.PD-body-desc {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 60px 0px;
  position: relative;
  width: 704px;
}

.PD-body-desc .desc-title {
  color: #333333;
  font-family: "Roboto", Helvetica;
  font-size: 18.2px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  width: 186px;
}

.PD-body-desc .div-content-inner {
  align-self: stretch;
  height: 200px;
  position: relative;
  width: 100%;
}

.PD-body-desc .element {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  height: 63px;
  left: 0;
  letter-spacing: 0;
  line-height: 20.8px;
  position: absolute;
  top: -1px;
}
