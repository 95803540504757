.inquiry-create {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.inquiry-create .div-2 {
  background-color: #ffffff;
  height: 1690px;
  position: relative;
  width: 1440px;
}

.inquiry-create .right-menu-instance {
  left: 1034px !important;
  position: absolute !important;
  top: 0 !important;
}

.inquiry-create .main-logo-instance {
  left: 131px !important;
  position: absolute !important;
  top: 50px !important;
}

.inquiry-create .search-area-instance {
  left: 540px !important;
  position: absolute !important;
  top: 50px !important;
}

.inquiry-create .nav-menu-instance {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.inquiry-create .link-tree-instance {
  left: 131px !important;
  position: absolute !important;
  top: 150px !important;
}

.inquiry-create .customer-menu-instance {
  left: 130px !important;
  position: absolute !important;
  top: 200px !important;
}

.inquiry-create .inquiry-create-body {
  height: 730px;
  left: 350px;
  position: absolute;
  top: 200px;
  width: 940px;
}

.inquiry-create .ICB {
  border: 1px solid;
  border-color: #00000080;
  height: 710px;
  left: 10px;
  position: relative;
  top: 10px;
  width: 920px;
}

.inquiry-create .head-area {
  height: 70px;
  left: 10px;
  position: absolute;
  top: 30px;
  width: 900px;
}

.inquiry-create .head-title {
  color: var(--storegpakoreacommine-shaft);
  font-family: var(--store-gpakorea-com-roboto-medium-26-font-family);
  font-size: var(--store-gpakorea-com-roboto-medium-26-font-size);
  font-style: var(--store-gpakorea-com-roboto-medium-26-font-style);
  font-weight: var(--store-gpakorea-com-roboto-medium-26-font-weight);
  height: 29px;
  left: 302px;
  letter-spacing: var(--store-gpakorea-com-roboto-medium-26-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-medium-26-line-height);
  position: absolute;
  text-align: center;
  top: -1px;
  width: 297px;
}

.inquiry-create .head-body {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 328.56px 0.79px 328.53px;
  position: absolute;
  top: 48px;
  width: 900px;
}

.inquiry-create .text-wrapper-18 {
  color: var(--storegpakoreacomgray);
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 15.6px;
  margin-left: -114.54px;
  margin-right: -114.54px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 472px;
}

.inquiry-create .form-area {
  height: 510px;
  left: 10px;
  position: absolute;
  top: 120px;
  width: 900px;
}

.inquiry-create .form-block {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.inquiry-create .title-area {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 900px;
}

.inquiry-create .title-tag {
  align-items: flex-start;
  background-color: #f7f7f7;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 2px;
  display: flex;
  flex-direction: column;
  height: 70px;
  justify-content: center;
  padding: 25px 10px;
  position: relative;
  width: 100px;
}

.inquiry-create .text-wrapper-19 {
  align-self: stretch;
  color: #212121;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -2px;
  position: relative;
  text-align: center;
}

.inquiry-create .title-data {
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 2px;
  height: 70px;
  left: 100px;
  position: absolute;
  top: 0;
  width: 800px;
}

.inquiry-create .input-2 {
  background: none;
  border: 1px solid;
  border-color: #00000080;
  color: #000000;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14px;
  margin-top: -1px;
  padding: 16px;
  position: relative;
  top: 12px;
  width: 800px;
}

.inquiry-create .body-area {
  align-items: center;
  display: inline-flex;
  height: 440px;
  position: relative;
}

.inquiry-create .body-tag {
  align-items: flex-start;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  height: 440px;
  justify-content: center;
  padding: 210px 10px;
  position: relative;
  width: 100px;
}

.inquiry-create .body-data {
  height: 440px;
  position: relative;
  width: 800px;
}

.inquiry-create .input-3 {
  align-items: center;
  border: 1px solid;
  border-color: #00000080;
  display: flex;
  gap: 10px;
  height: 440px;
  justify-content: center;
  padding: 16px;
  position: relative;
  width: 800px;
}

.inquiry-create .text-wrapper-20 {
  align-self: stretch;
  color: #000000;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: -1px;
  position: relative;
}

.inquiry-create .button-area {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 10px;
  padding: 0px 350px 0px 450px;
  position: absolute;
  top: 650px;
  width: 900px;
}

.inquiry-create .button {
  all: unset;
  align-items: center;
  background-color: #00c73c;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 8px;
  position: relative;
  width: 100px;
}

.inquiry-create .button-box {
  color: #ffffff;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -2px;
  position: relative;
  text-align: center;
}

.inquiry-create .footer-panel {
  align-items: center;
  background-color: #606060;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 1530px;
  width: 1440px;
}

.inquiry-create .footer-frame {
  height: 160px;
  position: relative;
  width: 1160px;
}

.inquiry-create .company-infos {
  height: 72px;
  left: 213px;
  position: absolute;
  top: 43px;
  width: 683px;
}

.inquiry-create .flexcontainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 72px;
  position: relative;
  width: 681px;
}

.inquiry-create .text-i {
  align-self: stretch;
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
  position: relative;
}

.inquiry-create .text-wrapper-21 {
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
}

.inquiry-create .sns-link {
  height: 40px;
  left: 949px;
  position: absolute;
  top: 60px;
  width: 200px;
}

.inquiry-create .list-item-link {
  background-image: url(../../../static/img/list-item-link.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 46px;
}

.inquiry-create .text-wrapper-22 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 40px;
}

.inquiry-create .list-item-link-2 {
  background-image: url(../../../static/img/list-item-link-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 51px;
  position: absolute;
  top: 0;
  width: 46px;
}

.inquiry-create .list-item-link-3 {
  background-image: url(../../../static/img/list-item-link-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 103px;
  position: absolute;
  top: 0;
  width: 46px;
}

.inquiry-create .text-wrapper-23 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 53px;
}

.inquiry-create .list-item-link-4 {
  background-image: url(../../../static/img/list-item-link-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 154px;
  position: absolute;
  top: 0;
  width: 46px;
}

.inquiry-create .text-wrapper-24 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 66px;
}

.inquiry-create .logo-gothic {
  height: 111px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 25px;
  width: 145px;
}

.inquiry-create .kakao-service-instance {
  left: 0 !important;
  position: absolute !important;
  top: 200px !important;
}

.inquiry-create .jimmed-products-instance {
  left: 1310px !important;
  position: absolute !important;
  top: 560px !important;
}

.inquiry-create .viewed-products-instance {
  left: 1310px !important;
  position: absolute !important;
  top: 200px !important;
}
