.customer-menu {
  height: 304px;
  position: relative;
  width: 220px;
}

.customer-menu .customer-item {
  align-items: flex-start;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  left: 10px;
  padding: 10px 0px;
  position: absolute;
  top: 50px;
  width: 200px;
}

.customer-menu .span-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 9.47px 15px 10px;
  position: relative;
  width: 100%;
}

.customer-menu .span {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  padding: 0px 0px 0.54px;
  position: relative;
}

.customer-menu .text-wrapper-9 {
  color: #232323;
  font-family: var(--store-gpakorea-com-roboto-regular-13-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-13-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-13-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-13-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-regular-13-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-13-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
}

.customer-menu .after-7 {
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: #232323;
  height: 1px;
  left: 0;
  position: absolute;
  top: 18px;
  width: 48px;
}

.customer-menu .item-link-7 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 10px 15px;
  position: relative;
  width: 100%;
}

.customer-menu .text-wrapper-10 {
  align-self: stretch;
  color: #333333;
  font-family: var(--store-gpakorea-com-roboto-regular-13-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-13-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-13-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-13-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-regular-13-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-13-line-height);
  margin-top: -1px;
  position: relative;
  cursor: pointer;
}

.customer-menu .customer-title {
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: #232323;
  height: 44px;
  left: 10px;
  position: absolute;
  top: 0;
  width: 200px;
}

.customer-menu .text-wrapper-11 {
  color: #232323;
  font-family: var(--store-gpakorea-com-roboto-bold-20-8-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-20-8-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-20-8-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-20-8-font-weight);
  height: 23px;
  left: 0;
  letter-spacing: var(--store-gpakorea-com-roboto-bold-20-8-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-20-8-line-height);
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 76px;
}
