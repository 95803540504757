.forgery-list {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.forgery-list .div-19 {
  background-color: #ffffff;
  height: 1400px;
  position: relative;
  width: 1640px;
}

.forgery-list .middle-section-3 {
  align-items: flex-start;
  display: flex;
  height: 1300px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 100px;
  width: 1640px;
}

.forgery-list .left-menu-9 {
  top: unset !important;
  width: 240px !important;
}

.forgery-list .body-area-3 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  padding: 10px;
  position: relative;
}

.forgery-list .body-title-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.forgery-list .title-9 {
  color: #000000;
  flex: 1;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  height: 25px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
}

.forgery-list .body-head-4 {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 10px 5px;
  position: relative;
  width: 100%;
}

.forgery-list .search-3 {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 5px 5px;
  padding: 10px 0px;
  position: relative;
  width: 200px;
}

.forgery-list .dropdown-12 {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  height: 28px;
  padding: 4.5px 5px;
  position: relative;
  width: 78px;
}

.forgery-list .text-wrapper-52 {
  color: #000000;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.forgery-list .dropdown-13 {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  flex: 1;
  flex-grow: 1;
  height: 28px;
  padding: 4.5px 5px;
  position: relative;
}

.forgery-list .element-3 {
  color: #000000;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
}

.forgery-list .div-20 {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 5px 5px;
  padding: 10px 0px;
  position: relative;
  width: 180px;
}

.forgery-list .dropdown-14 {
  align-items: center;
  display: flex;
  height: 28px;
  padding: 4.5px 5px;
  position: relative;
  width: 50px;
}

.forgery-list .text-wrapper-53 {
  color: #000000;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
}

.forgery-list .platform {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 5px 5px;
  padding: 10px 0px;
  position: relative;
  width: 220px;
}

.forgery-list .dropdown-15 {
  align-items: center;
  display: flex;
  height: 28px;
  padding: 4.5px 5px;
  position: relative;
  width: 60px;
}

.forgery-list .infringe {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 5px 5px;
  padding: 10px 0px;
  position: relative;
  width: 230px;
}

.forgery-list .dropdown-16 {
  align-items: center;
  display: flex;
  height: 28px;
  padding: 4.5px 5px;
  position: relative;
  width: 74px;
}

.forgery-list .left-right-frame-3 {
  align-items: center;
  display: flex;
  gap: 5px;
  height: 48px;
  justify-content: center;
  padding: 10px 0px;
  position: relative;
  width: 85px;
}

.forgery-list .div-wrapper-4 {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 28px;
  justify-content: center;
  position: relative;
  width: 40px;
}

.forgery-list .text-wrapper-54 {
  align-self: stretch;
  color: #666666;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.forgery-list .best-body-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 10px 10px;
  min-width: 240px;
  padding: 10px 5px;
  position: relative;
  width: 100%;
}

.forgery-list .best-item-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 280px;
  padding: 0px 0px 20px;
  position: relative;
  width: 220px;
}

.forgery-list .thumbnail-10 {
  align-self: stretch;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #e1e1e1;
  border-radius: 6px;
  height: 160px;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
}

.forgery-list .overlap-group-7 {
  background-image: url(https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/67763c2311607350f17e4e6d/img/div-img@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 159px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 219px;
}

.forgery-list .button-9 {
  all: unset;
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  left: 190px;
  opacity: 0.8;
  position: relative;
  top: 9px;
}

.forgery-list .button-box-8 {
  color: #888888;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 18.2px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.forgery-list .item-info-3 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100px;
  padding: 0px 2px;
  position: relative;
  width: 100%;
  z-index: 0;
}

.forgery-list .item-title-2 {
  align-items: flex-start;
  display: flex;
  height: 40px;
  margin-right: -4.00px;
  padding: 2px 0px;
  position: relative;
  width: 220px;
}

.forgery-list .item-title-text-3 {
  color: #333333;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
}

.forgery-list .div-21 {
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: space-between;
  margin-right: -4.00px;
  padding: 1.5px 0px;
  position: relative;
  width: 220px;
}

.forgery-list .text-wrapper-55 {
  color: #333333;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  width: fit-content;
}

.forgery-list .text-wrapper-56 {
  color: #333333;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.forgery-list .text-wrapper-57 {
  color: #333333;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  width: fit-content;
}

.forgery-list .product-price-3 {
  color: #333333;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  width: fit-content;
}

.forgery-list .price-floor-3 {
  color: #333333;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.forgery-list .determin-price-3 {
  color: #333333;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  width: fit-content;
}

.forgery-list .menu-section-3 {
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 40px;
  width: 1640px;
}

.forgery-list .main-logo-8 {
  top: unset !important;
}

.forgery-list .right-menu-9 {
  align-items: flex-start !important;
  display: flex !important;
  height: 50px !important;
  left: unset !important;
  top: unset !important;
  width: 234px !important;
}
