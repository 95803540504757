.subscribe-apply-list {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.subscribe-apply-list .div-2 {
  background-color: #ffffff;
  height: 1690px;
  position: relative;
  width: 1440px;
}

.subscribe-apply-list .bulletin-body {
  height: 1082px;
  left: 340px;
  overflow: hidden;
  position: absolute;
  top: 200px;
  width: 940px;
}

.subscribe-apply-list .body {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
  padding: 5px 0px 95px;
  position: relative;
  width: 940px;
}

.subscribe-apply-list .bulletin-body-title {
  align-self: stretch;
  height: 25px;
  margin-right: -2.00px;
  position: relative;
  width: 100%;
}

.subscribe-apply-list .title {
  color: #000000;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 25px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 940px;
}

.subscribe-apply-list .bulletin-body-table {
  height: 840px;
  position: relative;
  width: 940px;
}

.subscribe-apply-list .form-table {
  align-items: flex-start;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #adadad;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 940px;
}

.subscribe-apply-list .head-frame {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  height: 40px;
  position: relative;
  width: 100%;
}

.subscribe-apply-list .body-row-cell {
  align-items: center;
  background-color: #656978;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
  width: 60px;
}

.subscribe-apply-list .text-wrapper-22 {
  color: #ffffff;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -0.50px;
  position: relative;
  text-align: center;
  white-space: nowrap;
}

.subscribe-apply-list .body-row-cell-2 {
  align-items: center;
  background-color: #656978;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
  width: 460px;
}

.subscribe-apply-list .text-wrapper-23 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -0.50px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.subscribe-apply-list .body-row-cell-3 {
  align-items: center;
  background-color: #656978;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
  width: 100px;
}

.subscribe-apply-list .body-row-cell-4 {
  align-items: center;
  background-color: #656978;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
  width: 80px;
}

.subscribe-apply-list .record-frame {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-wrap: wrap;
  gap: 0px 0px;
  height: 40px;
  position: relative;
  width: 100%;
}

.subscribe-apply-list .body-row-data {
  align-items: center;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
  width: 60px;
}

.subscribe-apply-list .text-wrapper-24 {
  color: #666666;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 21px;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -0.50px;
  position: relative;
  text-align: center;
  white-space: nowrap;
}

.subscribe-apply-list .body-row-data-2 {
  align-items: center;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  display: flex;
  gap: 10px;
  height: 40px;
  padding: 9px 5px;
  position: relative;
  width: 460px;
}

.subscribe-apply-list .text-wrapper-25 {
  color: #666666;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 21px;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -0.50px;
  position: relative;
  white-space: nowrap;
}

.subscribe-apply-list .body-row-data-3 {
  align-items: center;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
  width: 100px;
}

.subscribe-apply-list .body-row-data-4 {
  align-items: center;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
  width: 80px;
}

.subscribe-apply-list .text-wrapper-26 {
  color: #666666;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 21px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.50px;
  position: relative;
  text-align: center;
}

.subscribe-apply-list .design-component-instance-node {
  left: unset !important;
  top: unset !important;
}

.subscribe-apply-list .footer-panel-instance {
  left: 0;
  position: absolute;
  top: 1530px;
  width: 1440px;
}

.subscribe-apply-list .design-component-instance-node-2 {
  position: absolute !important;
  width: 130px !important;
}

.subscribe-apply-list .left-menu-instance {
  position: absolute !important;
  width: 200px !important;
}

.subscribe-apply-list .design-component-instance-node-3 {
  left: 0 !important;
  position: absolute !important;
}

.subscribe-apply-list .link-tree-instance {
  left: 131px !important;
  position: absolute !important;
  width: 906px !important;
}

.subscribe-apply-list .main-logo-instance {
  left: 131px !important;
  position: absolute !important;
  width: 185px !important;
}

.subscribe-apply-list .search-area-2 {
  border: 1px solid;
  border-color: #00000080;
  border-radius: 16px;
  height: 40px;
  left: 540px;
  overflow: hidden;
  position: absolute;
  top: 50px;
  width: 360px;
}

.subscribe-apply-list .input-box-wrapper {
  align-items: flex-start;
  border: 1px solid;
  border-color: #ffffff;
  display: flex;
  height: 40px;
  left: 12px;
  padding: 11px 0px;
  position: absolute;
  top: 0;
  width: 308px;
}

.subscribe-apply-list .input-box-2 {
  color: #00000080;
  flex: 1;
  font-family: "Font Awesome 5 Free-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
}

.subscribe-apply-list .search-2 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  left: 320px;
  padding: 11px;
  position: absolute;
  top: 0;
}

.subscribe-apply-list .text-wrapper-27 {
  color: #999999;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 18px;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 18px;
}

.subscribe-apply-list .right-menu-instance {
  left: 1034px !important;
  position: absolute !important;
  top: 0 !important;
}
