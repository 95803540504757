.PD-body-similar {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 20px;
  left: 120px;
  opacity: 0.7;
  padding: 10px;
  position: relative;
  top: 5480px;
  width: 1200px;
}

.PD-body-similar .rec-item {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 280px;
  position: relative;
  width: 220px;
}

.PD-body-similar .thumbnail {
  align-self: stretch;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #e1e1e1;
  border-radius: 6px;
  height: 160px;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
}

.PD-body-similar .overlap-group {
  background-image: url(../../../static/img/div-img-2x.png);
  background-size: 100% 100%;
  height: 159px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 219px;
}

.PD-body-similar .button {
  all: unset;
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  left: 189px;
  opacity: 0.8;
  position: relative;
  top: 10px;
}

.PD-body-similar .button-box {
  color: #888888;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 18.2px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.PD-body-similar .item-info {
  align-self: stretch;
  background-color: #ffffff;
  height: 120px;
  position: relative;
  width: 100%;
  z-index: 0;
}

.PD-body-similar .item-title {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 44px;
  left: 0;
  position: absolute;
  top: 10px;
  width: 220px;
}

.PD-body-similar .item-title-text {
  color: #333333;
  font-family: "Roboto", Helvetica;
  font-size: 14.3px;
  font-weight: 400;
  letter-spacing: -0.71px;
  line-height: 22px;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.PD-body-similar .item-price {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 64px;
  width: 220px;
}

.PD-body-similar .item-price-text {
  color: #333333;
  font-family: "Roboto", Helvetica;
  font-size: 18.2px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.PD-body-similar .item-review {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  left: 0;
  position: absolute;
  top: 90px;
  width: 220px;
}

.PD-body-similar .item-review-symbol {
  color: #ffb450;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 11.7px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 11.7px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.PD-body-similar .text-wrapper-10 {
  color: #888888;
  font-family: "Roboto", Helvetica;
  font-size: 11.7px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16.7px;
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.PD-body-similar .item-review-tag {
  color: #aaaaaa;
  font-family: "Roboto", Helvetica;
  font-size: 9.9px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14.2px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}
