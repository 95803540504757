.search-area {
  border: 1px solid;
  border-color: #00000080;
  border-radius: 16px;
  height: 40px;
  left: 540px;
  overflow: hidden;
  position: relative;
  top: 50px;
  width: 360px;
}

.search-area .input-box {
  background: none;
  border: 1px solid;
  border-color: #ffffff;
  color: #00000080;
  flex: 1;
  flex-grow: 1;
  font-family: "Font Awesome 5 Free-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  left: 12px;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: -1px;
  padding: 11px 0px;
  position: absolute;
  top: 0;
  width: 308px;
}

.search-area .search {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  left: 320px;
  padding: 11px;
  position: absolute;
  top: 0;
}

.search-area .text-wrapper-54 {
  color: #999999;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 18px;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 18px;
}

*:focus {
  outline: none;
}