.my-purchase-list {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.my-purchase-list .div-22 {
  background-color: #ffffff;
  height: 1460px;
  position: relative;
  width: 1440px;
}

.my-purchase-list .right-menu-8 {
  left: 1034px !important;
  position: absolute !important;
  top: 0 !important;
}

.my-purchase-list .main-logo-9 {
  left: 131px !important;
  position: absolute !important;
  top: 50px !important;
}

.my-purchase-list .search-area-9 {
  left: 541px !important;
  position: absolute !important;
  top: 50px !important;
}

.my-purchase-list .nav-menu-9 {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.my-purchase-list .link-tree-9 {
  left: 131px !important;
  position: absolute !important;
  top: 150px !important;
}

.my-purchase-list .my-page-menu-4 {
  left: 130px !important;
  position: absolute !important;
  top: 200px !important;
}

.my-purchase-list .my-purchase-query {
  height: 374px;
  left: 370px;
  position: absolute;
  top: 400px;
  width: 900px;
}

.my-purchase-list .table {
  align-items: flex-start;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  max-width: 1140px;
  padding: 0.63px 0.63px 0px 0.62px;
  position: absolute;
  top: 41px;
  width: 900px;
}

.my-purchase-list .header-row {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.my-purchase-list .cell {
  align-items: flex-start;
  background-color: var(--storegpakoreacomporcelain);
  border-color: var(--storegpakoreacomalto);
  border-left-style: solid;
  border-left-width: 1.25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 15.09px 41.47px 15.55px 8.61px;
  position: relative;
  width: 100px;
}

.my-purchase-list .text-wrapper-110 {
  color: #444444;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1.25px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.my-purchase-list .cell-2 {
  align-items: flex-start;
  background-color: var(--storegpakoreacomporcelain);
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: flex-end;
  padding: 15.09px 520.84px 15.55px 7.99px;
  position: relative;
}

.my-purchase-list .text-wrapper-111 {
  color: #444444;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.my-purchase-list .cell-3 {
  align-items: flex-start;
  background-color: var(--storegpakoreacomporcelain);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 15.09px 8px 15.55px;
  position: relative;
  width: 100px;
}

.my-purchase-list .cell-4 {
  align-items: flex-start;
  background-color: var(--storegpakoreacomporcelain);
  border-color: var(--storegpakoreacomalto);
  border-right-style: solid;
  border-right-width: 1.25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 15.09px 8.62px 15.55px 61.46px;
  position: relative;
  width: 120px;
}

.my-purchase-list .text-wrapper-112 {
  color: #444444;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1.25px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.my-purchase-list .div-box-empty-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 120px 375.69px 120px 375.66px;
  position: absolute;
  top: 111px;
  width: 900px;
}

.my-purchase-list .text-wrapper-113 {
  color: #888888;
  font-family: var(--store-gpakorea-com-roboto-regular-15-6-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-15-6-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-15-6-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-15-6-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-regular-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-15-6-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.my-purchase-list .button-menu-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  padding: 0px 0px 2.5px;
  position: absolute;
  top: 0;
  width: 900px;
}

.my-purchase-list .button-menu {
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomsilver);
  border-radius: 4px;
  height: 38.24px;
  position: relative;
  width: 80.88px;
}

.my-purchase-list .text-wrapper-114 {
  color: #333333;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 20px;
  left: 13px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
  width: 27px;
}

.my-purchase-list .span-caret-2 {
  border-color: #333333;
  border-left-style: solid;
  border-left-width: 3.75px;
  border-right-style: solid;
  border-right-width: 3.75px;
  border-top-style: solid;
  border-top-width: 3.75px;
  height: 4px;
  left: 50px;
  position: absolute;
  top: 17px;
  width: 8px;
}

.my-purchase-list .my-page-avata-4 {
  left: 370px !important;
  position: absolute !important;
  top: 200px !important;
}

.my-purchase-list .footer-panel-8 {
  align-items: center;
  background-color: #606060;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 1300px;
  width: 1440px;
}

.my-purchase-list .footer-frame-9 {
  height: 160px;
  position: relative;
  width: 1160px;
}

.my-purchase-list .company-infos-7 {
  height: 72px;
  left: 213px;
  position: absolute;
  top: 43px;
  width: 683px;
}

.my-purchase-list .flexcontainer-8 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 72px;
  position: relative;
  width: 681px;
}

.my-purchase-list .text-i-9 {
  align-self: stretch;
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
  position: relative;
}

.my-purchase-list .text-wrapper-115 {
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
}

.my-purchase-list .sns-link-9 {
  height: 40px;
  left: 949px;
  position: absolute;
  top: 60px;
  width: 200px;
}

.my-purchase-list .list-item-link-33 {
  background-image: url(../../../static/img/list-item-link.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-purchase-list .text-wrapper-116 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 40px;
}

.my-purchase-list .list-item-link-34 {
  background-image: url(../../../static/img/list-item-link-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 51px;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-purchase-list .list-item-link-35 {
  background-image: url(../../../static/img/list-item-link-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 103px;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-purchase-list .text-wrapper-117 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 53px;
}

.my-purchase-list .list-item-link-36 {
  background-image: url(../../../static/img/list-item-link-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 154px;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-purchase-list .text-wrapper-118 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 66px;
}

.my-purchase-list .logo-gothic-9 {
  height: 111px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 25px;
  width: 145px;
}

.my-purchase-list .kakao-service-8 {
  left: 0 !important;
  position: absolute !important;
  top: 200px !important;
}

.my-purchase-list .jimmed-products-9 {
  left: 1310px !important;
  position: absolute !important;
  top: 560px !important;
}

.my-purchase-list .viewed-products-9 {
  left: 1310px !important;
  position: absolute !important;
  top: 200px !important;
}
