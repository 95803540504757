.PD-body-price {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 660px;
  padding: 60px 0px;
  position: relative;
  width: 704px;
}

.PD-body-price .price-title {
  color: #333333;
  font-family: "Roboto", Helvetica;
  font-size: 18.2px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  width: 138px;
}

.PD-body-price .div-visible-lg {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 13px;
  height: 560px;
  margin-bottom: -60px;
  position: relative;
  width: 704px;
}

.PD-body-price .price-heading {
  color: #666666;
  font-family: var(--chagaun-net-roboto-medium-14-font-family);
  font-size: var(--chagaun-net-roboto-medium-14-font-size);
  font-style: var(--chagaun-net-roboto-medium-14-font-style);
  font-weight: var(--chagaun-net-roboto-medium-14-font-weight);
  height: 16px;
  letter-spacing: var(--chagaun-net-roboto-medium-14-letter-spacing);
  line-height: var(--chagaun-net-roboto-medium-14-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 142px;
}

.PD-body-price .list {
  align-items: flex-start;
  display: flex;
  left: 0;
  position: absolute;
  top: 30px;
  width: 704px;
}

.PD-body-price .item {
  align-items: flex-start;
  display: flex;
  position: relative;
  width: 704px;
}

.PD-body-price .link-3 {
  align-items: flex-start;
  background-color: #232323;
  border: 1px solid;
  border-color: #e1e1e1;
  display: flex;
  flex: 1;
  flex-grow: 1;
  padding: 7px 6px;
  position: relative;
}

.PD-body-price .text-wrapper-11 {
  color: #ffffff;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.PD-body-price .frame {
  height: 406px;
  left: 0;
  position: absolute;
  top: 80px;
  width: 704px;
}

.PD-body-price .table {
  align-items: center;
  border-color: #dddddd;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 704px;
}

.PD-body-price .header-row {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.PD-body-price .cell {
  align-items: flex-start;
  align-self: stretch;
  background-color: #f9f9f9;
  border: 1px solid;
  border-color: #dddddd;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 125px;
  padding: 20px 5px;
  position: relative;
  width: 100%;
}

.PD-body-price .small {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 40px;
  padding: 5px;
  position: relative;
  width: 100%;
}

.PD-body-price .element-2 {
  align-self: stretch;
  color: #646464;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  height: 38px;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-bottom: -7px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.PD-body-price .element-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 40px;
  padding: 0px 5px;
  position: relative;
  width: 100%;
}

.PD-body-price .p {
  align-self: stretch;
  color: #646464;
  font-family: "Roboto", Helvetica;
  font-size: 20.8px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 40px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.PD-body-price .span {
  font-weight: 700;
}

.PD-body-price .text-wrapper-12 {
  color: #646464;
  font-family: "Roboto", Helvetica;
  font-size: 20.8px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 40px;
}

.PD-body-price .body-row {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 162px;
  position: relative;
  width: 100%;
}

.PD-body-price .data {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #dddddd;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 162px;
  padding: 15px 5px;
  position: relative;
  width: 100%;
}

.PD-body-price .strong-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 51px;
  padding: 5px;
  position: relative;
  width: 100%;
}

.PD-body-price .text-wrapper-13 {
  align-self: stretch;
  color: #646464;
  font-family: "Roboto", Helvetica;
  font-size: 14.3px;
  font-weight: 500;
  height: 41px;
  letter-spacing: 0;
  line-height: 20.4px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.PD-body-price .small-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 76px;
  position: relative;
  width: 100%;
}

.PD-body-price .text-wrapper-14 {
  align-self: stretch;
  color: #777777;
  font-family: "Roboto", Helvetica;
  font-size: 12.2px;
  font-weight: 400;
  height: 70px;
  letter-spacing: 0;
  line-height: 17.4px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.PD-body-price .footer-row {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 119px;
  position: relative;
  width: 100%;
}

.PD-body-price .data-2 {
  align-items: flex-start;
  align-self: stretch;
  border: 1px solid;
  border-color: #dddddd;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5px;
  padding: 20px 6px;
  position: relative;
  width: 100%;
}

.PD-body-price .link-4 {
  align-items: center;
  align-self: stretch;
  background-color: #b1db33;
  border: 1px solid;
  border-color: #b1db33;
  border-radius: 4px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  padding: 11px 10px;
  position: relative;
  width: 100%;
}

.PD-body-price .text-wrapper-15 {
  align-self: stretch;
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.PD-body-price .link-5 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #cccccc;
  border-radius: 4px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  padding: 11px 10px;
  position: relative;
  width: 100%;
}

.PD-body-price .text-wrapper-16 {
  align-self: stretch;
  color: #333333;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}
