.input {
  background: none;
  border: 1px solid;
  border-color: #00000080;
  /* border-radius: 8px; */
  color: #00000080;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1px;
  overflow: hidden;
  padding: 16px;
  position: relative;
  width: 380px;
}
