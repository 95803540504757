.assess-detail {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.assess-detail .div-4 {
  background-color: #ffffff;
  height: 1690px;
  overflow: hidden;
  position: relative;
  width: 1640px;
}

.assess-detail .bulletin-body-2 {
  align-items: flex-start;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 1300px;
  left: 240px;
  padding: 10px;
  position: absolute;
  top: 100px;
  width: 1400px;
}

.assess-detail .body-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  padding: 5px 0px 95px;
  position: relative;
  width: 100%;
}

.assess-detail .body-title-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.assess-detail .title-3 {
  color: #000000;
  flex: 1;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 25px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
}

.assess-detail .body-sub-title-3 {
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #bbbbbb;
  height: 10px;
  position: relative;
  width: 100%;
}

.assess-detail .body-head-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 370px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.assess-detail .drop-downs-3 {
  align-items: center;
  display: flex;
  gap: 10px;
  height: 28px;
  position: relative;
  width: 610px;
}

.assess-detail .dropdown-7 {
  align-items: center;
  display: flex;
  height: 28px;
  padding: 5px;
  position: relative;
  width: 60px;
}

.assess-detail .text-wrapper-16 {
  color: #000000;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
}

.assess-detail .dropdown-8 {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  height: 28px;
  padding: 5px;
  position: relative;
  width: 120px;
}

.assess-detail .text-wrapper-17 {
  color: #000000;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
}

.assess-detail .dropdown-9 {
  align-items: center;
  display: flex;
  height: 28px;
  padding: 5px;
  position: relative;
  width: 70px;
}

.assess-detail .body-search-2 {
  left: unset !important;
  top: unset !important;
}

.assess-detail .footer-panel-3 {
  left: -100px !important;
  position: absolute !important;
  top: 1530px !important;
  width: 1920px !important;
}

.assess-detail .main-logo-3 {
  left: 0 !important;
  position: absolute !important;
  top: 40px !important;
  width: 240px !important;
}

.assess-detail .right-menu-3 {
  position: absolute !important;
}

.assess-detail .left-menu-3 {
  left: 0 !important;
  position: absolute !important;
  width: 240px !important;
}
