.bulletin-list {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.bulletin-list .div-4 {
  background-color: #ffffff;
  height: 1354px;
  position: relative;
  width: 1440px;
}

.bulletin-list .right-menu-2 {
  left: 1034px !important;
  position: absolute !important;
  top: 0 !important;
}

.bulletin-list .main-logo-2 {
  left: 131px !important;
  position: absolute !important;
  top: 50px !important;
}

.bulletin-list .search-area-2 {
  left: 541px !important;
  position: absolute !important;
  top: 50px !important;
}

.bulletin-list .nav-menu-2 {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.bulletin-list .link-tree-2 {
  left: 131px !important;
  position: absolute !important;
  top: 150px !important;
}

.bulletin-list .footer-frame-wrapper {
  align-items: center;
  background-color: #606060;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 1194px;
  width: 1440px;
}

.bulletin-list .footer-frame-2 {
  height: 160px;
  position: relative;
  width: 1160px;
}

.bulletin-list .flexcontainer-wrapper {
  height: 72px;
  left: 213px;
  position: absolute;
  top: 43px;
  width: 683px;
}

.bulletin-list .flexcontainer-i {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 72px;
  position: relative;
  width: 681px;
}

.bulletin-list .p {
  align-self: stretch;
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
  position: relative;
}

.bulletin-list .text-wrapper-42 {
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
}

.bulletin-list .sns-link-2 {
  height: 40px;
  left: 949px;
  position: absolute;
  top: 60px;
  width: 200px;
}

.bulletin-list .list-item-link-11 {
  background-image: url(../../../static/img/list-item-link.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 46px;
}

.bulletin-list .text-wrapper-43 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 40px;
}

.bulletin-list .list-item-link-12 {
  background-image: url(../../../static/img/list-item-link-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 51px;
  position: absolute;
  top: 0;
  width: 46px;
}

.bulletin-list .list-item-link-13 {
  background-image: url(../../../static/img/list-item-link-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 103px;
  position: absolute;
  top: 0;
  width: 46px;
}

.bulletin-list .text-wrapper-44 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 53px;
}

.bulletin-list .list-item-link-14 {
  background-image: url(../../../static/img/list-item-link-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 154px;
  position: absolute;
  top: 0;
  width: 46px;
}

.bulletin-list .text-wrapper-45 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 66px;
}

.bulletin-list .logo-gothic-2 {
  height: 111px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 25px;
  width: 145px;
}

.bulletin-list .kakao-service-2 {
  left: 0 !important;
  position: absolute !important;
  top: 200px !important;
}

.bulletin-list .customer-menu-2 {
  left: 130px !important;
  position: absolute !important;
  top: 200px !important;
}

.bulletin-list .bulletin-body {
  height: 914px;
  left: 360px;
  position: absolute;
  top: 200px;
  width: 940px;
}

.bulletin-list .body {
  height: 914px;
  position: relative;
}

.bulletin-list .bulletin-body-title {
  height: 41px;
  left: 0;
  position: absolute;
  top: 0;
  width: 159px;
}

.bulletin-list .span-bar-2 {
  background-color: #fc871e;
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 60px;
}

.bulletin-list .heading {
  color: #000000;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 28px;
  font-weight: 700;
  height: 21px;
  left: 0;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: 20px;
  white-space: nowrap;
  width: 157px;
}

.bulletin-list .bulletin-body-table {
  height: 757px;
  left: 0;
  position: absolute;
  top: 61px;
  width: 940px;
}

.bulletin-list .form-table-2 {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #adadad;
  height: 757px;
  position: relative;
}

.bulletin-list .body-row-cell-5 {
  background-color: #656978;
  height: 37px;
  left: 0;
  position: absolute;
  top: 0;
  width: 80px;
}

.bulletin-list .text-wrapper-46 {
  color: #ffffff;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: 27px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
  width: 27px;
}

.bulletin-list .body-row-cell-6 {
  background-color: #656978;
  height: 37px;
  left: 80px;
  position: absolute;
  top: 0;
  width: 560px;
}

.bulletin-list .text-wrapper-47 {
  color: #ffffff;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: 336px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
  width: 27px;
}

.bulletin-list .body-row-cell-7 {
  background-color: #656978;
  height: 37px;
  left: 640px;
  position: absolute;
  top: 0;
  width: 100px;
}

.bulletin-list .text-wrapper-48 {
  color: #ffffff;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: 30px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
  width: 40px;
}

.bulletin-list .body-row-cell-8 {
  background-color: #656978;
  height: 37px;
  left: 740px;
  position: absolute;
  top: 0;
  width: 100px;
}

.bulletin-list .text-wrapper-49 {
  color: #ffffff;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: 37px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
  width: 27px;
}

.bulletin-list .body-row-cell-9 {
  background-color: #656978;
  height: 37px;
  left: 840px;
  position: absolute;
  top: 0;
  width: 100px;
}

.bulletin-list .body-row-data-81 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 0;
  position: absolute;
  top: 37px;
  width: 80px;
}

.bulletin-list .text-wrapper-50 {
  color: #666666;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 13px;
  font-weight: 800;
  height: 21px;
  left: 28px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
}

.bulletin-list .body-row-data-82 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 80px;
  position: absolute;
  top: 37px;
  width: 560px;
}

.bulletin-list .text-wrapper-51 {
  color: #666666;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 13px;
  font-weight: 700;
  height: 21px;
  left: 0;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: 6px;
  white-space: nowrap;
  width: 560px;
}

.bulletin-list .body-row-data-83 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 640px;
  position: absolute;
  top: 37px;
  width: 100px;
}

.bulletin-list .text-wrapper-52 {
  color: #666666;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 13px;
  font-weight: 700;
  height: 21px;
  left: 32px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
  width: 37px;
}

.bulletin-list .body-row-data-84 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 740px;
  position: absolute;
  top: 37px;
  width: 100px;
}

.bulletin-list .text-wrapper-53 {
  color: #666666;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 13px;
  font-weight: 700;
  height: 21px;
  left: 32px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
  width: 36px;
}

.bulletin-list .body-row-data-85 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 840px;
  position: absolute;
  top: 37px;
  width: 100px;
}

.bulletin-list .text-wrapper-54 {
  color: #666666;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 13px;
  font-weight: 700;
  height: 21px;
  left: 34px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
  width: 32px;
}

.bulletin-list .body-row-data-86 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 0;
  position: absolute;
  top: 73px;
  width: 80px;
}

.bulletin-list .body-row-data-87 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 80px;
  position: absolute;
  top: 73px;
  width: 560px;
}

.bulletin-list .body-row-data-88 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 640px;
  position: absolute;
  top: 73px;
  width: 100px;
}

.bulletin-list .body-row-data-89 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 740px;
  position: absolute;
  top: 73px;
  width: 100px;
}

.bulletin-list .body-row-data-90 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 840px;
  position: absolute;
  top: 73px;
  width: 100px;
}

.bulletin-list .text-wrapper-55 {
  color: #666666;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 13px;
  font-weight: 700;
  height: 21px;
  left: 30px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
  width: 40px;
}

.bulletin-list .body-row-data-91 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 0;
  position: absolute;
  top: 109px;
  width: 80px;
}

.bulletin-list .body-row-data-92 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 80px;
  position: absolute;
  top: 109px;
  width: 560px;
}

.bulletin-list .body-row-data-93 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 640px;
  position: absolute;
  top: 109px;
  width: 100px;
}

.bulletin-list .body-row-data-94 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 740px;
  position: absolute;
  top: 109px;
  width: 100px;
}

.bulletin-list .body-row-data-95 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 840px;
  position: absolute;
  top: 109px;
  width: 100px;
}

.bulletin-list .body-row-data-96 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 0;
  position: absolute;
  top: 145px;
  width: 80px;
}

.bulletin-list .body-row-data-97 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 80px;
  position: absolute;
  top: 145px;
  width: 560px;
}

.bulletin-list .body-row-data-98 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 640px;
  position: absolute;
  top: 145px;
  width: 100px;
}

.bulletin-list .body-row-data-99 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 740px;
  position: absolute;
  top: 145px;
  width: 100px;
}

.bulletin-list .body-row-data-100 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 840px;
  position: absolute;
  top: 145px;
  width: 100px;
}

.bulletin-list .text-wrapper-56 {
  color: #666666;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 13px;
  font-weight: 700;
  height: 21px;
  left: 26px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
  width: 47px;
}

.bulletin-list .body-row-data-101 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 0;
  position: absolute;
  top: 181px;
  width: 80px;
}

.bulletin-list .body-row-data-102 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 80px;
  position: absolute;
  top: 181px;
  width: 560px;
}

.bulletin-list .body-row-data-103 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 640px;
  position: absolute;
  top: 181px;
  width: 100px;
}

.bulletin-list .body-row-data-104 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 740px;
  position: absolute;
  top: 181px;
  width: 100px;
}

.bulletin-list .body-row-data-105 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 840px;
  position: absolute;
  top: 181px;
  width: 100px;
}

.bulletin-list .body-row-data-106 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 0;
  position: absolute;
  top: 217px;
  width: 80px;
}

.bulletin-list .body-row-data-107 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 80px;
  position: absolute;
  top: 217px;
  width: 560px;
}

.bulletin-list .body-row-data-108 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 640px;
  position: absolute;
  top: 217px;
  width: 100px;
}

.bulletin-list .body-row-data-109 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 740px;
  position: absolute;
  top: 217px;
  width: 100px;
}

.bulletin-list .body-row-data-110 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 840px;
  position: absolute;
  top: 217px;
  width: 100px;
}

.bulletin-list .body-row-data-111 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 0;
  position: absolute;
  top: 253px;
  width: 80px;
}

.bulletin-list .body-row-data-112 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 80px;
  position: absolute;
  top: 253px;
  width: 560px;
}

.bulletin-list .body-row-data-113 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 640px;
  position: absolute;
  top: 253px;
  width: 100px;
}

.bulletin-list .body-row-data-114 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 740px;
  position: absolute;
  top: 253px;
  width: 100px;
}

.bulletin-list .body-row-data-115 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 840px;
  position: absolute;
  top: 253px;
  width: 100px;
}

.bulletin-list .body-row-data-116 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 0;
  position: absolute;
  top: 289px;
  width: 80px;
}

.bulletin-list .body-row-data-117 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 80px;
  position: absolute;
  top: 289px;
  width: 560px;
}

.bulletin-list .body-row-data-118 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 640px;
  position: absolute;
  top: 289px;
  width: 100px;
}

.bulletin-list .body-row-data-119 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 740px;
  position: absolute;
  top: 289px;
  width: 100px;
}

.bulletin-list .body-row-data-120 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 840px;
  position: absolute;
  top: 289px;
  width: 100px;
}

.bulletin-list .body-row-data-121 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 0;
  position: absolute;
  top: 325px;
  width: 80px;
}

.bulletin-list .body-row-data-122 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 80px;
  position: absolute;
  top: 325px;
  width: 560px;
}

.bulletin-list .body-row-data-123 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 640px;
  position: absolute;
  top: 325px;
  width: 100px;
}

.bulletin-list .body-row-data-124 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 740px;
  position: absolute;
  top: 325px;
  width: 100px;
}

.bulletin-list .body-row-data-125 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 840px;
  position: absolute;
  top: 325px;
  width: 100px;
}

.bulletin-list .body-row-data-126 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 0;
  position: absolute;
  top: 361px;
  width: 80px;
}

.bulletin-list .body-row-data-127 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 80px;
  position: absolute;
  top: 361px;
  width: 560px;
}

.bulletin-list .body-row-data-128 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 640px;
  position: absolute;
  top: 361px;
  width: 100px;
}

.bulletin-list .body-row-data-129 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 740px;
  position: absolute;
  top: 361px;
  width: 100px;
}

.bulletin-list .body-row-data-130 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 840px;
  position: absolute;
  top: 361px;
  width: 100px;
}

.bulletin-list .body-row-data-131 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 0;
  position: absolute;
  top: 397px;
  width: 80px;
}

.bulletin-list .body-row-data-132 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 80px;
  position: absolute;
  top: 397px;
  width: 560px;
}

.bulletin-list .body-row-data-133 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 640px;
  position: absolute;
  top: 397px;
  width: 100px;
}

.bulletin-list .body-row-data-134 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 740px;
  position: absolute;
  top: 397px;
  width: 100px;
}

.bulletin-list .body-row-data-135 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 840px;
  position: absolute;
  top: 397px;
  width: 100px;
}

.bulletin-list .body-row-data-136 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 0;
  position: absolute;
  top: 433px;
  width: 80px;
}

.bulletin-list .body-row-data-137 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 80px;
  position: absolute;
  top: 433px;
  width: 560px;
}

.bulletin-list .body-row-data-138 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 640px;
  position: absolute;
  top: 433px;
  width: 100px;
}

.bulletin-list .body-row-data-139 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 740px;
  position: absolute;
  top: 433px;
  width: 100px;
}

.bulletin-list .body-row-data-140 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 840px;
  position: absolute;
  top: 433px;
  width: 100px;
}

.bulletin-list .body-row-data-141 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 0;
  position: absolute;
  top: 469px;
  width: 80px;
}

.bulletin-list .body-row-data-142 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 80px;
  position: absolute;
  top: 469px;
  width: 560px;
}

.bulletin-list .body-row-data-143 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 640px;
  position: absolute;
  top: 469px;
  width: 100px;
}

.bulletin-list .body-row-data-144 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 740px;
  position: absolute;
  top: 469px;
  width: 100px;
}

.bulletin-list .body-row-data-145 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 840px;
  position: absolute;
  top: 469px;
  width: 100px;
}

.bulletin-list .body-row-data-146 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 0;
  position: absolute;
  top: 505px;
  width: 80px;
}

.bulletin-list .text-wrapper-57 {
  color: #666666;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 13px;
  font-weight: 700;
  height: 21px;
  left: 27px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
}

.bulletin-list .body-row-data-147 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 80px;
  position: absolute;
  top: 505px;
  width: 560px;
}

.bulletin-list .body-row-data-148 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 640px;
  position: absolute;
  top: 505px;
  width: 100px;
}

.bulletin-list .body-row-data-149 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 740px;
  position: absolute;
  top: 505px;
  width: 100px;
}

.bulletin-list .body-row-data-150 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 840px;
  position: absolute;
  top: 505px;
  width: 100px;
}

.bulletin-list .body-row-data-151 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 0;
  position: absolute;
  top: 541px;
  width: 80px;
}

.bulletin-list .body-row-data-152 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 80px;
  position: absolute;
  top: 541px;
  width: 560px;
}

.bulletin-list .body-row-data-153 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 640px;
  position: absolute;
  top: 541px;
  width: 100px;
}

.bulletin-list .body-row-data-154 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 740px;
  position: absolute;
  top: 541px;
  width: 100px;
}

.bulletin-list .body-row-data-155 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 840px;
  position: absolute;
  top: 541px;
  width: 100px;
}

.bulletin-list .body-row-data-156 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 0;
  position: absolute;
  top: 577px;
  width: 80px;
}

.bulletin-list .body-row-data-157 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 80px;
  position: absolute;
  top: 577px;
  width: 560px;
}

.bulletin-list .body-row-data-158 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 640px;
  position: absolute;
  top: 577px;
  width: 100px;
}

.bulletin-list .body-row-data-159 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 740px;
  position: absolute;
  top: 577px;
  width: 100px;
}

.bulletin-list .body-row-data-160 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 840px;
  position: absolute;
  top: 577px;
  width: 100px;
}

.bulletin-list .body-row-data-161 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 0;
  position: absolute;
  top: 613px;
  width: 80px;
}

.bulletin-list .body-row-data-162 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 80px;
  position: absolute;
  top: 613px;
  width: 560px;
}

.bulletin-list .body-row-data-163 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 640px;
  position: absolute;
  top: 613px;
  width: 100px;
}

.bulletin-list .body-row-data-164 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 740px;
  position: absolute;
  top: 613px;
  width: 100px;
}

.bulletin-list .body-row-data-165 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 840px;
  position: absolute;
  top: 613px;
  width: 100px;
}

.bulletin-list .body-row-data-166 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 0;
  position: absolute;
  top: 649px;
  width: 80px;
}

.bulletin-list .body-row-data-167 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 80px;
  position: absolute;
  top: 649px;
  width: 560px;
}

.bulletin-list .body-row-data-168 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 640px;
  position: absolute;
  top: 649px;
  width: 100px;
}

.bulletin-list .body-row-data-169 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 740px;
  position: absolute;
  top: 649px;
  width: 100px;
}

.bulletin-list .body-row-data-170 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 840px;
  position: absolute;
  top: 649px;
  width: 100px;
}

.bulletin-list .body-row-data-171 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 0;
  position: absolute;
  top: 685px;
  width: 80px;
}

.bulletin-list .body-row-data-172 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 80px;
  position: absolute;
  top: 685px;
  width: 560px;
}

.bulletin-list .body-row-data-173 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 640px;
  position: absolute;
  top: 685px;
  width: 100px;
}

.bulletin-list .body-row-data-174 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 740px;
  position: absolute;
  top: 685px;
  width: 100px;
}

.bulletin-list .body-row-data-175 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 840px;
  position: absolute;
  top: 685px;
  width: 100px;
}

.bulletin-list .body-row-data-176 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 0;
  position: absolute;
  top: 721px;
  width: 80px;
}

.bulletin-list .body-row-data-177 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 80px;
  position: absolute;
  top: 721px;
  width: 560px;
}

.bulletin-list .body-row-data-178 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 640px;
  position: absolute;
  top: 721px;
  width: 100px;
}

.bulletin-list .body-row-data-179 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 740px;
  position: absolute;
  top: 721px;
  width: 100px;
}

.bulletin-list .body-row-data-180 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 36px;
  left: 840px;
  position: absolute;
  top: 721px;
  width: 100px;
}

.bulletin-list .body-page-2 {
  left: 250px !important;
  position: absolute !important;
  top: 838px !important;
}

.bulletin-list .design-component-instance-node-4 {
  left: 264px !important;
  position: absolute !important;
  top: 887px !important;
}

.bulletin-list .body-search-3 {
  background-image: url(../../../static/img/form-input-2.svg) !important;
}

.bulletin-list .jimmed-products-2 {
  left: 1310px !important;
  position: absolute !important;
  top: 560px !important;
}

.bulletin-list .viewed-products-2 {
  left: 1310px !important;
  position: absolute !important;
  top: 200px !important;
}
