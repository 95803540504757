.kakao-service {
  height: 170px;
  position: relative;
  top: 200px;
  width: 130px;
}

.kakao-service .chat-box {
  background-color: #00c73c;
  height: 170px;
  position: relative;
}

.kakao-service .span-ico-talk-banner {
  background-image: url(../../../static/img/span-ico-talk-banner.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 30px;
  left: 53px;
  position: absolute;
  top: 24px;
  width: 30px;
}

.kakao-service .emphasis {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  padding: 0px 5.5px;
  position: absolute;
  top: 62px;
  width: 130px;
}

.kakao-service .text-wrapper-8 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.kakao-service .strong {
  align-items: center;
  background-color: #00c73c;
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 20px;
  display: inline-flex;
  gap: 6px;
  left: 22px;
  padding: 1px 15px;
  position: absolute;
  top: 121px;
}

.kakao-service .text-wrapper-9 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.kakao-service .after {
  height: 7px;
  position: relative;
  width: 5px;
}
