.purchase-virtual {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.purchase-virtual .div-2 {
  background-color: #ffffff;
  height: 2470px;
  position: relative;
  width: 1440px;
}

.purchase-virtual .right-menu-instance {
  left: 1034px !important;
  position: absolute !important;
  top: 0 !important;
}

.purchase-virtual .main-logo-instance {
  left: 131px !important;
  position: absolute !important;
  top: 50px !important;
}

.purchase-virtual .search-area-instance {
  left: 541px !important;
  position: absolute !important;
  top: 50px !important;
}

.purchase-virtual .nav-menu-instance {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.purchase-virtual .link-tree-instance {
  left: 131px !important;
  position: absolute !important;
  top: 150px !important;
}

.purchase-virtual .div-3 {
  height: 2044px;
  left: 130px;
  position: absolute;
  top: 200px;
  width: 1170px;
}

.purchase-virtual .heading {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 15px;
  padding: 0px 520.09px 1.16px 520.07px;
  position: absolute;
  top: -1px;
  width: 1140px;
}

.purchase-virtual .text-wrapper-25 {
  color: #212121;
  font-family: "Roboto", Helvetica;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28.6px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.purchase-virtual .form {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  left: 15px;
  position: absolute;
  top: 109px;
  width: 1140px;
}

.purchase-virtual .div-4 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  box-shadow: 0px 1px 1px #0000000d;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 1.25px;
  position: relative;
  width: 1140px;
}

.purchase-virtual .div-panel-body {
  align-self: stretch;
  height: 572.81px;
  position: relative;
  width: 100%;
}

.purchase-virtual .div-item-data {
  height: 150px;
  left: 15px;
  position: absolute;
  top: 15px;
  width: 1108px;
}

.purchase-virtual .div-img-wrapper {
  align-items: flex-start;
  background-image: url(../../../static/img/div-image-3.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 200px;
}

.purchase-virtual .div-img {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  object-fit: cover;
  position: relative;
}

.purchase-virtual .div-info {
  align-items: flex-start;
  display: inline-flex;
  left: 200px;
  padding: 0px 0px 0px 20px;
  position: absolute;
  top: -1px;
}

.purchase-virtual .heading-2 {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 0px 126.27px;
  position: relative;
}

.purchase-virtual .p {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-medium-15-6-font-family);
  font-size: var(--store-gpakorea-com-roboto-medium-15-6-font-size);
  font-style: var(--store-gpakorea-com-roboto-medium-15-6-font-style);
  font-weight: var(--store-gpakorea-com-roboto-medium-15-6-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-medium-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-medium-15-6-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-virtual .table {
  align-items: flex-start;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 15px;
  max-width: 1137.5px;
  padding: 0.63px 0px 5.68e-14px;
  position: absolute;
  top: 195px;
}

.purchase-virtual .div-5 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.purchase-virtual .cell {
  align-items: flex-start;
  background-color: var(--storegpakoreacomalto);
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  padding: 8.07px 779.6px 8.53px 7.98px;
  position: relative;
}

.purchase-virtual .text-wrapper-26 {
  color: #646464;
  font-family: var(--store-gpakorea-com-roboto-bold-13-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-13-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-13-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-13-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-bold-13-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-13-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-virtual .cell-2 {
  align-items: flex-start;
  background-color: var(--storegpakoreacomalto);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8.07px 42.53px 8.53px 42.51px;
  position: relative;
  width: 110px;
}

.purchase-virtual .text-wrapper-27 {
  color: #646464;
  font-family: var(--store-gpakorea-com-roboto-bold-13-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-13-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-13-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-13-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-bold-13-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-13-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.purchase-virtual .cell-3 {
  align-items: flex-start;
  background-color: var(--storegpakoreacomalto);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8.07px 7.99px 8.53px 127.05px;
  position: relative;
  width: 160px;
}

.purchase-virtual .text-wrapper-28 {
  color: #646464;
  font-family: var(--store-gpakorea-com-roboto-bold-13-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-13-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-13-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-13-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-bold-13-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-13-line-height);
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.purchase-virtual .div-6 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  margin-top: -0.01px;
  position: relative;
}

.purchase-virtual .data {
  align-items: flex-start;
  background-color: #ffffff;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 0.01px;
  padding: 30.01px 8px 30.56px;
  position: relative;
}

.purchase-virtual .p-pkg-summary {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 0px 0.54px;
  position: relative;
  width: 100%;
}

.purchase-virtual .text-wrapper-29 {
  color: #646464;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-virtual .div-box-qty-wrapper {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 8px;
  position: relative;
  width: 110px;
}

.purchase-virtual .div-box-qty {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 30px 42.14px 30px 42.13px;
  position: relative;
  width: 100%;
}

.purchase-virtual .text-wrapper-30 {
  color: #646464;
  font-family: var(--store-gpakorea-com-roboto-extrabold-15-6-font-family);
  font-size: var(--store-gpakorea-com-roboto-extrabold-15-6-font-size);
  font-style: var(--store-gpakorea-com-roboto-extrabold-15-6-font-style);
  font-weight: var(--store-gpakorea-com-roboto-extrabold-15-6-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-extrabold-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-extrabold-15-6-line-height);
  margin-left: -0.13px;
  margin-right: -0.14px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.purchase-virtual .link-5 {
  align-items: center;
  background-color: #555555;
  border-radius: 26px;
  display: flex;
  flex-direction: column;
  height: 26px;
  left: 0;
  padding: 0px 10.08px 0px 10.06px;
  position: absolute;
  top: 28px;
  width: 26px;
}

.purchase-virtual .text-wrapper-31 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 26px;
  margin-left: -0.07px;
  margin-right: -0.07px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.purchase-virtual .link-6 {
  align-items: center;
  background-color: #555555;
  border-radius: 26px;
  display: flex;
  flex-direction: column;
  height: 26px;
  left: 68px;
  padding: 0px 9.13px 0px 9.12px;
  position: absolute;
  top: 28px;
  width: 26px;
}

.purchase-virtual .text-wrapper-32 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 26px;
  margin-left: -0.12px;
  margin-right: -0.12px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.purchase-virtual .data-2 {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 39.31px 7.99px 40.38px 95.62px;
  position: relative;
  width: 160px;
}

.purchase-virtual .text-wrapper-33 {
  color: #646464;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.purchase-virtual .body-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 15px;
  max-width: 1137.5px;
  padding: 0.62px 0px 0.63px;
  position: absolute;
  top: 369px;
}

.purchase-virtual .body {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.purchase-virtual .cell-4 {
  align-items: flex-start;
  background-color: #f7f7f7;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 37.04px 8px 38.13px;
  position: relative;
  width: 350px;
}

.purchase-virtual .text-wrapper-34 {
  color: #212121;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1.25px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-virtual .div-input-group-wrapper {
  align-items: flex-start;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 16.25px 8px 15px;
  position: relative;
}

.purchase-virtual .div-input-group {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 1.25px;
  position: relative;
  width: 100%;
}

.purchase-virtual .textarea {
  align-self: stretch;
  background-color: #ffffff;
  height: 60px;
  position: relative;
  width: 100%;
}

.purchase-virtual .element-wrapper {
  align-items: flex-start;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 27.77px 8px 28.84px;
  position: relative;
  width: 350px;
}

.purchase-virtual .element {
  color: #212121;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1.25px;
  position: relative;
  width: fit-content;
}

.purchase-virtual .data-3 {
  align-items: flex-start;
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 15.63px 8px 15.62px;
  position: relative;
}

.purchase-virtual .div-placeholder-wrapper {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 60px;
  padding: 7.36px 8px 32.02px;
  position: relative;
  width: 100%;
}

.purchase-virtual .div-placeholder {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 0px 0.62px;
  position: relative;
  width: 100%;
}

.purchase-virtual .text-wrapper-35 {
  align-self: stretch;
  color: var(--storegpakoreacomdusty-gray);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
}

.purchase-virtual .div-panel-heading {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--storegpakoreacomwild-sand);
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: var(--storegpakoreacomalto);
  border-radius: 3px 3px 0px 0px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 10px 15px 11.25px;
  position: relative;
  width: 100%;
}

.purchase-virtual .text-wrapper-36 {
  align-self: stretch;
  color: #333333;
  font-family: var(--store-gpakorea-com-roboto-regular-14-3-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-14-3-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-14-3-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-14-3-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-regular-14-3-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-14-3-line-height);
  margin-top: -1.25px;
  position: relative;
}

.purchase-virtual .div-row-wrapper {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--storegpakoreacomcyan-aqua);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-top: -2.84e-14px;
  padding: 25px 0px;
  position: relative;
  width: 100%;
}

.purchase-virtual .div-row {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 1.14e-13px;
  justify-content: center;
  position: relative;
  width: 1137.5px;
}

.purchase-virtual .div-col-sm {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  min-height: 1px;
  padding: 35px 15px 0px;
  position: relative;
}

.purchase-virtual .div-box-coupon {
  height: 34.49px;
  position: relative;
  width: 538.75px;
}

.purchase-virtual .heading-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 8px;
}

.purchase-virtual .heading-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 5.99px;
  padding: 0px 0px 1.07px;
  position: relative;
  width: 100%;
}

.purchase-virtual .text-wrapper-37 {
  color: #555555;
  font-family: "Roboto", Helvetica;
  font-size: 15.6px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 17.2px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-virtual .text-wrapper-38 {
  color: #ff0000;
  font-family: "Roboto", Helvetica;
  font-size: 15.6px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 17.2px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-virtual .link-7 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #cccccc;
  border-radius: 4px;
  display: inline-flex;
  left: 458px;
  padding: 7.25px 13.25px;
  position: absolute;
  top: 0;
}

.purchase-virtual .text-wrapper-39 {
  color: #333333;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.purchase-virtual .div-box-point {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.purchase-virtual .heading-4 {
  height: 17.15px;
  position: relative;
  width: 538.75px;
}

.purchase-virtual .text-wrapper-40 {
  color: #555555;
  font-family: "Roboto", Helvetica;
  font-size: 15.6px;
  font-weight: 700;
  height: 17px;
  left: 0;
  letter-spacing: 0;
  line-height: 17.2px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 69px;
}

.purchase-virtual .small-2 {
  color: #555555;
  font-family: var(--store-gpakorea-com-roboto-regular-13-3-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-13-3-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-13-3-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-13-3-font-weight);
  height: 13px;
  left: 75px;
  letter-spacing: var(--store-gpakorea-com-roboto-regular-13-3-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-13-3-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 89px;
}

.purchase-virtual .div-input-group-2 {
  align-items: flex-start;
  background-color: #ffffff;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}

.purchase-virtual .input-2 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  border-radius: 4px 0px 0px 4px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 33.98px;
  padding: 7.25px 13.25px;
  position: relative;
  z-index: 1;
}

.purchase-virtual .div-7 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow: scroll;
  position: relative;
  width: 100%;
}

.purchase-virtual .text-wrapper-41 {
  color: #555555;
  font-family: var(--store-gpakorea-com-roboto-regular-14-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-14-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-14-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-14-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-regular-14-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-14-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-virtual .span-input-group-btn {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  margin-left: -1.14e-13px;
  padding: 0px 0.01px 0px 9px;
  position: relative;
  z-index: 0;
}

.purchase-virtual .link-8 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #cccccc;
  border-radius: 0px 4px 4px 0px;
  display: inline-flex;
  flex: 0 0 auto;
  padding: 7.25px 13.25px;
  position: relative;
}

.purchase-virtual .table-wrapper {
  align-items: flex-start;
  border-color: var(--storegpakoreacomalto);
  border-left-style: solid;
  border-left-width: 1.25px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  min-height: 1px;
  padding: 0px 15px 0px 16.25px;
  position: relative;
}

.purchase-virtual .table-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  max-width: 568.75px;
  position: relative;
  width: 100%;
}

.purchase-virtual .row {
  height: 38.26px;
  position: relative;
  width: 537.5px;
}

.purchase-virtual .cell-5 {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-bold-15-6-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-15-6-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-15-6-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-15-6-font-weight);
  height: 22px;
  left: 8px;
  letter-spacing: var(--store-gpakorea-com-roboto-bold-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-15-6-line-height);
  position: absolute;
  top: 6px;
  white-space: nowrap;
  width: 99px;
}

.purchase-virtual .data-4 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 252px;
  padding: 9.31px 8px 9.85px;
  position: absolute;
  top: 0;
}

.purchase-virtual .div-wrapper-3 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 0px 0.54px;
  position: relative;
  width: 100%;
}

.purchase-virtual .text-wrapper-42 {
  color: #212121;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.purchase-virtual .cell-6 {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-regular-15-6-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-15-6-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-15-6-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-15-6-font-weight);
  height: 22px;
  left: 8px;
  letter-spacing: var(--store-gpakorea-com-roboto-regular-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-15-6-line-height);
  position: absolute;
  top: 6px;
  white-space: nowrap;
  width: 50px;
}

.purchase-virtual .cell-7 {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-bold-15-6-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-15-6-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-15-6-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-15-6-font-weight);
  height: 22px;
  left: 8px;
  letter-spacing: var(--store-gpakorea-com-roboto-bold-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-15-6-line-height);
  position: absolute;
  top: 6px;
  white-space: nowrap;
  width: 45px;
}

.purchase-virtual .p-total-vat-amount-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 252px;
  padding: 9.31px 8px 9.84px;
  position: absolute;
  top: 0;
}

.purchase-virtual .cell-8 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 1.14px;
  padding: 28.85px 8px 7.98px;
  position: relative;
}

.purchase-virtual .text-wrapper-43 {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-bold-15-6-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-15-6-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-15-6-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-15-6-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-bold-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-15-6-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-virtual .div-wrapper-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.purchase-virtual .text-wrapper-44 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 0;
  color: #212121;
  display: -webkit-box;
  font-family: var(--store-gpakorea-com-roboto-bold-13-3-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-13-3-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-13-3-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-13-3-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-bold-13-3-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-13-3-line-height);
  margin-top: -1px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
}

.purchase-virtual .p-total-amount-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-left: -0.01px;
  padding: 32.05px 8px 10.04px;
  position: relative;
}

.purchase-virtual .p-total-amount {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.purchase-virtual .text-wrapper-45 {
  color: #ff0000;
  font-family: var(--store-gpakorea-com-roboto-bold-26-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-26-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-26-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-26-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-bold-26-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-26-line-height);
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.purchase-virtual .div-panel-pay-method {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  box-shadow: 0px 1px 1px #0000000d;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 0.01px;
  padding: 1.25px;
  position: relative;
  width: 1140px;
}

.purchase-virtual .text-wrapper-46 {
  align-self: stretch;
  color: #333333;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1.25px;
  position: relative;
}

.purchase-virtual .div-panel-body-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 18.8px;
  padding: 14.47px 15px 15px;
  position: relative;
  width: 100%;
}

.purchase-virtual .label {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  max-width: 1137.5px;
  padding: 0px 0px 0.54px 20px;
  position: relative;
}

.purchase-virtual .span-far {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 17px;
  left: 0;
  padding: 0px 1.95px 0.94px 1.93px;
  position: absolute;
  top: 1px;
  width: 19px;
}

.purchase-virtual .symbol-6 {
  color: #d9d9d9;
  font-family: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-font-family);
  font-size: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-font-size);
  font-style: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-font-style);
  font-weight: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-font-weight);
  letter-spacing: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-line-height);
  margin-left: -0.2px;
  margin-right: -0.2px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.purchase-virtual .text-wrapper-47 {
  color: #505050;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-virtual .span-fas {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 17px;
  left: 0;
  padding: 0px 1.94px 0.94px;
  position: absolute;
  top: 1px;
  width: 19px;
}

.purchase-virtual .symbol-7 {
  color: #232323;
  font-family: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-font-family);
  font-size: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-font-size);
  font-style: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-font-style);
  font-weight: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-font-weight);
  letter-spacing: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-line-height);
  margin-left: -0.2px;
  margin-right: -0.2px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.purchase-virtual .div-panel-taxinvoice {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  box-shadow: 0px 1px 1px #0000000d;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 1.25px;
  position: relative;
  width: 100%;
}

.purchase-virtual .div-panel-body-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 11.02px;
  margin-top: -6.39e-14px;
  padding: 13.98px 15px 15px;
  position: relative;
  width: 100%;
}

.purchase-virtual .small-3 {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-regular-11-1-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-11-1-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-11-1-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-11-1-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-regular-11-1-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-11-1-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-virtual .table-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  max-width: 1137.5px;
  padding: 0.62px 0px 0.63px;
  position: relative;
  width: 100%;
}

.purchase-virtual .body-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  margin-right: -0.02px;
  position: relative;
}

.purchase-virtual .cell-9 {
  align-items: flex-start;
  background-color: #f7f7f7;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 22.78px 8px 23.88px;
  position: relative;
  width: 200px;
}

.purchase-virtual .data-5 {
  align-items: flex-start;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 16.25px 713.04px 15px 7.98px;
  position: relative;
}

.purchase-virtual .input-wrapper {
  align-items: flex-start;
  background-color: #ffffff;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.purchase-virtual .input-3 {
  align-self: stretch;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  height: 33.98px;
  position: relative;
  width: 100%;
}

.purchase-virtual .div-input-group-3 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.purchase-virtual .cell-10 {
  align-items: flex-start;
  background-color: #f7f7f7;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 44.78px 8px 45.87px;
  position: relative;
  width: 200px;
}

.purchase-virtual .data-6 {
  align-items: flex-start;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 16.25px 8px 15px;
  position: relative;
}

.purchase-virtual .div-input-group-4 {
  align-items: flex-start;
  background-color: #ffffff;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 0.01px;
  justify-content: center;
  position: relative;
}

.purchase-virtual .input-4 {
  align-items: flex-start;
  background-color: var(--storegpakoreacomgallery);
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  border-radius: 4px 0px 0px 4px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 33.98px;
  padding: 7.25px 13.25px;
  position: relative;
  z-index: 1;
}

.purchase-virtual .text-wrapper-48 {
  color: var(--storegpakoreacomdusty-gray);
  font-family: var(--store-gpakorea-com-roboto-regular-14-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-14-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-14-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-14-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-regular-14-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-14-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-virtual .span-input-group-btn-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  padding: 0.99px 0px 1px 9px;
  position: relative;
  z-index: 0;
}

.purchase-virtual .link-9 {
  align-items: flex-start;
  background-color: var(--storegpakoreacomemperor);
  border-radius: 0px 4px 4px 0px;
  display: inline-flex;
  flex: 0 0 auto;
  padding: 8.38px 12px 9.61px;
  position: relative;
}

.purchase-virtual .symbol-8 {
  color: #ffffff;
  font-family: "Font Awesome 5 Free-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.purchase-virtual .text-wrapper-49 {
  color: #ffffff;
  font-family: "Font Awesome 5 Free-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14px;
}

.purchase-virtual .text-wrapper-50 {
  font-family: "Malgun Gothic-Bold", Helvetica;
  font-weight: 700;
}

.purchase-virtual .input-5 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  display: flex;
  flex-direction: column;
  height: 33.98px;
  padding: 7.25px 13.25px;
  position: relative;
  width: 100%;
}

.purchase-virtual .text-wrapper-51 {
  align-self: stretch;
  color: var(--storegpakoreacomdusty-gray);
  font-family: var(--store-gpakorea-com-roboto-regular-14-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-14-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-14-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-14-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-regular-14-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-14-line-height);
  margin-top: -1px;
  position: relative;
}

.purchase-virtual .cell-11 {
  align-items: flex-start;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 22.78px 8px 23.88px;
  position: relative;
  width: 200px;
}

.purchase-virtual .overlap-group-wrapper {
  background-color: var(--storegpakoreacomconifer);
  border: 1px solid;
  border-color: var(--storegpakoreacomconifer);
  border-radius: 4px;
  height: 52px;
  left: 15px;
  position: absolute;
  top: 1992px;
  width: 1140px;
}

.purchase-virtual .overlap-group-2 {
  height: 26px;
  left: 522px;
  position: relative;
  top: 12px;
  width: 96px;
}

.purchase-virtual .symbol-9 {
  color: #ffffff;
  font-family: var(--store-gpakorea-com-font-awesome-5-free-solid-18-2-font-family);
  font-size: var(--store-gpakorea-com-font-awesome-5-free-solid-18-2-font-size);
  font-style: var(--store-gpakorea-com-font-awesome-5-free-solid-18-2-font-style);
  font-weight: var(--store-gpakorea-com-font-awesome-5-free-solid-18-2-font-weight);
  height: 18px;
  left: 0;
  letter-spacing: var(--store-gpakorea-com-font-awesome-5-free-solid-18-2-letter-spacing);
  line-height: var(--store-gpakorea-com-font-awesome-5-free-solid-18-2-line-height);
  position: absolute;
  text-align: center;
  top: 5px;
  white-space: nowrap;
  width: 21px;
}

.purchase-virtual .text-wrapper-52 {
  color: #ffffff;
  font-family: var(--store-gpakorea-com-roboto-regular-18-2-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-18-2-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-18-2-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-18-2-font-weight);
  height: 26px;
  left: 20px;
  letter-spacing: var(--store-gpakorea-com-roboto-regular-18-2-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-18-2-line-height);
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 75px;
}

.purchase-virtual .footer-panel {
  align-items: center;
  background-color: #606060;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 2310px;
  width: 1440px;
}

.purchase-virtual .footer-frame {
  height: 160px;
  position: relative;
  width: 1160px;
}

.purchase-virtual .company-infos {
  height: 72px;
  left: 213px;
  position: absolute;
  top: 43px;
  width: 683px;
}

.purchase-virtual .flexcontainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 72px;
  position: relative;
  width: 681px;
}

.purchase-virtual .text-i {
  align-self: stretch;
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
  position: relative;
}

.purchase-virtual .text-wrapper-53 {
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
}

.purchase-virtual .sns-link {
  height: 40px;
  left: 949px;
  position: absolute;
  top: 60px;
  width: 200px;
}

.purchase-virtual .list-item-link {
  background-image: url(../../../static/img/list-item-link.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 46px;
}

.purchase-virtual .text-wrapper-54 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 40px;
}

.purchase-virtual .list-item-link-2 {
  background-image: url(../../../static/img/list-item-link-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 51px;
  position: absolute;
  top: 0;
  width: 46px;
}

.purchase-virtual .list-item-link-3 {
  background-image: url(../../../static/img/list-item-link-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 103px;
  position: absolute;
  top: 0;
  width: 46px;
}

.purchase-virtual .text-wrapper-55 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 53px;
}

.purchase-virtual .list-item-link-4 {
  background-image: url(../../../static/img/list-item-link-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 154px;
  position: absolute;
  top: 0;
  width: 46px;
}

.purchase-virtual .text-wrapper-56 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 66px;
}

.purchase-virtual .logo-gothic {
  height: 111px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 25px;
  width: 145px;
}

.purchase-virtual .kakao-service-instance {
  left: 0 !important;
  position: absolute !important;
  top: 200px !important;
}

.purchase-virtual .jimmed-products-instance {
  left: 1310px !important;
  position: absolute !important;
  top: 560px !important;
}

.purchase-virtual .viewed-products-instance {
  left: 1310px !important;
  position: absolute !important;
  top: 200px !important;
}
