.PD-body-price-side {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px 0px 40px;
  position: absolute;
  right: 0;
  top: 3542px;
  width: 400px;
}

.PD-body-price-side .div-item-top {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 278.3px;
  position: relative;
  width: 100%;
}

.PD-body-price-side .div-item-share {
  background-color: #dddddd;
  border-radius: 24px;
  height: 24px;
  overflow: hidden;
  position: relative;
  width: 24px;
  z-index: 1;
}

.PD-body-price-side .link-6 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 24px;
  padding: 6px 6.31px 5px;
  position: relative;
  width: 24px;
}

.PD-body-price-side .symbol-8 {
  color: #333333;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 13px;
  margin-left: -0.31px;
  margin-right: -0.31px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.PD-body-price-side .div-item-fav {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  z-index: 0;
}

.PD-body-price-side .link-7 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.PD-body-price-side .symbol-9 {
  color: #333333;
  font-family: "Font Awesome 5 Free-Regular", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 13px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.PD-body-price-side .text-wrapper-25 {
  color: #333333;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.PD-body-price-side .text-wrapper-26 {
  color: #cccccc;
  font-family: var(--chagaun-net-roboto-regular-13-font-family);
  font-size: var(--chagaun-net-roboto-regular-13-font-size);
  font-style: var(--chagaun-net-roboto-regular-13-font-style);
  font-weight: var(--chagaun-net-roboto-regular-13-font-weight);
  letter-spacing: var(--chagaun-net-roboto-regular-13-letter-spacing);
  line-height: var(--chagaun-net-roboto-regular-13-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.PD-body-price-side .text-wrapper-27 {
  color: #333333;
  font-family: var(--chagaun-net-roboto-regular-13-font-family);
  font-size: var(--chagaun-net-roboto-regular-13-font-size);
  font-style: var(--chagaun-net-roboto-regular-13-font-style);
  font-weight: var(--chagaun-net-roboto-regular-13-font-weight);
  letter-spacing: var(--chagaun-net-roboto-regular-13-letter-spacing);
  line-height: var(--chagaun-net-roboto-regular-13-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.PD-body-price-side .div-item-title {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 39.42px;
  position: relative;
  width: 100%;
}

.PD-body-price-side .heading-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.PD-body-price-side .text-wrapper-28 {
  align-self: stretch;
  color: #212121;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 33.3px;
  margin-top: -1px;
  position: relative;
}

.PD-body-price-side .div-pkg-group-list {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.PD-body-price-side .heading-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 0px 0.56px;
  position: relative;
  width: 100%;
}

.PD-body-price-side .text-wrapper-29 {
  align-self: stretch;
  color: #212121;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17.2px;
  margin-top: -1px;
  position: relative;
}

.PD-body-price-side .list-2 {
  align-items: flex-start;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #222222;
  border-left-style: solid;
  border-left-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 0px 0px;
  padding: 0px 0px 1px;
  position: relative;
  width: 100%;
}

.PD-body-price-side .item-link {
  align-items: flex-start;
  background-color: #dddddd;
  border-color: #222222;
  border-left-style: solid;
  border-left-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex-direction: column;
  padding: 13px 31px 12px 16px;
  position: relative;
  width: 400px;
}

.PD-body-price-side .text-wrapper-30 {
  color: #333333;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.PD-body-price-side .span-fas {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 38.68%;
  left: 93.83%;
  padding: 0px 0px 0.79px;
  position: absolute;
  top: 30.3%;
  width: 3.42%;
}

.PD-body-price-side .symbol-10 {
  color: #333333;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 15.6px;
  margin-right: -0.34px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.PD-body-price-side .table-2 {
  align-items: flex-start;
  border: 1px solid;
  border-color: #222222;
  display: flex;
  flex-direction: column;
  max-width: 694px;
  position: relative;
  width: 400px;
}

.PD-body-price-side .header-row-2 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #dddddd;
  display: flex;
  flex-direction: column;
  height: 50px;
  justify-content: center;
  padding: 3px 0px 7px;
  position: relative;
  width: 400px;
}

.PD-body-price-side .small-4 {
  height: 20px;
  position: relative;
  width: 400px;
}

.PD-body-price-side .element-4 {
  color: #646464;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  height: 15px;
  left: 5px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 4px;
  white-space: nowrap;
  width: 390px;
}

.PD-body-price-side .strong-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 20px;
  padding: 0px 5px;
  position: relative;
  width: 400px;
}

.PD-body-price-side .text-wrapper-31 {
  align-self: stretch;
  color: #646464;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
}

.PD-body-price-side .body-row-2 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #dddddd;
  display: flex;
  flex-direction: column;
  height: 70px;
  padding: 5px 0px;
  position: relative;
  width: 400px;
}

.PD-body-price-side .strong-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 0px 5px;
  position: relative;
  width: 100%;
}

.PD-body-price-side .text-wrapper-32 {
  color: #646464;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 500;
  height: 20px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.PD-body-price-side .small-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 40px;
  padding: 0px 5px;
  position: relative;
  width: 400px;
}

.PD-body-price-side .text-wrapper-33 {
  align-self: stretch;
  color: #777777;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 40px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.PD-body-price-side .footer-row-2 {
  align-items: center;
  display: flex;
  gap: 10px;
  height: 40px;
  padding: 6px 10px;
  position: relative;
  width: 400px;
}

.PD-body-price-side .link-8 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #cccccc;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 28px;
  justify-content: center;
  padding: 6px 10px;
  position: relative;
  width: 185px;
}

.PD-body-price-side .text-wrapper-34 {
  align-self: stretch;
  color: #333333;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.PD-body-price-side .link-9 {
  align-items: center;
  background-color: #b1db33;
  border: 1px solid;
  border-color: #b1db33;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 28px;
  justify-content: center;
  padding: 6px 10px;
  position: relative;
  width: 185px;
}

.PD-body-price-side .text-wrapper-35 {
  align-self: stretch;
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}
