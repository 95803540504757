.merchant-list {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.merchant-list .div-2 {
  background-color: #ffffff;
  height: 1600px;
  position: relative;
  width: 1640px;
}

.merchant-list .menu-section {
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 40px;
  width: 1640px;
}

.merchant-list .main-logo-instance {
  top: unset !important;
}

.merchant-list .right-menu-instance {
  align-items: flex-start !important;
  display: flex !important;
  height: 50px !important;
  left: unset !important;
  top: unset !important;
  width: 234px !important;
}

.merchant-list .middle-section {
  align-items: flex-start;
  display: flex;
  height: 1300px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 100px;
  width: 1640px;
}

.merchant-list .left-menu-instance {
  top: unset !important;
  width: 240px !important;
}

.merchant-list .body-area {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  padding: 10px;
  position: relative;
}

.merchant-list .body-title {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.merchant-list .title {
  color: #000000;
  flex: 1;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  height: 25px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
}

.merchant-list .body-head {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 10px 5px;
  position: relative;
  width: 100%;
}

.merchant-list .search {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 5px 5px;
  padding: 10px 5px;
  position: relative;
  width: 320px;
}

.merchant-list .dropdown {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  height: 28px;
  padding: 4.5px 5px;
  position: relative;
  width: 124px;
}

.merchant-list .text-wrapper-7 {
  color: #000000;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.merchant-list .dropdown-2 {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  flex: 1;
  flex-grow: 1;
  height: 28px;
  padding: 4.5px 5px;
  position: relative;
}

.merchant-list .element {
  color: #000000;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
}

.merchant-list .div-3 {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 5px 5px;
  padding: 10px 5px;
  position: relative;
  width: 240px;
}

.merchant-list .dropdown-3 {
  align-items: center;
  display: flex;
  height: 28px;
  padding: 4.5px 5px;
  position: relative;
  width: 60px;
}

.merchant-list .text-wrapper-8 {
  color: #000000;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
}

.merchant-list .nation {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 5px 5px;
  padding: 10px 5px;
  position: relative;
  width: 230px;
}

.merchant-list .dropdown-4 {
  align-items: center;
  display: flex;
  height: 28px;
  padding: 4.5px 5px;
  position: relative;
  width: 50px;
}

.merchant-list .left-right-frame {
  align-items: center;
  display: flex;
  gap: 5px;
  height: 48px;
  justify-content: center;
  padding: 10px 5px;
  position: relative;
  width: 140px;
}

.merchant-list .div-wrapper-2 {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 28px;
  justify-content: center;
  position: relative;
  width: 40px;
}

.merchant-list .text-wrapper-9 {
  align-self: stretch;
  color: #666666;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.merchant-list .text-wrapper-10 {
  align-self: stretch;
  color: #bcbcbc;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.merchant-list .best-body {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 10px 10px;
  min-width: 240px;
  padding: 10px 5px;
  position: relative;
  width: 100%;
}

.merchant-list .best-item {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 280px;
  padding: 0px 0px 20px;
  position: relative;
  width: 220px;
}

.merchant-list .thumbnail {
  align-self: stretch;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #e1e1e1;
  border-radius: 6px;
  height: 160px;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
}

.merchant-list .overlap-group {
  background-image: url(../../../static/img/div-img.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 159px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 219px;
}

.merchant-list .button-box-wrapper {
  all: unset;
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  left: 190px;
  opacity: 0.8;
  position: relative;
  top: 9px;
}

.merchant-list .button-box {
  color: #888888;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 18.2px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.merchant-list .item-info {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100px;
  padding: 0px 2px;
  position: relative;
  width: 100%;
  z-index: 0;
}

.merchant-list .item-title {
  align-items: flex-start;
  display: flex;
  height: 40px;
  margin-right: -4.00px;
  padding: 2px 0px;
  position: relative;
  width: 220px;
}

.merchant-list .item-title-text {
  color: #333333;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
}

.merchant-list .div-4 {
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: space-between;
  margin-right: -4.00px;
  padding: 1.5px 0px;
  position: relative;
  width: 220px;
}

.merchant-list .text-wrapper-11 {
  color: #333333;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  width: fit-content;
}

.merchant-list .text-wrapper-12 {
  color: #333333;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.merchant-list .text-wrapper-13 {
  color: #333333;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  width: fit-content;
}

.merchant-list .product-price {
  color: #333333;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  width: fit-content;
}

.merchant-list .price-floor {
  color: #333333;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.merchant-list .determin-price {
  color: #333333;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  width: fit-content;
}
