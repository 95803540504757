.ipr-list {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.ipr-list .div-2 {
  background-color: #ffffff;
  height: 1690px;
  overflow: hidden;
  position: relative;
  width: 1640px;
}

.ipr-list .bulletin-body {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 1300px;
  left: 240px;
  padding: 10px;
  position: absolute;
  top: 100px;
  width: 1400px;
}

.ipr-list .body {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  overflow: hidden;
  padding: 5px 0px 95px;
  position: relative;
  width: 100%;
}

.ipr-list .bulletin-body-title {
  align-self: stretch;
  height: 25px;
  margin-right: -2.00px;
  position: relative;
  width: 100%;
}

.ipr-list .title {
  color: #000000;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 25px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 1380px;
}

.ipr-list .head {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 620px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.ipr-list .summary {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 360px;
}

.ipr-list .summary-2 {
  align-items: center;
  display: flex;
  height: 28px;
  position: relative;
  width: 360px;
}

.ipr-list .text-wrapper-13 {
  color: #000000;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.50px;
  position: relative;
}

.ipr-list .summary-3 {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  height: 28px;
  position: relative;
  width: 180px;
}

.ipr-list .text-wrapper-14 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: 90px;
}

.ipr-list .summary-4 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #bcbcbc;
  border-right-style: solid;
  border-right-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  height: 28px;
  position: relative;
  width: 180px;
}

.ipr-list .summary-5 {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 28px;
  position: relative;
  width: 100%;
}

.ipr-list .summary-6 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #bcbcbc;
  border-left-style: solid;
  border-left-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
  display: flex;
  height: 28px;
  position: relative;
  width: 180px;
}

.ipr-list .summary-7 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #bcbcbc;
  border-right-style: solid;
  border-right-width: 1px;
  display: flex;
  height: 28px;
  position: relative;
  width: 180px;
}

.ipr-list .design-component-instance-node {
  left: unset !important;
  top: unset !important;
}

.ipr-list .bulletin-body-table {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.ipr-list .form-table {
  align-items: flex-start;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #adadad;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 1380px;
}

.ipr-list .head-frame {
  align-items: flex-start;
  display: flex;
  height: 50px;
  position: relative;
  width: 1380px;
}

.ipr-list .body-row-cell {
  align-items: center;
  background-color: #656978;
  display: flex;
  gap: 10px;
  height: 50px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
  width: 70px;
}

.ipr-list .text-wrapper-15 {
  color: #ffffff;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 15px;
  font-weight: 700;
  height: 21px;
  letter-spacing: 0;
  line-height: 21px;
  position: relative;
  text-align: center;
  white-space: nowrap;
}

.ipr-list .body-row-cell-2 {
  align-items: center;
  background-color: #656978;
  display: flex;
  gap: 10px;
  height: 50px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
  width: 100px;
}

.ipr-list .text-wrapper-16 {
  color: #ffffff;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 21px;
  position: relative;
  text-align: center;
}

.ipr-list .body-row-cell-3 {
  align-items: center;
  background-color: #656978;
  display: flex;
  gap: 10px;
  height: 50px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
  width: 120px;
}

.ipr-list .body-row-cell-4 {
  align-items: center;
  background-color: #656978;
  display: flex;
  gap: 10px;
  height: 50px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
  width: 310px;
}

.ipr-list .record-frame {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 0px 0px;
  height: 45px;
  position: relative;
  width: 1380px;
}

.ipr-list .body-row-data {
  align-items: center;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  display: flex;
  gap: 10px;
  height: 45px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
  width: 70px;
}

.ipr-list .text-wrapper-17 {
  color: #666666;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 15px;
  font-weight: 400;
  height: 21px;
  letter-spacing: 0;
  line-height: 21px;
  position: relative;
  text-align: center;
  white-space: nowrap;
}

.ipr-list .body-row-data-2 {
  align-items: center;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  display: flex;
  gap: 10px;
  height: 45px;
  padding: 9px 5px;
  position: relative;
  width: 100px;
}

.ipr-list .body-row-data-3 {
  align-items: center;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  display: flex;
  gap: 10px;
  height: 45px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
  width: 120px;
}

.ipr-list .body-row-data-4 {
  align-items: center;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  display: flex;
  gap: 10px;
  height: 45px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
  width: 310px;
}

.ipr-list .text-wrapper-18 {
  color: #666666;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 15px;
  font-weight: 400;
  height: 21px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.ipr-list .body-row-data-5 {
  align-items: center;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  display: flex;
  gap: 10px;
  height: 45px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
  width: 100px;
}

.ipr-list .footer-panel-instance {
  left: -100px !important;
  position: absolute !important;
  top: 1538px !important;
  width: 1840px !important;
}

.ipr-list .left-menu-instance {
  left: 0 !important;
  position: absolute !important;
  width: 240px !important;
}

.ipr-list .main-logo-instance {
  left: 0 !important;
  position: absolute !important;
  top: 40px !important;
  width: 240px !important;
}

.ipr-list .right-menu-instance {
  position: absolute !important;
}
