.message-panel {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 440px;
  padding: 20px 50px;
  position: relative;
  width: 625px;
}

.message-panel .MPB {
  height: 400px;
  overflow: hidden;
  position: relative;
  width: 525px;
}

.message-panel .message-body {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #ff0000;
  height: 96px;
  left: -1px;
  position: absolute;
  top: 199px;
  width: 527px;
}

.message-panel .message-body-text {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 525px;
}

.message-panel .button {
  all: unset;
  align-items: center;
  background-color: #27ae60;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 0;
  padding: 16px 32px;
  position: absolute;
  top: 345px;
  width: 160px;
}

.message-panel .button-box {
  flex: 1;

  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.message-panel .button-box-wrapper {
  all: unset;
  background-color: #27ae60;
  box-sizing: border-box;
  height: 55px;
  left: 365px;
  position: absolute;
  top: 345px;
  width: 160px;
}

.message-panel .button-box-2 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;

  left: 32px;
  position: absolute;
  text-align: center;
  top: 15px;
  width: 96px;
}

.message-panel .message-title {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 0;
  padding: 16px;
  position: absolute;
  top: 100px;
  width: 525px;
}

.message-panel .message-title-text {
  color: #27ae60;
  font-family: "Roboto", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.message-panel .message-logo {
  height: 65px;
  left: 230px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 65px;
}
