.malseller-list {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.malseller-list .div-3 {
  background-color: #ffffff;
  height: 1400px;
  position: relative;
  width: 1640px;
}

.malseller-list .menu-section-2 {
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 40px;
  width: 1640px;
}

.malseller-list .design-component-instance-node {
  top: unset !important;
  left: unset !important;
}

.malseller-list .right-menu-2 {
  align-items: flex-start !important;
  display: flex !important;
  height: 50px !important;
  left: unset !important;
  top: unset !important;
  width: 234px !important;
}

.malseller-list .middle-section-2 {
  align-items: flex-start;
  display: flex;
  height: 1300px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 100px;
  width: 1640px;
}

.malseller-list .left-menu-2 {
  top: unset !important;
  width: 240px !important;
}

.malseller-list .body-area-2 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  height: 1300px;
  padding: 10px;
  position: relative;
}

.malseller-list .title-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.malseller-list .title-2 {
  color: #000000;
  flex: 1;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 25px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
}

.malseller-list .body-head-2 {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 10px;
  position: relative;
  width: 100%;
}

.malseller-list .dropdown-wrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 10px;
  padding: 10px;
  position: relative;
  width: 200px;
}

.malseller-list .dropdown-5 {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  flex: 1;
  flex-grow: 1;
  height: 28px;
  padding: 4.5px 5px;
  position: relative;
}

.malseller-list .element-2 {
  color: #000000;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
}

.malseller-list .nation-2 {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 10px;
  padding: 10px;
  position: relative;
  width: 240px;
}

.malseller-list .dropdown-6 {
  align-items: center;
  display: flex;
  height: 28px;
  padding: 4.5px 5px;
  position: relative;
  width: 50px;
}

.malseller-list .text-wrapper-12 {
  color: #000000;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
}

.malseller-list .text-wrapper-13 {
  color: #000000;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.malseller-list .platform-2 {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 10px;
  padding: 10px;
  position: relative;
  width: 280px;
}

.malseller-list .dropdown-7 {
  align-items: center;
  display: flex;
  height: 28px;
  padding: 4.5px 5px;
  position: relative;
  width: 60px;
}

.malseller-list .seller-risk-2 {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 10px;
  padding: 10px;
  position: relative;
  width: 320px;
}

.malseller-list .dropdown-8 {
  align-items: center;
  display: flex;
  height: 28px;
  padding: 4.5px 5px;
  position: relative;
  width: 120px;
}

.malseller-list .bulletin-body-table {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 950px;
  position: relative;
  width: 100%;
}

.malseller-list .form-table {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #adadad;
  display: flex;
  flex-direction: column;
  height: 950px;
  position: relative;
  width: 100%;
}

.malseller-list .head-frame {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  height: 50px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.malseller-list .body-row-cell {
  align-items: center;
  background-color: #656978;
  display: flex;
  gap: 10px;
  height: 50px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
  width: 70px;
}

.malseller-list .text-wrapper-14 {
  color: #ffffff;
  flex: 1;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 15px;
  font-weight: 700;
  height: 21px;
  letter-spacing: 0;
  line-height: 21px;
  position: relative;
  text-align: center;
  white-space: nowrap;
}

.malseller-list .body-row-cell-2 {
  align-items: center;
  background-color: #656978;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  height: 50px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
}

.malseller-list .text-wrapper-15 {
  color: #ffffff;
  flex: 1;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 15px;
  font-weight: 700;
  height: 21px;
  letter-spacing: 0;
  line-height: 21px;
  position: relative;
  white-space: nowrap;
}

.malseller-list .body-row-cell-3 {
  align-items: center;
  background-color: #656978;
  display: flex;
  gap: 10px;
  height: 50px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
  width: 120px;
}

.malseller-list .body-row-cell-4 {
  align-items: center;
  background-color: #656978;
  display: flex;
  gap: 10px;
  height: 50px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
  width: 140px;
}

.malseller-list .text-wrapper-16 {
  color: #ffffff;
  flex: 1;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 21px;
  position: relative;
  text-align: center;
}

.malseller-list .record-frame {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-wrap: wrap;
  gap: 0px 0px;
  height: 45px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.malseller-list .body-row-data {
  align-items: center;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  display: flex;
  gap: 10px;
  height: 45px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
  width: 70px;
}

.malseller-list .text-wrapper-17 {
  color: #666666;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 15px;
  font-weight: 400;
  height: 21px;
  letter-spacing: 0;
  line-height: 21px;
  position: relative;
  text-align: center;
  white-space: nowrap;
}

.malseller-list .body-row-data-2 {
  align-items: center;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  height: 45px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
}

.malseller-list .text-wrapper-18 {
  color: #666666;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 15px;
  font-weight: 400;
  height: 21px;
  letter-spacing: 0;
  line-height: 21px;
  position: relative;
  white-space: nowrap;
}

.malseller-list .body-row-data-3 {
  align-items: center;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  display: flex;
  gap: 10px;
  height: 45px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
  width: 120px;
}

.malseller-list .text-wrapper-19 {
  color: #666666;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 15px;
  font-weight: 400;
  height: 21px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.malseller-list .body-row-data-4 {
  align-items: center;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  display: flex;
  gap: 10px;
  height: 45px;
  padding: 9px 5px;
  position: relative;
  width: 140px;
}

.malseller-list .body-page-instance {
  left: unset !important;
  top: unset !important;
}
