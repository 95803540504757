.cache-deposit {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.cache-deposit .div-34 {
  background-color: #ffffff;
  height: 2020px;
  position: relative;
  width: 1440px;
}

.cache-deposit .right-menu-10 {
  left: 1034px !important;
  position: absolute !important;
  top: 0 !important;
}

.cache-deposit .main-logo-11 {
  left: 131px !important;
  position: absolute !important;
  top: 50px !important;
}

.cache-deposit .search-area-11 {
  left: 541px !important;
  position: absolute !important;
  top: 50px !important;
}

.cache-deposit .nav-menu-11 {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.cache-deposit .link-tree-11 {
  left: 131px !important;
  position: absolute !important;
  top: 150px !important;
}

.cache-deposit .my-page-menu-2 {
  left: 130px !important;
  position: absolute !important;
  top: 200px !important;
}

.cache-deposit .my-page-avata-2 {
  left: 370px !important;
  position: absolute !important;
  top: 200px !important;
  width: 930px !important;
}

.cache-deposit .div-35 {
  height: 1414px;
  left: 370px;
  position: absolute;
  top: 400px;
  width: 930px;
}

.cache-deposit .form-5 {
  height: 1360px;
  left: 0;
  position: absolute;
  top: 0;
  width: 930px;
}

.cache-deposit .list-wrapper {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  display: flex;
  flex-direction: column;
  left: 15px;
  padding: 1.25px;
  position: absolute;
  top: 0;
  width: 512px;
}

.cache-deposit .div-36 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.cache-deposit .item-2 {
  align-items: flex-start;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: var(--storegpakoreacomalto);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 0px 1.25px;
  position: relative;
  width: 100%;
}

.cache-deposit .div-37 {
  align-self: stretch;
  height: 50.41px;
  max-width: 510px;
  position: relative;
  width: 100%;
}

.cache-deposit .symbol-wrapper-5 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 15px;
  padding: 0px 2.16px 1.09px 2.12px;
  position: absolute;
  top: 14px;
  width: 21px;
}

.cache-deposit .symbol-20 {
  color: #232323;
  font-family: var(--store-gpakorea-com-font-awesome-5-free-solid-17-2-font-family);
  font-size: var(--store-gpakorea-com-font-awesome-5-free-solid-17-2-font-size);
  font-style: var(--store-gpakorea-com-font-awesome-5-free-solid-17-2-font-style);
  font-weight: var(--store-gpakorea-com-font-awesome-5-free-solid-17-2-font-weight);
  letter-spacing: var(--store-gpakorea-com-font-awesome-5-free-solid-17-2-letter-spacing);
  line-height: var(--store-gpakorea-com-font-awesome-5-free-solid-17-2-line-height);
  margin-left: -0.41px;
  margin-right: -0.41px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit .overlap-group-11 {
  height: 23px;
  left: 45px;
  position: absolute;
  top: 14px;
  width: 185px;
}

.cache-deposit .text-wrapper-201 {
  color: #505050;
  font-family: var(--store-gpakorea-com-roboto-medium-14-3-font-family);
  font-size: var(--store-gpakorea-com-roboto-medium-14-3-font-size);
  font-style: var(--store-gpakorea-com-roboto-medium-14-3-font-style);
  font-weight: var(--store-gpakorea-com-roboto-medium-14-3-font-weight);
  height: 20px;
  left: 0;
  letter-spacing: var(--store-gpakorea-com-roboto-medium-14-3-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-medium-14-3-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 89px;
}

.cache-deposit .span-label-4 {
  align-items: flex-start;
  background-color: var(--storegpakoreacomfern);
  border-radius: 2.68px;
  display: inline-flex;
  left: 89px;
  padding: 3.64px 6.43px 4.71px;
  position: absolute;
  top: 4px;
}

.cache-deposit .text-wrapper-202 {
  color: #ffffff;
  font-family: var(--store-gpakorea-com-roboto-bold-10-7-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-10-7-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-10-7-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-10-7-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-bold-10-7-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-10-7-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit .symbol-21 {
  color: #d9d9d9;
  font-family: var(--store-gpakorea-com-font-awesome-5-free-regular-17-2-font-family);
  font-size: var(--store-gpakorea-com-font-awesome-5-free-regular-17-2-font-size);
  font-style: var(--store-gpakorea-com-font-awesome-5-free-regular-17-2-font-style);
  font-weight: var(--store-gpakorea-com-font-awesome-5-free-regular-17-2-font-weight);
  letter-spacing: var(--store-gpakorea-com-font-awesome-5-free-regular-17-2-letter-spacing);
  line-height: var(--store-gpakorea-com-font-awesome-5-free-regular-17-2-line-height);
  margin-left: -0.41px;
  margin-right: -0.41px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit .overlap-group-12 {
  height: 23px;
  left: 45px;
  position: absolute;
  top: 14px;
  width: 191px;
}

.cache-deposit .overlap-group-13 {
  height: 23px;
  left: 45px;
  position: absolute;
  top: 14px;
  width: 204px;
}

.cache-deposit .text-wrapper-203 {
  color: #505050;
  font-family: var(--store-gpakorea-com-roboto-medium-14-3-font-family);
  font-size: var(--store-gpakorea-com-roboto-medium-14-3-font-size);
  font-style: var(--store-gpakorea-com-roboto-medium-14-3-font-style);
  font-weight: var(--store-gpakorea-com-roboto-medium-14-3-font-weight);
  height: 20px;
  left: 0;
  letter-spacing: var(--store-gpakorea-com-roboto-medium-14-3-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-medium-14-3-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 102px;
}

.cache-deposit .span-label-5 {
  align-items: flex-start;
  background-color: var(--storegpakoreacomfern);
  border-radius: 2.68px;
  display: inline-flex;
  left: 102px;
  padding: 3.64px 6.43px 4.71px;
  position: absolute;
  top: 4px;
}

.cache-deposit .overlap-54 {
  height: 23px;
  left: 45px;
  position: absolute;
  top: 14px;
  width: 219px;
}

.cache-deposit .text-wrapper-204 {
  color: #505050;
  font-family: var(--store-gpakorea-com-roboto-medium-14-3-font-family);
  font-size: var(--store-gpakorea-com-roboto-medium-14-3-font-size);
  font-style: var(--store-gpakorea-com-roboto-medium-14-3-font-style);
  font-weight: var(--store-gpakorea-com-roboto-medium-14-3-font-weight);
  height: 20px;
  left: 0;
  letter-spacing: var(--store-gpakorea-com-roboto-medium-14-3-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-medium-14-3-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 111px;
}

.cache-deposit .span-label-6 {
  align-items: flex-start;
  background-color: var(--storegpakoreacomfern);
  border-radius: 2.68px;
  display: inline-flex;
  left: 111px;
  padding: 3.64px 6.43px 4.71px;
  position: absolute;
  top: 4px;
}

.cache-deposit .div-inner-pay-table-2 {
  align-items: flex-start;
  background-color: var(--storegpakoreacomcyan-aqua);
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  display: flex;
  flex-direction: column;
  left: 558px;
  padding: 16.25px;
  position: absolute;
  top: 0;
  width: 358px;
}

.cache-deposit .table-12 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  max-width: 357.5px;
  position: relative;
  width: 100%;
}

.cache-deposit .body-7 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.cache-deposit .row-7 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 52.94px;
  padding: 0px 0px 0px 7.98px;
  position: relative;
}

.cache-deposit .cell-36 {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-medium-14-3-font-family);
  font-size: var(--store-gpakorea-com-roboto-medium-14-3-font-size);
  font-style: var(--store-gpakorea-com-roboto-medium-14-3-font-style);
  font-weight: var(--store-gpakorea-com-roboto-medium-14-3-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-medium-14-3-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-medium-14-3-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit .data-29 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 8px 7.99px 8px 112.46px;
  position: relative;
}

.cache-deposit .text-wrapper-205 {
  color: var(--storegpakoreacomgray);
  font-family: var(--store-gpakorea-com-roboto-regular-14-3-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-14-3-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-14-3-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-14-3-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-regular-14-3-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-14-3-line-height);
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit .row-8 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 35.05px;
  padding: 0px 0px 0px 7.98px;
  position: relative;
}

.cache-deposit .cell-37 {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-regular-14-3-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-14-3-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-14-3-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-14-3-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-regular-14-3-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-14-3-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit .data-30 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 8px 7.99px 8px 130.03px;
  position: relative;
}

.cache-deposit .data-31 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 8px 7.99px 8px 111.54px;
  position: relative;
}

.cache-deposit .text-wrapper-206 {
  color: var(--storegpakoreacomblack);
  font-family: var(--store-gpakorea-com-roboto-bold-14-3-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-14-3-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-14-3-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-14-3-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-bold-14-3-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-14-3-line-height);
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit .row-9 {
  height: 46.39px;
  position: relative;
  width: 325px;
}

.cache-deposit .cell-38 {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-medium-14-3-font-family);
  font-size: var(--store-gpakorea-com-roboto-medium-14-3-font-size);
  font-style: var(--store-gpakorea-com-roboto-medium-14-3-font-style);
  font-weight: var(--store-gpakorea-com-roboto-medium-14-3-font-weight);
  height: 20px;
  left: 8px;
  letter-spacing: var(--store-gpakorea-com-roboto-medium-14-3-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-medium-14-3-line-height);
  position: absolute;
  top: 7px;
  white-space: nowrap;
  width: 41px;
}

.cache-deposit .data-32 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 120px;
  padding: 7.99px 8px 17.99px;
  position: absolute;
  top: 0;
}

.cache-deposit .div-wrapper-7 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.cache-deposit .footer-row-3 {
  height: 140.25px;
  position: relative;
  width: 325px;
}

.cache-deposit .cell-39 {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-medium-14-3-font-family);
  font-size: var(--store-gpakorea-com-roboto-medium-14-3-font-size);
  font-style: var(--store-gpakorea-com-roboto-medium-14-3-font-style);
  font-weight: var(--store-gpakorea-com-roboto-medium-14-3-font-weight);
  height: 20px;
  left: 8px;
  letter-spacing: var(--store-gpakorea-com-roboto-medium-14-3-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-medium-14-3-line-height);
  position: absolute;
  top: 39px;
  white-space: nowrap;
  width: 73px;
}

.cache-deposit .data-33 {
  align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
  gap: 0.68px;
  left: 120px;
  padding: 79.28px 8px 7.99px;
  position: absolute;
  top: 0;
}

.cache-deposit .text-wrapper-207 {
  color: #ff0000;
  font-family: var(--store-gpakorea-com-roboto-medium-22-9-font-family);
  font-size: var(--store-gpakorea-com-roboto-medium-22-9-font-size);
  font-style: var(--store-gpakorea-com-roboto-medium-22-9-font-style);
  font-weight: var(--store-gpakorea-com-roboto-medium-22-9-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-medium-22-9-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-medium-22-9-line-height);
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit .text-wrapper-208 {
  color: var(--storegpakoreacomgray);
  font-family: "Roboto", Helvetica;
  font-size: 13.7px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19.6px;
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit .div-inner-pay-method-2 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  height: 91px;
  left: 15px;
  position: absolute;
  top: 455px;
  width: 900px;
}

.cache-deposit .heading-19 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 16px;
  position: absolute;
  top: 16px;
  width: 868px;
}

.cache-deposit .text-wrapper-209 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: var(--storegpakoreacomscorpion);
  display: -webkit-box;
  font-family: "Roboto", Helvetica;
  font-size: 18.2px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit .label-6 {
  align-items: flex-start;
  display: inline-flex;
  left: 16px;
  max-width: 900px;
  padding: 0px 0px 0.54px 20px;
  position: absolute;
  top: 56px;
}

.cache-deposit .span-far-5 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 17px;
  left: 0;
  padding: 0px 1.95px 0.94px 1.93px;
  position: absolute;
  top: 1px;
  width: 19px;
}

.cache-deposit .symbol-22 {
  color: #d9d9d9;
  font-family: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-font-family);
  font-size: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-font-size);
  font-style: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-font-style);
  font-weight: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-font-weight);
  letter-spacing: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-line-height);
  margin-left: -0.2px;
  margin-right: -0.2px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit .text-wrapper-210 {
  color: #505050;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit .label-7 {
  align-items: flex-start;
  display: inline-flex;
  left: 306px;
  max-width: 900px;
  padding: 0px 0px 0.54px 20px;
  position: absolute;
  top: 56px;
}

.cache-deposit .span-fas-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 17px;
  left: 0;
  padding: 0px 1.94px 0.94px;
  position: absolute;
  top: 1px;
  width: 19px;
}

.cache-deposit .symbol-23 {
  color: #232323;
  font-family: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-font-family);
  font-size: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-font-size);
  font-style: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-font-style);
  font-weight: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-font-weight);
  letter-spacing: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-line-height);
  margin-left: -0.2px;
  margin-right: -0.2px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit .div-inner-taxinvoice {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 15px;
  padding: 16.25px;
  position: absolute;
  top: 606px;
  width: 900px;
}

.cache-deposit .heading-20 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.81px;
  position: relative;
  width: 100%;
}

.cache-deposit .text-wrapper-211 {
  color: var(--storegpakoreacomscorpion);
  font-family: "Roboto", Helvetica;
  font-size: 18.2px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit .small-8 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 0px 1.2px;
  position: relative;
  width: 100%;
}

.cache-deposit .text-wrapper-212 {
  align-self: stretch;
  color: var(--storegpakoreacomboulder);
  font-family: var(--store-gpakorea-com-roboto-regular-13-7-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-13-7-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-13-7-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-13-7-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-regular-13-7-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-13-7-line-height);
  margin-top: -1px;
  position: relative;
}

.cache-deposit .table-13 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  max-width: 900px;
  padding: 0.62px 0px 0.63px;
  position: relative;
  width: 100%;
}

.cache-deposit .body-8 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  margin-right: -0.02px;
  position: relative;
}

.cache-deposit .row-10 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.14e-13px;
  position: relative;
}

.cache-deposit .cell-40 {
  align-items: flex-start;
  background-color: #f7f7f7;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24.03px 8px 25.12px;
  position: relative;
  width: 200px;
}

.cache-deposit .text-wrapper-213 {
  color: #212121;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1.25px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit .data-34 {
  align-items: flex-start;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 16.25px 473.04px 15px 7.98px;
  position: relative;
}

.cache-deposit .div-input-group-11 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 1.25px;
  position: relative;
}

.cache-deposit .input-11 {
  align-self: stretch;
  background-color: #ffffff;
  height: 33.98px;
  position: relative;
  width: 100%;
}

.cache-deposit .data-35 {
  align-items: flex-start;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 16.25px 8px 15px;
  position: relative;
}

.cache-deposit .div-input-group-12 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 1.25px;
  position: relative;
  width: 100%;
}

.cache-deposit .cell-41 {
  align-items: flex-start;
  background-color: #f7f7f7;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 47.53px 8px 48.63px;
  position: relative;
  width: 200px;
}

.cache-deposit .data-36 {
  align-items: flex-start;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 16.25px 8px 15px;
  position: relative;
}

.cache-deposit .div-input-group-13 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 1.25px;
  position: relative;
}

.cache-deposit .input-12 {
  align-items: flex-start;
  background-color: var(--storegpakoreacomgallery);
  border-radius: 4px 0px 0px 4px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 33.98px;
  padding: 7.62px 12px 7.61px;
  position: relative;
  z-index: 1;
}

.cache-deposit .text-wrapper-214 {
  color: var(--storegpakoreacomdusty-gray);
  font-family: var(--store-gpakorea-com-roboto-regular-14-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-14-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-14-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-14-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-regular-14-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-14-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit .span-input-group-btn-5 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  margin-left: -0.99px;
  position: relative;
  z-index: 0;
}

.cache-deposit .link-49 {
  align-items: flex-start;
  border-radius: 0px 4px 4px 0px;
  display: inline-flex;
  flex: 0 0 auto;
  padding: 9.62px 13.25px 10.86px;
  position: relative;
}

.cache-deposit .symbol-24 {
  color: #333333;
  font-family: "Font Awesome 5 Free-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit .text-wrapper-215 {
  color: #333333;
  font-family: "Font Awesome 5 Free-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14px;
}

.cache-deposit .text-wrapper-216 {
  font-family: "Malgun Gothic-Bold", Helvetica;
  font-weight: 700;
}

.cache-deposit .input-13 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 33.98px;
  padding: 7.62px 12px 7.61px;
  position: relative;
  width: 100%;
}

.cache-deposit .text-wrapper-217 {
  align-self: stretch;
  color: var(--storegpakoreacomdusty-gray);
  font-family: var(--store-gpakorea-com-roboto-regular-14-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-14-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-14-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-14-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-regular-14-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-14-line-height);
  margin-top: -1px;
  position: relative;
}

.cache-deposit .cell-42 {
  align-items: flex-start;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24.03px 8px 25.12px;
  position: relative;
  width: 200px;
}

.cache-deposit .data-37 {
  align-items: flex-start;
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 15.63px 8px 15.62px;
  position: relative;
}

.cache-deposit .div-text-right-4 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 15px;
  position: absolute;
  top: 1380px;
  width: 900px;
}

.cache-deposit .link-50 {
  align-items: center;
  background-color: var(--storegpakoreacomconifer);
  border: 1px solid;
  border-color: var(--storegpakoreacomconifer);
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  padding: 7.25px 13.25px;
  position: relative;
}

.cache-deposit .text-wrapper-218 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit .footer-panel-10 {
  align-items: center;
  background-color: #606060;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 1860px;
  width: 1440px;
}

.cache-deposit .footer-frame-11 {
  height: 160px;
  position: relative;
  width: 1160px;
}

.cache-deposit .company-infos-9 {
  height: 72px;
  left: 213px;
  position: absolute;
  top: 43px;
  width: 683px;
}

.cache-deposit .flexcontainer-10 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 72px;
  position: relative;
  width: 681px;
}

.cache-deposit .text-i-11 {
  align-self: stretch;
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
  position: relative;
}

.cache-deposit .text-wrapper-219 {
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
}

.cache-deposit .sns-link-11 {
  height: 40px;
  left: 949px;
  position: absolute;
  top: 60px;
  width: 200px;
}

.cache-deposit .list-item-link-47 {
  background-image: url(../../../static/img/list-item-link.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 46px;
}

.cache-deposit .text-wrapper-220 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 40px;
}

.cache-deposit .list-item-link-48 {
  background-image: url(../../../static/img/list-item-link-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 51px;
  position: absolute;
  top: 0;
  width: 46px;
}

.cache-deposit .list-item-link-49 {
  background-image: url(../../../static/img/list-item-link-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 103px;
  position: absolute;
  top: 0;
  width: 46px;
}

.cache-deposit .text-wrapper-221 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 53px;
}

.cache-deposit .list-item-link-50 {
  background-image: url(../../../static/img/list-item-link-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 154px;
  position: absolute;
  top: 0;
  width: 46px;
}

.cache-deposit .text-wrapper-222 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 66px;
}

.cache-deposit .logo-gothic-11 {
  height: 111px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 25px;
  width: 145px;
}

.cache-deposit .kakao-service-11 {
  left: 0 !important;
  position: absolute !important;
  top: 200px !important;
}

.cache-deposit .jimmed-products-11 {
  left: 1310px !important;
  position: absolute !important;
  top: 560px !important;
}

.cache-deposit .viewed-products-11 {
  left: 1310px !important;
  position: absolute !important;
  top: 200px !important;
}
