.nav-menu {
  align-items: center;
  background-color: #222222;
  display: flex;
  flex-direction: column;
  padding: 0px 79.15px 0px 79.16px;
  position: relative;
  top: 100px;
  width: 1440px;
}

.nav-menu .list {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.8px;
  justify-content: center;
  position: relative;
}

.nav-menu .item {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}

.nav-menu .link-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 50px;
  padding: 0px 15px;
  position: relative;
}

.nav-menu .text-wrapper-5 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 14.3px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 50px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}
