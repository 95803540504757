.dashboard-list {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.dashboard-list .div-2 {
  background-color: #ffffff;
  height: 1400px;
  position: relative;
  width: 1640px;
}

.dashboard-list .menu-section {
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 40px;
  width: 1640px;
}

.dashboard-list .main-logo-instance {
  top: unset !important;
  left: unset !important;
}

.dashboard-list .right-menu-instance {
  align-items: flex-start !important;
  display: flex !important;
  height: 50px !important;
  left: unset !important;
  top: unset !important;
  width: 234px !important;
}

.dashboard-list .middle-section {
  align-items: flex-start;
  display: flex;
  height: 1300px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 100px;
  width: 1640px;
}

.dashboard-list .left-menu-instance {
  top: unset !important;
  width: 240px !important;
}

.dashboard-list .body-area {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  height: 1300px;
  padding: 10px;
  position: relative;
}

.dashboard-list .body-title {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 40px;
  position: relative;
  width: 100%;
}

.dashboard-list .title-freme {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  gap: 10px;
  height: 34px;
  padding: 0px 10px;
  position: relative;
  width: 196px;
}

.dashboard-list .title {
  color: #000000;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 26px;
  letter-spacing: 0;
  line-height: normal;
  margin-right: -20.00px;
  position: relative;
  white-space: nowrap;
  width: 196px;
}

.dashboard-list .body-head {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 160px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.dashboard-list .headitem {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 160px;
  justify-content: center;
  position: relative;
  width: 300px;
}

.dashboard-list .item {
  align-items: center;
  background-color: #163e64;
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 0px 10px;
  position: relative;
  width: 300px;
}

.dashboard-list .item-2 {
  align-items: center;
  display: flex;
  gap: 10px;
  position: relative;
  width: 110px;
}

.dashboard-list .text-wrapper-7 {
  color: #ffffff;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
}

.dashboard-list .item-3 {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 159px;
}

.dashboard-list .text-wrapper-8 {
  color: #ffffff;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
}

.dashboard-list .item-4 {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  height: 60px;
  position: relative;
  width: 300px;
}

.dashboard-list .item-5 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
  height: 52px;
  justify-content: center;
  padding: 5px 10px;
  position: relative;
}

.dashboard-list .text-wrapper-9 {
  color: #000000;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  width: 90px;
}

.dashboard-list .element {
  color: #000000;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: 90px;
}

.dashboard-list .item-6 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 52px;
  justify-content: center;
  padding: 5px 10px;
  position: relative;
  width: 100px;
}

.dashboard-list .text-wrapper-10 {
  color: #000000;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -5.00px;
  margin-right: -5.00px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  width: 90px;
}

.dashboard-list .element-2 {
  color: #000000;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -5.00px;
  margin-right: -5.00px;
  position: relative;
  text-align: center;
  width: 90px;
}

.dashboard-list .body-summary {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  flex-direction: column;
  height: 420px;
  position: relative;
  width: 100%;
}

.dashboard-list .div-wrapper-2 {
  align-items: center;
  align-self: stretch;
  background-color: #163e64;
  display: flex;
  gap: 10px;
  height: 40px;
  padding: 0px 10px;
  position: relative;
  width: 100%;
}

.dashboard-list .text-wrapper-11 {
  color: #ffffff;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.dashboard-list .summary-filter {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  gap: 10px;
  height: 80px;
  justify-content: space-around;
  padding: 20px 10px;
  position: relative;
  width: 100%;
}

.dashboard-list .filter {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 10px;
  justify-content: center;
  margin-bottom: -4.00px;
  margin-top: -4.00px;
  padding: 10px 5px;
  position: relative;
  width: 450px;
}

.dashboard-list .filter-2 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  flex: 1;
  flex-grow: 1;
  height: 28px;
  padding: 4.5px 5px;
  position: relative;
}

.dashboard-list .text-wrapper-12 {
  color: #000000;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.dashboard-list .div-wrapper-3 {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  flex: 1;
  flex-grow: 1;
  height: 28px;
  padding: 4.5px 5px;
  position: relative;
}

.dashboard-list .summary-main {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 300px;
  justify-content: center;
  padding: 10px;
  position: relative;
  width: 100%;
}

.dashboard-list .chart-area {
  align-self: stretch;
  min-height: 280px;
  position: relative;
  width: 280px;
}

.dashboard-list .body-detail {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  flex-direction: column;
  height: 520px;
  position: relative;
  width: 100%;
}

.dashboard-list .detail-filter {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  height: 80px;
  justify-content: space-between;
  padding: 20px 10px;
  position: relative;
  width: 100%;
}

.dashboard-list .continent {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 10px;
  justify-content: space-between;
  margin-bottom: -4.00px;
  margin-top: -4.00px;
  padding: 10px 5px;
  position: relative;
  width: 140px;
}

.dashboard-list .div-wrapper-4 {
  align-items: center;
  display: flex;
  height: 28px;
  padding: 4.5px 5px;
  position: relative;
  width: 50px;
}

.dashboard-list .text-wrapper-13 {
  color: #000000;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
}

.dashboard-list .continent-2 {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  flex: 1;
  flex-grow: 1;
  height: 28px;
  justify-content: center;
  padding: 4.5px 5px;
  position: relative;
}

.dashboard-list .nation {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 10px;
  justify-content: space-between;
  margin-bottom: -4.00px;
  margin-top: -4.00px;
  padding: 10px 5px;
  position: relative;
  width: 210px;
}

.dashboard-list .platform {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 10px;
  justify-content: space-between;
  margin-bottom: -4.00px;
  margin-top: -4.00px;
  padding: 10px 5px;
  position: relative;
  width: 220px;
}

.dashboard-list .platform-2 {
  align-items: center;
  display: flex;
  height: 28px;
  padding: 4.5px 5px;
  position: relative;
  width: 60px;
}

.dashboard-list .text-wrapper-14 {
  color: #000000;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  width: 50px;
}

.dashboard-list .filter-3 {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 10px;
  justify-content: space-between;
  margin-bottom: -4.00px;
  margin-top: -4.00px;
  padding: 10px 5px;
  position: relative;
  width: 450px;
}

.dashboard-list .duration {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 10px;
  justify-content: space-between;
  margin-bottom: -4.00px;
  margin-top: -4.00px;
  padding: 10px 5px;
  position: relative;
  width: 300px;
}

.dashboard-list .text-wrapper-15 {
  color: #000000;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
}

.dashboard-list .duration-2 {
  align-items: center;
  display: flex;
  height: 28px;
  padding: 4.5px 5px;
  position: relative;
  width: 160px;
}

.dashboard-list .detail-main {
  align-self: stretch;
  height: 400px;
  position: relative;
  width: 100%;
}
