.monitoring-list {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.monitoring-list .div-3 {
  background-color: #ffffff;
  height: 1360px;
  overflow: hidden;
  position: relative;
  width: 1640px;
}

.monitoring-list .bulletin-body {
  align-items: flex-start;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 1260px;
  left: 240px;
  padding: 10px;
  position: absolute;
  top: 100px;
  width: 1400px;
}

.monitoring-list .body {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  padding: 5px 0px 95px;
  position: relative;
  width: 100%;
}

.monitoring-list .title-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.monitoring-list .title-2 {
  color: #000000;
  flex: 1;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 25px;
  letter-spacing: 0;
  line-height: Truepx;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
}

.monitoring-list .drop-downs-wrapper {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 20px 0px;
  position: relative;
  width: 100%;
}

.monitoring-list .drop-downs {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
}

.monitoring-list .frame {
  height: 28px;
  position: relative;
  width: 210px;
}

.monitoring-list .dropdown-4 {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  height: 28px;
  left: 110px;
  padding: 4.5px 10px;
  position: absolute;
  top: 0;
  width: 100px;
}

.monitoring-list .text-wrapper-9 {
  color: #000000;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: Truepx;
  margin-top: -1.00px;
  position: relative;
}

.monitoring-list .dropdown-5 {
  align-items: center;
  display: flex;
  height: 28px;
  left: 0;
  padding: 4.5px 10px;
  position: absolute;
  top: 0;
  width: 100px;
}

.monitoring-list .text-wrapper-10 {
  color: #000000;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: Truepx;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
}

.monitoring-list .dropdown-6 {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  height: 28px;
  left: 70px;
  padding: 4.5px 10px;
  position: absolute;
  top: 0;
  width: 140px;
}

.monitoring-list .dropdown-7 {
  align-items: center;
  display: flex;
  height: 28px;
  left: 0;
  padding: 4.5px 5px;
  position: absolute;
  top: 0;
  width: 60px;
}

.monitoring-list .frame-2 {
  height: 28px;
  position: relative;
  width: 230px;
}

.monitoring-list .dropdown-8 {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  height: 28px;
  left: 90px;
  padding: 4.5px 5px;
  position: absolute;
  top: 0;
  width: 140px;
}

.monitoring-list .dropdown-9 {
  align-items: center;
  display: flex;
  height: 28px;
  left: 0;
  padding: 4.5px 10px;
  position: absolute;
  top: 0;
  width: 80px;
}

.monitoring-list .dropdown-10 {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  height: 28px;
  left: 110px;
  padding: 4.5px 5px;
  position: absolute;
  top: 0;
  width: 120px;
}

.monitoring-list .dropdown-11 {
  align-items: center;
  display: flex;
  height: 28px;
  left: 0;
  padding: 4.5px 5px;
  position: absolute;
  top: 0;
  width: 100px;
}

.monitoring-list .frame-3 {
  height: 28px;
  position: relative;
  width: 270px;
}

.monitoring-list .element-wrapper {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  height: 28px;
  left: 70px;
  padding: 4.5px 10px;
  position: absolute;
  top: 0;
  width: 200px;
}

.monitoring-list .dropdown-12 {
  align-items: center;
  display: flex;
  height: 28px;
  left: 0;
  padding: 4.5px 10px;
  position: absolute;
  top: 0;
  width: 60px;
}

.monitoring-list .body-chart-2 {
  align-self: stretch;
  border: 1px solid;
  border-color: #bcbcbc;
  height: 600px;
  position: relative;
  width: 100%;
}

.monitoring-list .body-summary-2 {
  border: 1px solid;
  border-color: #bcbcbc;
  height: 60px;
  position: relative;
  width: 1380px;
}

.monitoring-list .main-logo-instance {
  left: 0 !important;
  position: absolute !important;
  top: 40px !important;
  width: 240px !important;
}

.monitoring-list .left-menu-instance {
  left: 0 !important;
  padding: 30px 15px !important;
  position: absolute !important;
  width: 240px !important;
}

.monitoring-list .right-menu-2 {
  left: 1404px !important;
  position: absolute !important;
}
