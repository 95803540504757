.my-info-update {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.my-info-update .div-2 {
  background-color: #ffffff;
  height: 1460px;
  position: relative;
  width: 1440px;
}

.my-info-update .right-menu-instance {
  left: 1034px !important;
  position: absolute !important;
  top: 0 !important;
}

.my-info-update .main-logo-instance {
  left: 131px !important;
  position: absolute !important;
  top: 50px !important;
}

.my-info-update .search-area-instance {
  left: 541px !important;
  position: absolute !important;
  top: 50px !important;
}

.my-info-update .nav-menu-instance {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.my-info-update .link-tree-instance {
  left: 131px !important;
  position: absolute !important;
  top: 150px !important;
}

.my-info-update .footer-panel {
  align-items: center;
  background-color: #606060;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 1300px;
  width: 1440px;
}

.my-info-update .footer-frame {
  height: 160px;
  position: relative;
  width: 1160px;
}

.my-info-update .company-infos {
  height: 72px;
  left: 213px;
  position: absolute;
  top: 43px;
  width: 683px;
}

.my-info-update .flexcontainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 72px;
  position: relative;
  width: 681px;
}

.my-info-update .text-i {
  align-self: stretch;
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
  position: relative;
}

.my-info-update .text-wrapper-30 {
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
}

.my-info-update .sns-link {
  height: 40px;
  left: 949px;
  position: absolute;
  top: 60px;
  width: 200px;
}

.my-info-update .list-item-link {
  background-image: url(../../../static/img/list-item-link.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-info-update .text-wrapper-31 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 40px;
}

.my-info-update .list-item-link-2 {
  background-image: url(../../../static/img/list-item-link-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 51px;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-info-update .list-item-link-3 {
  background-image: url(../../../static/img/list-item-link-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 103px;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-info-update .text-wrapper-32 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 53px;
}

.my-info-update .list-item-link-4 {
  background-image: url(../../../static/img/list-item-link-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 154px;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-info-update .text-wrapper-33 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 66px;
}

.my-info-update .logo-gothic {
  height: 111px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 25px;
  width: 145px;
}

.my-info-update .kakao-service-instance {
  left: 0 !important;
  position: absolute !important;
  top: 200px !important;
}

.my-info-update .my-page-menu-instance {
  left: 130px !important;
  position: absolute !important;
  top: 200px !important;
}

.my-info-update .my-page-avata-instance {
  left: 370px !important;
  position: absolute !important;
  top: 200px !important;
}

.my-info-update .form-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  left: 370px;
  min-height: 400px;
  position: absolute;
  top: 400px;
  width: 900px;
}

.my-info-update .form {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 39.5px;
  position: relative;
  width: 360px;
}

.my-info-update .div-profile-image-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 120px;
  position: relative;
  width: 100%;
}

.my-info-update .link-6 {
  height: 120px;
  position: relative;
  width: 120px;
}

.my-info-update .input-3 {
  align-items: center;
  display: flex;
  gap: 3.98px;
  left: -11128px;
  position: absolute;
  top: -9999px;
  width: 272px;
}

.my-info-update .input-4 {
  align-items: flex-start;
  background-color: #f0f0f0;
  border: 1px solid;
  border-color: #000000;
  display: inline-flex;
  flex: 0 0 auto;
  padding: 4.74px 7.25px 3.5px;
  position: relative;
}

.my-info-update .text-wrapper-34 {
  color: #000000;
  font-family: var(--store-gpakorea-com-arial-narrow-13-font-family);
  font-size: var(--store-gpakorea-com-arial-narrow-13-font-size);
  font-style: var(--store-gpakorea-com-arial-narrow-13-font-style);
  font-weight: var(--store-gpakorea-com-arial-narrow-13-font-weight);
  letter-spacing: var(--store-gpakorea-com-arial-narrow-13-letter-spacing);
  line-height: var(--store-gpakorea-com-arial-narrow-13-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.my-info-update .text-wrapper-35 {
  color: #212121;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.my-info-update .div-input-wrap {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9.01px;
  position: relative;
  width: 100%;
}

.my-info-update .label {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 6px;
  max-width: 360px;
  padding: 0px 0px 0.54px;
  position: relative;
  width: 100%;
}

.my-info-update .text-wrapper-36 {
  color: #212121;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.my-info-update .text-wrapper-37 {
  color: var(--storegpakoreacomblack);
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.my-info-update .div-input-group {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 1.25px;
  position: relative;
  width: 100%;
}

.my-info-update .input-5 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--storegpakoreacomgallery);
  border-radius: 4px 0px 0px 4px;
  display: flex;
  flex-direction: column;
  height: 40px;
  padding: 10.62px 12px 10.63px;
  position: relative;
  width: 100%;
}

.my-info-update .div-3 {
  align-self: stretch;
  background: none;
  border: none;
  color: #555555;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  overflow: scroll;
  padding: 0;
  position: relative;
  width: 100%;
}

.my-info-update .div-input-wrap-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9.47px;
  position: relative;
  width: 100%;
}

.my-info-update .label-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  max-width: 360px;
  padding: 0px 0px 0.54px;
  position: relative;
  width: 100%;
}

.my-info-update .text-wrapper-38 {
  align-self: stretch;
  color: #212121;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
}

.my-info-update .label-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  max-width: 360px;
  padding: 0px 0px 0.54px 20px;
  position: relative;
}

.my-info-update .span-far {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 17px;
  left: 0;
  padding: 0px 1.95px 0.94px 1.93px;
  position: absolute;
  top: 1px;
  width: 19px;
}

.my-info-update .symbol-10 {
  color: #d9d9d9;
  font-family: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-font-family);
  font-size: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-font-size);
  font-style: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-font-style);
  font-weight: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-font-weight);
  letter-spacing: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-line-height);
  margin-left: -0.2px;
  margin-right: -0.2px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.my-info-update .text-wrapper-39 {
  color: #505050;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.my-info-update .input-6 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 4px 0px 0px 4px;
  display: flex;
  flex-direction: column;
  height: 40px;
  padding: 10.62px 12px 10.63px;
  position: relative;
  width: 100%;
}

.my-info-update .div-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow: scroll;
  position: relative;
  width: 100%;
}

.my-info-update .text-wrapper-40 {
  align-self: stretch;
  color: #555555;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.my-info-update .div-input-wrap-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9px;
  position: relative;
  width: 100%;
}

.my-info-update .input-7 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 4px 0px 0px 4px;
  display: flex;
  flex-direction: column;
  height: 40px;
  padding: 10.63px 12px 10.62px;
  position: relative;
  width: 100%;
}

.my-info-update .div-btn-wrap {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 38.99px;
  position: relative;
  width: 100%;
}

.my-info-update .link-7 {
  align-items: center;
  align-self: stretch;
  background-color: var(--storegpakoreacomconifer);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 10px 140.67px 10px 140.66px;
  position: relative;
  width: 100%;
}

.my-info-update .text-wrapper-41 {
  align-self: stretch;
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.my-info-update .link-8 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 0px 0px 1.01px;
  position: relative;
}

.my-info-update .text-wrapper-42 {
  color: #888888;
  font-family: var(--store-gpakorea-com-roboto-regular-11-1-underline-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-11-1-underline-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-11-1-underline-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-11-1-underline-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-regular-11-1-underline-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-11-1-underline-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.my-info-update .jimmed-products-instance {
  left: 1310px !important;
  position: absolute !important;
  top: 560px !important;
}

.my-info-update .viewed-products-instance {
  left: 1310px !important;
  position: absolute !important;
  top: 200px !important;
}
