.PD-body-policy {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 60px 0px;
  position: relative;
  width: 704px;
}

.PD-body-policy .heading {
  color: #333333;
  font-family: "Roboto", Helvetica;
  font-size: 18.2px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.PD-body-policy .div-content-inner-2 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20.8px;
  position: relative;
  width: 694px;
}

.PD-body-policy .element-3 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20.8px;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}
