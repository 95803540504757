.inquiry-list {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.inquiry-list .div-2 {
  background-color: #ffffff;
  height: 1580px;
  position: relative;
  width: 1440px;
}

.inquiry-list .right-menu-instance {
  left: 1037px !important;
  position: absolute !important;
  top: 0 !important;
}

.inquiry-list .main-logo-instance {
  left: 130px !important;
  position: absolute !important;
  top: 50px !important;
}

.inquiry-list .search-area-instance {
  left: 540px !important;
  position: absolute !important;
  top: 50px !important;
}

.inquiry-list .nav-menu-instance {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.inquiry-list .link-tree-instance {
  left: 130px !important;
  position: absolute !important;
  top: 150px !important;
}

.inquiry-list .my-page-menu-instance {
  left: 130px !important;
  position: absolute !important;
  top: 200px !important;
}

.inquiry-list .my-page-avata-instance {
  left: 350px !important;
  position: absolute !important;
  top: 200px !important;
  width: 940px !important;
}

.inquiry-list .inquiry-list-body {
  height: 1000px;
  left: 350px;
  position: absolute;
  top: 420px;
  width: 940px;
}

.inquiry-list .ILB {
  height: 975px;
  position: relative;
}

.inquiry-list .inquiry-body-title {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 41px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 300px;
}

.inquiry-list .span-bar {
  background-color: #fc871e;
  height: 4px;
  position: relative;
  width: 63.69px;
}

.inquiry-list .heading-FAQ {
  color: #000000;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 37px;
  left: 0;
  letter-spacing: 0;
  line-height: 36px;
  position: absolute;
  top: 3px;
  white-space: nowrap;
  width: 300px;
}

.inquiry-list .inquiry-body-menu {
  height: 35px;
  left: 0;
  position: absolute;
  top: 56px;
  width: 540px;
  cursor: pointer;
}

.inquiry-list .list-item-link {
  background-color: #656978;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #cccccc;
  border-left-style: solid;
  border-left-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  height: 35px;
  left: 0;
  position: absolute;
  top: 0;
  width: 90px;
}

.inquiry-list .text-wrapper-25 {
  color: #ffffff;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: 14px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
  width: 60px;
}

.inquiry-list .list-item-link-2 {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #cccccc;
  border-left-style: solid;
  border-left-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  height: 35px;
  left: 90px;
  position: absolute;
  top: 0;
  width: 90px;
}

.inquiry-list .text-wrapper-26 {
  color: #666666;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: 14px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
  width: 60px;
}

.inquiry-list .list-item-link-3 {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #cccccc;
  border-left-style: solid;
  border-left-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  height: 35px;
  left: 180px;
  position: absolute;
  top: 0;
  width: 90px;
}

.inquiry-list .list-item-link-4 {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #cccccc;
  border-left-style: solid;
  border-left-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  height: 35px;
  left: 270px;
  position: absolute;
  top: 0;
  width: 90px;
}

.inquiry-list .text-wrapper-27 {
  color: #666666;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: 15px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
  width: 60px;
}

.inquiry-list .list-item-link-5 {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #cccccc;
  border-left-style: solid;
  border-left-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  height: 35px;
  left: 359px;
  position: absolute;
  top: 0;
  width: 90px;
}

.inquiry-list .list-item-link-6 {
  border: 1px solid;
  border-color: #cccccc;
  height: 35px;
  left: 449px;
  position: absolute;
  top: 0;
  width: 91px;
}

.inquiry-list .inquiry-body-table {
  height: 777px;
  left: 0;
  position: absolute;
  top: 101px;
  width: 940px;
}

.inquiry-list .form-table {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #adadad;
  height: 777px;
  position: relative;
}

.inquiry-list .body-row-cell {
  background-color: #656978;
  height: 37px;
  left: 0;
  position: absolute;
  top: 0;
  width: 80px;
}

.inquiry-list .text-wrapper-28 {
  color: #ffffff;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: 9px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
  width: 60px;
}

.inquiry-list .body-row-cell-2 {
  background-color: #656978;
  height: 37px;
  left: 80px;
  position: absolute;
  top: 0;
  width: 600px;
}

.inquiry-list .text-wrapper-29 {
  color: #ffffff;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: 270px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
  width: 60px;
}

.inquiry-list .body-row-cell-3 {
  background-color: #656978;
  height: 37px;
  left: 680px;
  position: absolute;
  top: 0;
  width: 160px;
}

.inquiry-list .text-wrapper-30 {
  color: #ffffff;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: 54px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
}

.inquiry-list .body-row-cell-4 {
  background-color: #656978;
  height: 37px;
  left: 840px;
  position: absolute;
  top: 0;
  width: 100px;
}

.inquiry-list .text-wrapper-31 {
  color: #ffffff;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: 20px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
  width: 60px;
}

.inquiry-list .body-row-data-1 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 0;
  position: absolute;
  top: 37px;
  width: 80px;
}

.inquiry-list .text-wrapper-32 {
  color: #666666;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 13px;
  font-weight: 800;
  height: 21px;
  left: 9px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
  width: 60px;
}

.inquiry-list .body-row-data-2 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 80px;
  position: absolute;
  top: 37px;
  width: 600px;
}

.inquiry-list .text-wrapper-33 {
  color: #666666;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 13px;
  font-weight: 700;
  height: 21px;
  left: 0;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: 6px;
  white-space: nowrap;
  width: 600px;
}

.inquiry-list .body-row-data-3 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 680px;
  position: absolute;
  top: 37px;
  width: 160px;
}

.inquiry-list .text-wrapper-34 {
  color: #666666;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 13px;
  font-weight: 700;
  height: 21px;
  left: 62px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
}

.inquiry-list .body-row-data-4 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 840px;
  position: absolute;
  top: 37px;
  width: 100px;
}

.inquiry-list .text-wrapper-35 {
  color: #666666;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 13px;
  font-weight: 700;
  height: 21px;
  left: 20px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
  width: 60px;
}

.inquiry-list .body-row-data-5 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 0;
  position: absolute;
  top: 74px;
  width: 80px;
}

.inquiry-list .body-row-data-6 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 80px;
  position: absolute;
  top: 74px;
  width: 600px;
}

.inquiry-list .body-row-data-7 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 680px;
  position: absolute;
  top: 74px;
  width: 160px;
}

.inquiry-list .body-row-data-8 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 840px;
  position: absolute;
  top: 74px;
  width: 100px;
}

.inquiry-list .body-row-data-9 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 0;
  position: absolute;
  top: 111px;
  width: 80px;
}

.inquiry-list .body-row-data-10 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 80px;
  position: absolute;
  top: 111px;
  width: 600px;
}

.inquiry-list .body-row-data-11 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 680px;
  position: absolute;
  top: 111px;
  width: 160px;
}

.inquiry-list .body-row-data-12 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 840px;
  position: absolute;
  top: 111px;
  width: 100px;
}

.inquiry-list .body-row-data-13 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 0;
  position: absolute;
  top: 148px;
  width: 80px;
}

.inquiry-list .body-row-data-14 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 80px;
  position: absolute;
  top: 148px;
  width: 600px;
}

.inquiry-list .body-row-data-15 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 680px;
  position: absolute;
  top: 148px;
  width: 160px;
}

.inquiry-list .body-row-data-16 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 840px;
  position: absolute;
  top: 148px;
  width: 100px;
}

.inquiry-list .body-row-data-17 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 0;
  position: absolute;
  top: 185px;
  width: 80px;
}

.inquiry-list .body-row-data-18 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 80px;
  position: absolute;
  top: 185px;
  width: 600px;
}

.inquiry-list .body-row-data-19 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 680px;
  position: absolute;
  top: 185px;
  width: 160px;
}

.inquiry-list .body-row-data-20 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 840px;
  position: absolute;
  top: 185px;
  width: 100px;
}

.inquiry-list .body-row-data-21 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 0;
  position: absolute;
  top: 222px;
  width: 80px;
}

.inquiry-list .body-row-data-22 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 80px;
  position: absolute;
  top: 222px;
  width: 600px;
}

.inquiry-list .body-row-data-23 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 680px;
  position: absolute;
  top: 222px;
  width: 160px;
}

.inquiry-list .body-row-data-24 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 840px;
  position: absolute;
  top: 222px;
  width: 100px;
}

.inquiry-list .body-row-data-25 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 0;
  position: absolute;
  top: 259px;
  width: 80px;
}

.inquiry-list .body-row-data-26 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 80px;
  position: absolute;
  top: 259px;
  width: 600px;
}

.inquiry-list .body-row-data-27 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 680px;
  position: absolute;
  top: 259px;
  width: 160px;
}

.inquiry-list .body-row-data-28 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 840px;
  position: absolute;
  top: 259px;
  width: 100px;
}

.inquiry-list .body-row-data-29 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 0;
  position: absolute;
  top: 296px;
  width: 80px;
}

.inquiry-list .body-row-data-30 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 80px;
  position: absolute;
  top: 296px;
  width: 600px;
}

.inquiry-list .body-row-data-31 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 680px;
  position: absolute;
  top: 296px;
  width: 160px;
}

.inquiry-list .body-row-data-32 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 840px;
  position: absolute;
  top: 296px;
  width: 100px;
}

.inquiry-list .body-row-data-33 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 0;
  position: absolute;
  top: 333px;
  width: 80px;
}

.inquiry-list .body-row-data-34 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 80px;
  position: absolute;
  top: 333px;
  width: 600px;
}

.inquiry-list .body-row-data-35 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 680px;
  position: absolute;
  top: 333px;
  width: 160px;
}

.inquiry-list .body-row-data-36 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 840px;
  position: absolute;
  top: 333px;
  width: 100px;
}

.inquiry-list .body-row-data-37 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 0;
  position: absolute;
  top: 370px;
  width: 80px;
}

.inquiry-list .body-row-data-38 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 80px;
  position: absolute;
  top: 370px;
  width: 600px;
}

.inquiry-list .body-row-data-39 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 680px;
  position: absolute;
  top: 370px;
  width: 160px;
}

.inquiry-list .body-row-data-40 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 840px;
  position: absolute;
  top: 370px;
  width: 100px;
}

.inquiry-list .body-row-data-41 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 0;
  position: absolute;
  top: 407px;
  width: 80px;
}

.inquiry-list .body-row-data-42 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 80px;
  position: absolute;
  top: 407px;
  width: 600px;
}

.inquiry-list .body-row-data-43 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 680px;
  position: absolute;
  top: 407px;
  width: 160px;
}

.inquiry-list .body-row-data-44 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 840px;
  position: absolute;
  top: 407px;
  width: 100px;
}

.inquiry-list .text-wrapper-39 {
  color: #666666;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 13px;
  font-weight: 700;
  height: 21px;
  left: 26px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
  width: 47px;
}

.inquiry-list .body-row-data-45 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 0;
  position: absolute;
  top: 444px;
  width: 80px;
}

.inquiry-list .body-row-data-46 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 80px;
  position: absolute;
  top: 444px;
  width: 600px;
}

.inquiry-list .body-row-data-47 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 680px;
  position: absolute;
  top: 444px;
  width: 160px;
}

.inquiry-list .body-row-data-48 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 840px;
  position: absolute;
  top: 444px;
  width: 100px;
}

.inquiry-list .body-row-data-49 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 0;
  position: absolute;
  top: 481px;
  width: 80px;
}

.inquiry-list .text-wrapper-40 {
  color: #666666;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 13px;
  font-weight: 700;
  height: 21px;
  left: 27px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
}

.inquiry-list .body-row-data-50 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 80px;
  position: absolute;
  top: 481px;
  width: 600px;
}

.inquiry-list .body-row-data-51 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 680px;
  position: absolute;
  top: 481px;
  width: 160px;
}

.inquiry-list .body-row-data-52 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 840px;
  position: absolute;
  top: 481px;
  width: 100px;
}

.inquiry-list .body-row-data-53 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 0;
  position: absolute;
  top: 518px;
  width: 80px;
}

.inquiry-list .body-row-data-54 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 80px;
  position: absolute;
  top: 518px;
  width: 600px;
}

.inquiry-list .body-row-data-55 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 680px;
  position: absolute;
  top: 518px;
  width: 160px;
}

.inquiry-list .body-row-data-56 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 840px;
  position: absolute;
  top: 518px;
  width: 100px;
}

.inquiry-list .body-row-data-57 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 0;
  position: absolute;
  top: 555px;
  width: 80px;
}

.inquiry-list .body-row-data-58 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 80px;
  position: absolute;
  top: 555px;
  width: 600px;
}

.inquiry-list .body-row-data-59 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 680px;
  position: absolute;
  top: 555px;
  width: 160px;
}

.inquiry-list .body-row-data-60 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 840px;
  position: absolute;
  top: 555px;
  width: 100px;
}

.inquiry-list .body-row-data-61 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 0;
  position: absolute;
  top: 592px;
  width: 80px;
}

.inquiry-list .body-row-data-62 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 80px;
  position: absolute;
  top: 592px;
  width: 600px;
}

.inquiry-list .body-row-data-63 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 680px;
  position: absolute;
  top: 592px;
  width: 160px;
}

.inquiry-list .body-row-data-64 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 840px;
  position: absolute;
  top: 592px;
  width: 100px;
}

.inquiry-list .body-row-data-65 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 0;
  position: absolute;
  top: 629px;
  width: 80px;
}

.inquiry-list .body-row-data-66 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 80px;
  position: absolute;
  top: 629px;
  width: 600px;
}

.inquiry-list .body-row-data-67 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 680px;
  position: absolute;
  top: 629px;
  width: 160px;
}

.inquiry-list .body-row-data-68 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 840px;
  position: absolute;
  top: 629px;
  width: 100px;
}

.inquiry-list .body-row-data-69 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 0;
  position: absolute;
  top: 666px;
  width: 80px;
}

.inquiry-list .body-row-data-70 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 80px;
  position: absolute;
  top: 666px;
  width: 600px;
}

.inquiry-list .body-row-data-71 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 680px;
  position: absolute;
  top: 666px;
  width: 160px;
}

.inquiry-list .body-row-data-72 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 840px;
  position: absolute;
  top: 666px;
  width: 100px;
}

.inquiry-list .body-row-data-73 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 0;
  position: absolute;
  top: 703px;
  width: 80px;
}

.inquiry-list .body-row-data-74 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 80px;
  position: absolute;
  top: 703px;
  width: 600px;
}

.inquiry-list .body-row-data-75 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 680px;
  position: absolute;
  top: 703px;
  width: 160px;
}

.inquiry-list .body-row-data-76 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 840px;
  position: absolute;
  top: 703px;
  width: 100px;
}

.inquiry-list .body-row-data-77 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 0;
  position: absolute;
  top: 740px;
  width: 80px;
}

.inquiry-list .body-row-data-78 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 80px;
  position: absolute;
  top: 740px;
  width: 600px;
}

.inquiry-list .body-row-data-79 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 680px;
  position: absolute;
  top: 740px;
  width: 160px;
}

.inquiry-list .body-row-data-80 {
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  height: 37px;
  left: 840px;
  position: absolute;
  top: 740px;
  width: 100px;
}

.inquiry-list .body-page-instance {
  left: 300px !important;
  position: absolute !important;
  top: 898px !important;
}

.inquiry-list .body-search-instance {
  left: 264px !important;
  position: absolute !important;
  top: 947px !important;
}

.inquiry-list .kakao-service-instance {
  left: 0 !important;
  position: absolute !important;
  top: 200px !important;
}

.inquiry-list .jimmed-products-instance {
  left: 1310px !important;
  position: absolute !important;
  top: 560px !important;
}

.inquiry-list .viewed-products-instance {
  left: 1310px !important;
  position: absolute !important;
  top: 200px !important;
}

.inquiry-list .footer-panel {
  align-items: center;
  background-color: #606060;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 1420px;
  width: 1440px;
}

.inquiry-list .footer-frame {
  height: 160px;
  position: relative;
  width: 1160px;
}

.inquiry-list .company-infos {
  height: 72px;
  left: 213px;
  position: absolute;
  top: 43px;
  width: 683px;
}

.inquiry-list .flexcontainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 72px;
  position: relative;
  width: 681px;
}

.inquiry-list .text-i {
  align-self: stretch;
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
  position: relative;
}

.inquiry-list .text-wrapper-41 {
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
}

.inquiry-list .sns-link {
  height: 40px;
  left: 949px;
  position: absolute;
  top: 60px;
  width: 200px;
}

.inquiry-list .list-item-link-7 {
  background-image: url(../../../static/img/list-item-link.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 46px;
}

.inquiry-list .text-wrapper-42 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 40px;
}

.inquiry-list .list-item-link-8 {
  background-image: url(../../../static/img/list-item-link-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 51px;
  position: absolute;
  top: 0;
  width: 46px;
}

.inquiry-list .list-item-link-9 {
  background-image: url(../../../static/img/list-item-link-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 103px;
  position: absolute;
  top: 0;
  width: 46px;
}

.inquiry-list .text-wrapper-43 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 53px;
}

.inquiry-list .list-item-link-10 {
  background-image: url(../../../static/img/list-item-link-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 154px;
  position: absolute;
  top: 0;
  width: 46px;
}

.inquiry-list .text-wrapper-44 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 66px;
}

.inquiry-list .logo-gothic {
  height: 111px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 25px;
  width: 145px;
}
