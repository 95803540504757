.refund-cancel {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.refund-cancel .div-3 {
  background-color: #ffffff;
  height: 1690px;
  position: relative;
  width: 1440px;
}

.refund-cancel .design-component-instance-node {
  left: 1034px !important;
  position: absolute !important;
  top: 0 !important;
}

.refund-cancel .main-logo-2 {
  left: 131px !important;
  position: absolute !important;
  top: 50px !important;
}

.refund-cancel .search-area-2 {
  left: 541px !important;
  position: absolute !important;
  top: 50px !important;
}

.refund-cancel .nav-menu-2 {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.refund-cancel .link-tree-2 {
  left: 131px !important;
  position: absolute !important;
  top: 150px !important;
}

.refund-cancel .customer-menu-2 {
  left: 130px !important;
  position: absolute !important;
  top: 200px !important;
}

.refund-cancel .RC-wrapper {
  height: 560px;
  left: 350px;
  position: absolute;
  top: 200px;
  width: 940px;
}

.refund-cancel .RC {
  align-items: flex-start;
  border: 1px solid;
  border-color: #00000080;
  display: flex;
  flex-direction: column;
  gap: 80px;
  height: 540px;
  left: 10px;
  padding: 10px;
  position: relative;
  top: 10px;
  width: 920px;
}

.refund-cancel .heading-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 355.06px;
  position: relative;
  width: 100%;
}

.refund-cancel .text-wrapper-23 {
  align-self: stretch;
  color: var(--storegpakoreacommine-shaft);
  font-family: "Roboto", Helvetica;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28.6px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.refund-cancel .div-view-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 18.5px;
  margin-bottom: -1px;
  position: relative;
  width: 100%;
}

.refund-cancel .element {
  color: var(--storegpakoreacommine-shaft);
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.refund-cancel .element-2 {
  color: var(--storegpakoreacommine-shaft);
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  position: relative;
  width: fit-content;
}

.refund-cancel .text-wrapper-24 {
  color: var(--storegpakoreacommine-shaft);
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.refund-cancel .footer-frame-wrapper {
  align-items: center;
  background-color: #606060;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 1530px;
  width: 1440px;
}

.refund-cancel .footer-frame-2 {
  height: 160px;
  position: relative;
  width: 1160px;
}

.refund-cancel .flexcontainer-wrapper {
  height: 72px;
  left: 213px;
  position: absolute;
  top: 43px;
  width: 683px;
}

.refund-cancel .flexcontainer-i {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 72px;
  position: relative;
  width: 681px;
}

.refund-cancel .text-i-2 {
  align-self: stretch;
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
  position: relative;
}

.refund-cancel .text-wrapper-25 {
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
}

.refund-cancel .sns-link-2 {
  height: 40px;
  left: 949px;
  position: absolute;
  top: 60px;
  width: 200px;
}

.refund-cancel .list-item-link-5 {
  background-image: url(../../../static/img/list-item-link.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 46px;
}

.refund-cancel .text-wrapper-26 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 40px;
}

.refund-cancel .list-item-link-6 {
  background-image: url(../../../static/img/list-item-link-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 51px;
  position: absolute;
  top: 0;
  width: 46px;
}

.refund-cancel .list-item-link-7 {
  background-image: url(../../../static/img/list-item-link-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 103px;
  position: absolute;
  top: 0;
  width: 46px;
}

.refund-cancel .text-wrapper-27 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 53px;
}

.refund-cancel .list-item-link-8 {
  background-image: url(../../../static/img/list-item-link-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 154px;
  position: absolute;
  top: 0;
  width: 46px;
}

.refund-cancel .text-wrapper-28 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 66px;
}

.refund-cancel .logo-gothic-2 {
  height: 111px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 25px;
  width: 145px;
}

.refund-cancel .kakao-service-2 {
  left: 0 !important;
  position: absolute !important;
  top: 200px !important;
}

.refund-cancel .jimmed-products-2 {
  left: 1310px !important;
  position: absolute !important;
  top: 560px !important;
}

.refund-cancel .viewed-products-2 {
  left: 1310px !important;
  position: absolute !important;
  top: 200px !important;
}
