.my-wish-list {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.my-wish-list .div-36 {
  background-color: #ffffff;
  height: 1460px;
  position: relative;
  width: 1440px;
}

.my-wish-list .right-menu-12 {
  left: 1034px !important;
  position: absolute !important;
  top: 0 !important;
}

.my-wish-list .main-logo-13 {
  left: 131px !important;
  position: absolute !important;
  top: 50px !important;
}

.my-wish-list .search-area-13 {
  left: 541px !important;
  position: absolute !important;
  top: 50px !important;
}

.my-wish-list .nav-menu-13 {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.my-wish-list .link-tree-13 {
  left: 131px !important;
  position: absolute !important;
  top: 150px !important;
}

.my-wish-list .my-page-menu-7 {
  left: 130px !important;
  position: absolute !important;
  top: 200px !important;
}

.my-wish-list .my-page-avata-7 {
  left: 370px !important;
  position: absolute !important;
  top: 200px !important;
}

.my-wish-list .main-body-2 {
  left: 370px !important;
  position: absolute !important;
  top: 400px !important;
}

.my-wish-list .footer-panel-12 {
  align-items: center;
  background-color: #606060;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 1300px;
  width: 1440px;
}

.my-wish-list .footer-frame-13 {
  height: 160px;
  position: relative;
  width: 1160px;
}

.my-wish-list .company-infos-11 {
  height: 72px;
  left: 213px;
  position: absolute;
  top: 43px;
  width: 683px;
}

.my-wish-list .flexcontainer-12 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 72px;
  position: relative;
  width: 681px;
}

.my-wish-list .text-i-13 {
  align-self: stretch;
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
  position: relative;
}

.my-wish-list .text-wrapper-184 {
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
}

.my-wish-list .sns-link-13 {
  height: 40px;
  left: 949px;
  position: absolute;
  top: 60px;
  width: 200px;
}

.my-wish-list .list-item-link-49 {
  background-image: url(../../../static/img/list-item-link.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-wish-list .text-wrapper-185 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 40px;
}

.my-wish-list .list-item-link-50 {
  background-image: url(../../../static/img/list-item-link-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 51px;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-wish-list .list-item-link-51 {
  background-image: url(../../../static/img/list-item-link-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 103px;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-wish-list .text-wrapper-186 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 53px;
}

.my-wish-list .list-item-link-52 {
  background-image: url(../../../static/img/list-item-link-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 154px;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-wish-list .text-wrapper-187 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 66px;
}

.my-wish-list .logo-gothic-13 {
  height: 111px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 25px;
  width: 145px;
}

.my-wish-list .kakao-service-12 {
  left: 0 !important;
  position: absolute !important;
  top: 200px !important;
}

.my-wish-list .jimmed-products-13 {
  left: 1310px !important;
  position: absolute !important;
  top: 560px !important;
}

.my-wish-list .viewed-products-13 {
  left: 1310px !important;
  position: absolute !important;
  top: 200px !important;
}
