.my-cache-list {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.my-cache-list .div-35 {
  background-color: #ffffff;
  height: 1460px;
  position: relative;
  width: 1440px;
}

.my-cache-list .right-menu-11 {
  left: 1034px !important;
  position: absolute !important;
  top: 0 !important;
}

.my-cache-list .main-logo-12 {
  left: 131px !important;
  position: absolute !important;
  top: 50px !important;
}

.my-cache-list .search-area-12 {
  left: 541px !important;
  position: absolute !important;
  top: 50px !important;
}

.my-cache-list .nav-menu-12 {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.my-cache-list .link-tree-12 {
  left: 131px !important;
  position: absolute !important;
  top: 150px !important;
}

.my-cache-list .my-page-menu-6 {
  left: 130px !important;
  position: absolute !important;
  top: 200px !important;
}

.my-cache-list .my-page-avata-6 {
  left: 370px !important;
  position: absolute !important;
  top: 200px !important;
}

.my-cache-list .cache-list {
  align-items: center;
  border: 1px solid;
  border-color: #0000001a;
  display: flex;
  gap: 10px;
  height: 200px;
  justify-content: center;
  left: 370px;
  padding: 10px;
  position: absolute;
  top: 400px;
  width: 900px;
}

.my-cache-list .text-wrapper-179 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22.3px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.my-cache-list .footer-panel-11 {
  align-items: center;
  background-color: #606060;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 1300px;
  width: 1440px;
}

.my-cache-list .footer-frame-12 {
  height: 160px;
  position: relative;
  width: 1160px;
}

.my-cache-list .company-infos-10 {
  height: 72px;
  left: 213px;
  position: absolute;
  top: 43px;
  width: 683px;
}

.my-cache-list .flexcontainer-11 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 72px;
  position: relative;
  width: 681px;
}

.my-cache-list .text-i-12 {
  align-self: stretch;
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
  position: relative;
}

.my-cache-list .text-wrapper-180 {
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
}

.my-cache-list .sns-link-12 {
  height: 40px;
  left: 949px;
  position: absolute;
  top: 60px;
  width: 200px;
}

.my-cache-list .list-item-link-45 {
  background-image: url(../../../static/img/list-item-link.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-cache-list .text-wrapper-181 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 40px;
}

.my-cache-list .list-item-link-46 {
  background-image: url(../../../static/img/list-item-link-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 51px;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-cache-list .list-item-link-47 {
  background-image: url(../../../static/img/list-item-link-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 103px;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-cache-list .text-wrapper-182 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 53px;
}

.my-cache-list .list-item-link-48 {
  background-image: url(../../../static/img/list-item-link-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 154px;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-cache-list .text-wrapper-183 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 66px;
}

.my-cache-list .logo-gothic-12 {
  height: 111px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 25px;
  width: 145px;
}

.my-cache-list .kakao-service-11 {
  left: 0 !important;
  position: absolute !important;
  top: 200px !important;
}

.my-cache-list .jimmed-products-12 {
  left: 1310px !important;
  position: absolute !important;
  top: 560px !important;
}

.my-cache-list .viewed-products-12 {
  left: 1310px !important;
  position: absolute !important;
  top: 200px !important;
}
