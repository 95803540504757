.inquiry-detail {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.inquiry-detail .div-2 {
  background-color: #ffffff;
  height: 1580px;
  position: relative;
  width: 1440px;
}

.inquiry-detail .right-menu-instance {
  left: 1037px !important;
  position: absolute !important;
  top: 0 !important;
}

.inquiry-detail .main-logo-instance {
  left: 130px !important;
  position: absolute !important;
  top: 50px !important;
}

.inquiry-detail .search-area-instance {
  left: 540px !important;
  position: absolute !important;
  top: 50px !important;
}

.inquiry-detail .nav-menu-instance {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.inquiry-detail .link-tree-instance {
  left: 130px !important;
  position: absolute !important;
  top: 150px !important;
}

.inquiry-detail .my-page-menu-instance {
  left: 130px !important;
  position: absolute !important;
  top: 200px !important;
}

.inquiry-detail .my-page-avata-instance {
  left: 350px !important;
  position: absolute !important;
  top: 200px !important;
  width: 940px !important;
}

.inquiry-detail .kakao-service-instance {
  left: 0 !important;
  position: absolute !important;
  top: 200px !important;
}

.inquiry-detail .jimmed-products-instance {
  left: 1310px !important;
  position: absolute !important;
  top: 560px !important;
}

.inquiry-detail .viewed-products-instance {
  left: 1310px !important;
  position: absolute !important;
  top: 200px !important;
}

.inquiry-detail .footer-panel {
  align-items: center;
  background-color: #606060;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 1420px;
  width: 1440px;
}

.inquiry-detail .footer-frame {
  height: 160px;
  position: relative;
  width: 1160px;
}

.inquiry-detail .company-infos {
  height: 72px;
  left: 213px;
  position: absolute;
  top: 43px;
  width: 683px;
}

.inquiry-detail .flexcontainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 72px;
  position: relative;
  width: 681px;
}

.inquiry-detail .text-i {
  align-self: stretch;
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
  position: relative;
}

.inquiry-detail .text-wrapper-22 {
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
}

.inquiry-detail .sns-link {
  height: 40px;
  left: 949px;
  position: absolute;
  top: 60px;
  width: 200px;
}

.inquiry-detail .list-item-link {
  background-image: url(../../../static/img/list-item-link.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 46px;
}

.inquiry-detail .text-wrapper-23 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 40px;
}

.inquiry-detail .list-item-link-2 {
  background-image: url(../../../static/img/list-item-link-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 51px;
  position: absolute;
  top: 0;
  width: 46px;
}

.inquiry-detail .list-item-link-3 {
  background-image: url(../../../static/img/list-item-link-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 103px;
  position: absolute;
  top: 0;
  width: 46px;
}

.inquiry-detail .text-wrapper-24 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 53px;
}

.inquiry-detail .list-item-link-4 {
  background-image: url(../../../static/img/list-item-link-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 154px;
  position: absolute;
  top: 0;
  width: 46px;
}

.inquiry-detail .text-wrapper-25 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 66px;
}

.inquiry-detail .logo-gothic {
  height: 111px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 25px;
  width: 145px;
}

.inquiry-detail .inquiry-detail-body {
  height: 960px;
  left: 350px;
  overflow: hidden;
  position: absolute;
  top: 423px;
  width: 940px;
}

.inquiry-detail .inquiry-detail-body-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 41px;
  left: 0;
  position: absolute;
  top: 0;
  width: 330px;
}

.inquiry-detail .span-bar {
  background-color: #fc871e;
  height: 4px;
  position: relative;
  width: 88.6px;
}

.inquiry-detail .heading {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 26px;
  font-weight: 700;
  height: 37px;
  left: 0;
  letter-spacing: 0;
  line-height: 36px;
  position: absolute;
  top: 3px;
  white-space: nowrap;
  width: 330px;
}

.inquiry-detail .inquiry-detail-body-3 {
  height: 79px;
  left: 0;
  position: absolute;
  top: 56px;
  width: 940px;
}

.inquiry-detail .div-3 {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 0;
  padding: 9px 20px;
  position: absolute;
  top: 0;
  width: 940px;
}

.inquiry-detail .p {
  color: #505050;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1px;
  position: relative;
}

.inquiry-detail .div-4 {
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: #656978;
  height: 39px;
  left: 0;
  position: absolute;
  top: 40px;
  width: 940px;
}

.inquiry-detail .table-body-row-data {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  height: 21px;
  left: 20px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: 6px;
  white-space: nowrap;
  width: 260px;
}

.inquiry-detail .table-body-row-data-2 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  height: 21px;
  left: 300px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
  width: 300px;
}

.inquiry-detail .table-body-row-data-3 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  height: 21px;
  left: 790px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: right;
  top: 6px;
  white-space: nowrap;
  width: 130px;
}

.inquiry-detail .inquiry-detail-body-4 {
  align-items: flex-start;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px 10px;
  left: 0;
  position: absolute;
  top: 135px;
}

.inquiry-detail .table-body-row-data-wrapper {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #656978;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 10px;
  height: 788px;
  justify-content: center;
  padding: 30px;
  position: relative;
  width: 940px;
}

.inquiry-detail .table-body-row-data-4 {
  align-self: stretch;
  color: #000000;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1px;
  position: relative;
}

.inquiry-detail .inquiry-detail-body-5 {
  height: 37px;
  left: 865px;
  position: absolute;
  top: 923px;
  width: 75px;
}

.inquiry-detail .link-5 {
  background-color: #41444f;
  height: 37px;
  position: relative;
}

.inquiry-detail .text-wrapper-26 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: 19px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: right;
  top: 6px;
  white-space: nowrap;
  width: 27px;
}
