.my-coupon-list {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.my-coupon-list .div-37 {
  background-color: #ffffff;
  height: 1460px;
  position: relative;
  width: 1440px;
}

.my-coupon-list .right-menu-13 {
  left: 1034px !important;
  position: absolute !important;
  top: 0 !important;
}

.my-coupon-list .main-logo-14 {
  left: 131px !important;
  position: absolute !important;
  top: 50px !important;
}

.my-coupon-list .search-area-14 {
  left: 541px !important;
  position: absolute !important;
  top: 50px !important;
}

.my-coupon-list .nav-menu-14 {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.my-coupon-list .link-tree-14 {
  left: 131px !important;
  position: absolute !important;
  top: 150px !important;
}

.my-coupon-list .my-page-menu-8 {
  left: 130px !important;
  position: absolute !important;
  top: 200px !important;
}

.my-coupon-list .my-page-avata-8 {
  left: 370px !important;
  position: absolute !important;
  top: 200px !important;
}

.my-coupon-list .div-38 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  left: 370px;
  min-height: 400px;
  position: absolute;
  top: 400px;
  width: 900px;
}

.my-coupon-list .div-input-group-wrapper {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--storegpakoreacomalto);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 20px;
  position: relative;
  width: 100%;
}

.my-coupon-list .div-input-group-3 {
  align-items: flex-start;
  background-color: #ffffff;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}

.my-coupon-list .input-17 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 4px 0px 0px 4px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 43.98px;
  padding: 12.62px 12px 12.61px;
  position: relative;
  z-index: 1;
}

.my-coupon-list .div-placeholder-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.my-coupon-list .text-wrapper-188 {
  color: var(--storegpakoreacomdusty-gray);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.my-coupon-list .span-input-group-btn-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  margin-left: -1.14e-13px;
  padding: 6px 5.01px 5.99px 4px;
  position: relative;
  z-index: 0;
}

.my-coupon-list .link-22 {
  align-items: center;
  background-color: var(--storegpakoreacomemperor);
  display: inline-flex;
  flex: 0 0 auto;
  padding: 6px 12px;
  position: relative;
}

.my-coupon-list .text-wrapper-189 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.my-coupon-list .body-wrapper {
  align-items: flex-start;
  align-self: stretch;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  max-width: 1140px;
  padding: 1.25px 0px 0px;
  position: relative;
  width: 100%;
}

.my-coupon-list .body {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.my-coupon-list .row {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.my-coupon-list .div-ticket-wrapper {
  align-items: flex-start;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(238, 238, 238), rgb(221, 221, 221) 0%) 1;
  border-top-style: solid;
  border-top-width: 1.25px;
  display: flex;
  flex-direction: column;
  padding: 15.63px 8px 15.62px;
  position: relative;
  width: 190px;
}

.my-coupon-list .div-ticket {
  align-items: center;
  align-self: stretch;
  background-color: var(--storegpakoreacomtacao);
  display: flex;
  flex-direction: column;
  height: 60px;
  opacity: 0.8;
  padding: 0px 48.84px 0px 48.83px;
  position: relative;
  width: 100%;
}

.my-coupon-list .text-wrapper-190 {
  color: #212121;
  font-family: "Roboto", Helvetica;
  font-size: 18.7px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 60px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.my-coupon-list .before-2 {
  height: 60px;
  left: 0;
  position: absolute;
  top: 0;
  width: 10px;
}

.my-coupon-list .after-9 {
  height: 60px;
  left: 164px;
  position: absolute;
  top: 0;
  width: 10px;
}

.my-coupon-list .data-3 {
  align-items: flex-start;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(238, 238, 238), rgb(221, 221, 221) 0%) 1;
  border-top-style: solid;
  border-top-width: 1.25px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7.16px;
  padding: 19.22px 243.98px 21.42px 7.98px;
  position: relative;
}

.my-coupon-list .strong-5 {
  color: var(--storegpakoreacomtundora);
  font-family: var(--store-gpakorea-com-roboto-medium-17-2-font-family);
  font-size: var(--store-gpakorea-com-roboto-medium-17-2-font-size);
  font-style: var(--store-gpakorea-com-roboto-medium-17-2-font-style);
  font-weight: var(--store-gpakorea-com-roboto-medium-17-2-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-medium-17-2-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-medium-17-2-line-height);
  margin-top: -1.25px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.my-coupon-list .small-5 {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-regular-13-3-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-13-3-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-13-3-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-13-3-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-regular-13-3-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-13-3-line-height);
  opacity: 0.6;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.my-coupon-list .data-4 {
  align-items: flex-end;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(238, 238, 238), rgb(221, 221, 221) 0%) 1;
  border-top-style: solid;
  border-top-width: 1.25px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7.5px;
  padding: 20.2px 8px 23.4px;
  position: relative;
}

.my-coupon-list .strong-6 {
  align-items: flex-end;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 0px 1.14px;
  position: relative;
  width: 305.16px;
}

.my-coupon-list .text-wrapper-191 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 15.6px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22.3px;
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.my-coupon-list .small-6 {
  color: #666666;
  font-family: var(--store-gpakorea-com-roboto-regular-11-7-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-11-7-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-11-7-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-11-7-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-regular-11-7-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-11-7-line-height);
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.my-coupon-list .footer-panel-13 {
  align-items: center;
  background-color: #606060;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 1300px;
  width: 1440px;
}

.my-coupon-list .footer-frame-14 {
  height: 160px;
  position: relative;
  width: 1160px;
}

.my-coupon-list .company-infos-12 {
  height: 72px;
  left: 213px;
  position: absolute;
  top: 43px;
  width: 683px;
}

.my-coupon-list .flexcontainer-13 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 72px;
  position: relative;
  width: 681px;
}

.my-coupon-list .text-i-14 {
  align-self: stretch;
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
  position: relative;
}

.my-coupon-list .text-wrapper-192 {
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
}

.my-coupon-list .sns-link-14 {
  height: 40px;
  left: 949px;
  position: absolute;
  top: 60px;
  width: 200px;
}

.my-coupon-list .list-item-link-53 {
  background-image: url(../../../static/img/list-item-link.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-coupon-list .text-wrapper-193 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 40px;
}

.my-coupon-list .list-item-link-54 {
  background-image: url(../../../static/img/list-item-link-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 51px;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-coupon-list .list-item-link-55 {
  background-image: url(../../../static/img/list-item-link-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 103px;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-coupon-list .text-wrapper-194 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 53px;
}

.my-coupon-list .list-item-link-56 {
  background-image: url(../../../static/img/list-item-link-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 154px;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-coupon-list .text-wrapper-195 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 66px;
}

.my-coupon-list .logo-gothic-14 {
  height: 111px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 25px;
  width: 145px;
}

.my-coupon-list .kakao-service-13 {
  left: 0 !important;
  position: absolute !important;
  top: 200px !important;
}

.my-coupon-list .jimmed-products-14 {
  left: 1310px !important;
  position: absolute !important;
  top: 560px !important;
}

.my-coupon-list .viewed-products-14 {
  left: 1310px !important;
  position: absolute !important;
  top: 200px !important;
}
