.terms-of-use {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.terms-of-use .div-2 {
  background-color: #ffffff;
  height: 4060px;
  position: relative;
  width: 1440px;
}

.terms-of-use .right-menu-instance {
  left: 1034px !important;
  position: absolute !important;
  top: 0 !important;
}

.terms-of-use .main-logo-instance {
  left: 131px !important;
  position: absolute !important;
  top: 50px !important;
}

.terms-of-use .search-area-instance {
  left: 541px !important;
  position: absolute !important;
  top: 50px !important;
}

.terms-of-use .nav-menu-instance {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.terms-of-use .link-tree-instance {
  left: 131px !important;
  position: absolute !important;
  top: 150px !important;
}

.terms-of-use .customer-menu-instance {
  left: 130px !important;
  position: absolute !important;
  top: 200px !important;
}

.terms-of-use .terms-body {
  height: 3671px;
  left: 350px;
  position: absolute;
  top: 200px;
  width: 920px;
}

.terms-of-use .TB {
  align-items: flex-start;
  border: 1px solid;
  border-color: #00000080;
  display: flex;
  flex-direction: column;
  gap: 79.47px;
  left: 10px;
  min-height: 400px;
  padding: 10px;
  position: relative;
  top: 10px;
  width: 900px;
}

.terms-of-use .heading {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 400.09px 1.16px 400.07px;
  position: relative;
  width: 100%;
}

.terms-of-use .text-wrapper-18 {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-medium-26-font-family);
  font-size: var(--store-gpakorea-com-roboto-medium-26-font-size);
  font-style: var(--store-gpakorea-com-roboto-medium-26-font-style);
  font-weight: var(--store-gpakorea-com-roboto-medium-26-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-medium-26-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-medium-26-line-height);
  margin-left: -23.08px;
  margin-right: -23.08px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 126px;
}

.terms-of-use .div-view {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 18.6px;
  padding: 0px 0px 0.55px;
  position: relative;
  width: 100%;
}

.terms-of-use .element-URL-https-store {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-regular-13-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-13-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-13-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-13-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-regular-13-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-13-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.terms-of-use .p {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-regular-13-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-13-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-13-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-13-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-regular-13-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-13-line-height);
  position: relative;
  width: fit-content;
}

.terms-of-use .footer-panel {
  align-items: center;
  background-color: #606060;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 3900px;
  width: 1440px;
}

.terms-of-use .footer-frame {
  height: 160px;
  position: relative;
  width: 1160px;
}

.terms-of-use .company-infos {
  height: 72px;
  left: 213px;
  position: absolute;
  top: 43px;
  width: 683px;
}

.terms-of-use .flexcontainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 72px;
  position: relative;
  width: 681px;
}

.terms-of-use .text-i {
  align-self: stretch;
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
  position: relative;
}

.terms-of-use .text-wrapper-19 {
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
}

.terms-of-use .sns-link {
  height: 40px;
  left: 949px;
  position: absolute;
  top: 60px;
  width: 200px;
}

.terms-of-use .list-item-link {
  background-image: url(../../../static/img/list-item-link.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 46px;
}

.terms-of-use .text-wrapper-20 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 40px;
}

.terms-of-use .list-item-link-2 {
  background-image: url(../../../static/img/list-item-link-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 51px;
  position: absolute;
  top: 0;
  width: 46px;
}

.terms-of-use .list-item-link-3 {
  background-image: url(../../../static/img/list-item-link-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 103px;
  position: absolute;
  top: 0;
  width: 46px;
}

.terms-of-use .text-wrapper-21 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 53px;
}

.terms-of-use .list-item-link-4 {
  background-image: url(../../../static/img/list-item-link-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 154px;
  position: absolute;
  top: 0;
  width: 46px;
}

.terms-of-use .text-wrapper-22 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 66px;
}

.terms-of-use .logo-gothic {
  height: 111px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 25px;
  width: 145px;
}

.terms-of-use .kakao-service-instance {
  left: 0 !important;
  position: absolute !important;
  top: 200px !important;
}

.terms-of-use .jimmed-products-instance {
  left: 1310px !important;
  position: absolute !important;
  top: 560px !important;
}

.terms-of-use .viewed-products-instance {
  left: 1310px !important;
  position: absolute !important;
  top: 200px !important;
}
