.malseller-detail {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.malseller-detail .div-2 {
  background-color: #ffffff;
  height: 1600px;
  position: relative;
  width: 1640px;
}

.malseller-detail .menu-section {
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 40px;
  width: 1640px;
}

.malseller-detail .main-logo-instance {
  top: unset !important;
  left: unset !important;
}

.malseller-detail .right-menu-instance {
  align-items: flex-start !important;
  display: flex !important;
  height: 50px !important;
  left: unset !important;
  top: unset !important;
  width: 234px !important;
}

.malseller-detail .middle-section {
  align-items: flex-start;
  display: flex;
  height: 1500px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 100px;
  width: 1640px;
}

.malseller-detail .left-menu-instance {
  top: unset !important;
  width: 240px !important;
}

.malseller-detail .body-area {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  height: 1500px;
  padding: 10px;
  position: relative;
}

.malseller-detail .seller-info {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.malseller-detail .seller-info-2 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 600px;
}

.malseller-detail .title-wrapper {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  gap: 10px;
  height: 50px;
  justify-content: center;
  padding: 0px 20px;
  position: relative;
  width: 100%;
}

.malseller-detail .title {
  color: #000000;
  flex: 1;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.malseller-detail .info-body {
  align-items: flex-start;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #bcbcbc;
  border-left-style: solid;
  border-left-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  padding: 20px 40px 20px 20px;
  position: relative;
  width: 100%;
}

.malseller-detail .div-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-wrap: wrap;
  gap: 0px 0px;
  height: 34px;
  position: relative;
  width: 100%;
}

.malseller-detail .seller-name-text {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  height: 34px;
  padding: 7.5px 10px;
  position: relative;
  width: 160px;
}

.malseller-detail .text-wrapper-8 {
  color: #000000;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.malseller-detail .sellername-edit {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #bcbcbc;
  border-right-style: solid;
  border-right-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  height: 34px;
  padding: 7.5px 10px;
  position: relative;
}

.malseller-detail .text-wrapper-9 {
  color: #000000;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
}

.malseller-detail .seller-type-text {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #bcbcbc;
  border-left-style: solid;
  border-left-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
  display: flex;
  gap: 10px;
  height: 34px;
  padding: 7.5px 10px;
  position: relative;
  width: 160px;
}

.malseller-detail .seller-type-edit {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #bcbcbc;
  border-right-style: solid;
  border-right-width: 1px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  height: 34px;
  padding: 7.5px 10px;
  position: relative;
}

.malseller-detail .body-seperator {
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-color: #bcbcbc;
  height: 5px;
  position: relative;
  width: 100%;
}

.malseller-detail .bulletin-body-table {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 950px;
  position: relative;
  width: 100%;
}

.malseller-detail .form-table {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #adadad;
  display: flex;
  flex-direction: column;
  height: 950px;
  position: relative;
  width: 100%;
}

.malseller-detail .head-frame {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  height: 50px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.malseller-detail .body-row-cell {
  align-items: center;
  background-color: #656978;
  display: flex;
  gap: 10px;
  height: 50px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
  width: 70px;
}

.malseller-detail .text-wrapper-10 {
  color: #ffffff;
  flex: 1;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 15px;
  font-weight: 700;
  height: 21px;
  letter-spacing: 0;
  line-height: 21px;
  position: relative;
  text-align: center;
  white-space: nowrap;
}

.malseller-detail .body-row-cell-2 {
  align-items: center;
  background-color: #656978;
  display: flex;
  gap: 10px;
  height: 50px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
  width: 200px;
}

.malseller-detail .body-row-cell-3 {
  align-items: center;
  background-color: #656978;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  height: 50px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
}

.malseller-detail .body-row-cell-4 {
  align-items: center;
  background-color: #656978;
  display: flex;
  gap: 10px;
  height: 50px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
  width: 120px;
}

.malseller-detail .text-wrapper-11 {
  color: #ffffff;
  flex: 1;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 21px;
  position: relative;
  text-align: center;
}

.malseller-detail .record-frame {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-wrap: wrap;
  gap: 0px 0px;
  height: 45px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.malseller-detail .body-row-data {
  align-items: center;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  display: flex;
  gap: 10px;
  height: 45px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
  width: 70px;
}

.malseller-detail .text-wrapper-12 {
  color: #666666;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 15px;
  font-weight: 400;
  height: 21px;
  letter-spacing: 0;
  line-height: 21px;
  position: relative;
  text-align: center;
  white-space: nowrap;
}

.malseller-detail .body-row-data-2 {
  align-items: center;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  display: flex;
  gap: 10px;
  height: 45px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
  width: 200px;
}

.malseller-detail .text-wrapper-13 {
  color: #666666;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 15px;
  font-weight: 400;
  height: 21px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.malseller-detail .body-row-data-3 {
  align-items: center;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  height: 45px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
}

.malseller-detail .body-row-data-4 {
  align-items: center;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  display: flex;
  gap: 10px;
  height: 45px;
  justify-content: center;
  padding: 9px 5px;
  position: relative;
  width: 120px;
}

.malseller-detail .body-row-data-5 {
  align-items: center;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  display: flex;
  gap: 10px;
  height: 45px;
  padding: 9px 5px;
  position: relative;
  width: 120px;
}

.malseller-detail .body-page-instance {
  left: unset !important;
  top: unset !important;
}
