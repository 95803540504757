.my-invite-link {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.my-invite-link .div-11 {
  background-color: #ffffff;
  height: 1460px;
  position: relative;
  width: 1440px;
}

.my-invite-link .right-menu-5 {
  left: 1034px !important;
  position: absolute !important;
  top: 0 !important;
}

.my-invite-link .main-logo-6 {
  left: 131px !important;
  position: absolute !important;
  top: 50px !important;
}

.my-invite-link .search-area-6 {
  left: 541px !important;
  position: absolute !important;
  top: 50px !important;
}

.my-invite-link .nav-menu-6 {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.my-invite-link .link-tree-6 {
  left: 131px !important;
  position: absolute !important;
  top: 150px !important;
}

.my-invite-link .my-page-menu-3 {
  left: 130px !important;
  position: absolute !important;
  top: 200px !important;
}

.my-invite-link .my-page-avata-3 {
  left: 370px !important;
  position: absolute !important;
  top: 200px !important;
}

.my-invite-link .div-12 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  left: 370px;
  min-height: 400px;
  position: absolute;
  top: 400px;
  width: 900px;
}

.my-invite-link .div-referer-url {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--storegpakoreacomalto);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 0.01px;
  padding: 2px;
  position: relative;
  width: 100%;
}

.my-invite-link .div-dropdown {
  align-self: stretch;
  height: 34.49px;
  position: relative;
  width: 100%;
}

.my-invite-link .button-4 {
  background-color: var(--storegpakoreacomfern);
  border: 1px solid;
  border-color: var(--storegpakoreacomapple);
  border-radius: 4px;
  height: 34px;
  left: 0;
  position: absolute;
  top: 0;
  width: 119px;
}

.my-invite-link .overlap-group-6 {
  height: 20px;
  left: 13px;
  position: relative;
  top: 6px;
  width: 92px;
}

.my-invite-link .text-wrapper-69 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 20px;
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 85px;
}

.my-invite-link .span-caret {
  border-color: #ffffff;
  border-left-style: solid;
  border-left-width: 3.75px;
  border-right-style: solid;
  border-right-width: 3.75px;
  border-top-style: solid;
  border-top-width: 3.75px;
  height: 4px;
  left: 85px;
  position: absolute;
  top: 11px;
  width: 8px;
}

.my-invite-link .text-wrapper-70 {
  color: #212121;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 700;
  height: 19px;
  left: 132px;
  letter-spacing: 0;
  line-height: 18.6px;
  position: absolute;
  top: 6px;
  white-space: nowrap;
  width: 63px;
}

.my-invite-link .div-input-group-2 {
  align-items: flex-start;
  background-color: #ffffff;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.14e-13px;
  justify-content: center;
  position: relative;
}

.my-invite-link .input-8 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 4px 0px 0px 4px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 43.98px;
  padding: 12.61px 12px 12.62px;
  position: relative;
  z-index: 1;
}

.my-invite-link .div-13 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow: scroll;
  position: relative;
  width: 100%;
}

.my-invite-link .text-wrapper-71 {
  color: #555555;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.my-invite-link .span-input-group-btn {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  padding: 5.99px 5.01px 6px 4px;
  position: relative;
  z-index: 0;
}

.my-invite-link .link-10 {
  align-items: center;
  background-color: var(--storegpakoreacomemperor);
  display: inline-flex;
  flex: 0 0 auto;
  padding: 6px 12px;
  position: relative;
}

.my-invite-link .text-wrapper-72 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.my-invite-link .div-box-empty-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 120px 385.44px 120px 385.42px;
  position: relative;
  width: 100%;
}

.my-invite-link .text-wrapper-73 {
  align-self: stretch;
  color: #888888;
  font-family: var(--store-gpakorea-com-roboto-regular-15-6-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-15-6-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-15-6-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-15-6-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-regular-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-15-6-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.my-invite-link .footer-panel-5 {
  align-items: center;
  background-color: #606060;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 1300px;
  width: 1440px;
}

.my-invite-link .footer-frame-6 {
  height: 160px;
  position: relative;
  width: 1160px;
}

.my-invite-link .company-infos-4 {
  height: 72px;
  left: 213px;
  position: absolute;
  top: 43px;
  width: 683px;
}

.my-invite-link .flexcontainer-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 72px;
  position: relative;
  width: 681px;
}

.my-invite-link .text-i-6 {
  align-self: stretch;
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
  position: relative;
}

.my-invite-link .text-wrapper-74 {
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
}

.my-invite-link .sns-link-6 {
  height: 40px;
  left: 949px;
  position: absolute;
  top: 60px;
  width: 200px;
}

.my-invite-link .list-item-link-21 {
  background-image: url(../../../static/img/list-item-link.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-invite-link .text-wrapper-75 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 40px;
}

.my-invite-link .list-item-link-22 {
  background-image: url(../../../static/img/list-item-link-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 51px;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-invite-link .list-item-link-23 {
  background-image: url(../../../static/img/list-item-link-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 103px;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-invite-link .text-wrapper-76 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 53px;
}

.my-invite-link .list-item-link-24 {
  background-image: url(../../../static/img/list-item-link-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 154px;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-invite-link .text-wrapper-77 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 66px;
}

.my-invite-link .logo-gothic-6 {
  height: 111px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 25px;
  width: 145px;
}

.my-invite-link .kakao-service-5 {
  left: 0 !important;
  position: absolute !important;
  top: 200px !important;
}

.my-invite-link .jimmed-products-6 {
  left: 1310px !important;
  position: absolute !important;
  top: 560px !important;
}

.my-invite-link .viewed-products-6 {
  left: 1310px !important;
  position: absolute !important;
  top: 200px !important;
}
