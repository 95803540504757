.collect-detail {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.collect-detail .div-6 {
  background-color: #ffffff;
  height: 3425px;
  position: relative;
  width: 1640px;
}

.collect-detail .main-frame {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  left: 240px;
  padding: 10px;
  position: absolute;
  top: 100px;
  width: 1400px;
}

.collect-detail .title-wrapper {
  align-self: stretch;
  height: 25px;
  margin-right: -2.00px;
  position: relative;
  width: 100%;
}

.collect-detail .title-3 {
  color: #000000;
  font-family: "NanumGothic-Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 25px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 1380px;
}

.collect-detail .body-sub-title-2 {
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #bbbbbb;
  height: 10px;
  position: relative;
  width: 100%;
}

.collect-detail .section-frame {
  align-items: flex-start;
  display: flex;
  height: 610px;
  justify-content: space-between;
  position: relative;
  width: 1380px;
}

.collect-detail .frame-2 {
  height: 610px;
  position: relative;
  width: 690px;
}

.collect-detail .div-image-wrapper {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #aaaaaa;
  border-right-style: solid;
  border-right-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 610px;
  left: 80px;
  padding: 10px;
  position: absolute;
  top: 0;
  width: 610px;
}

.collect-detail .div-image {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.collect-detail .frame-3 {
  border: 1px solid;
  border-color: #aaaaaa;
  height: 610px;
  left: 0;
  position: absolute;
  top: 0;
  width: 80px;
}

.collect-detail .frame-4 {
  background-image: url(https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/665935372ad594dd39825363/img/div-image-2@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 60px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 60px;
}

.collect-detail .frame-5 {
  background-image: url(https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/665935372ad594dd39825363/img/div-image-2@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 60px;
  left: 10px;
  position: absolute;
  top: 80px;
  width: 60px;
}

.collect-detail .frame-6 {
  background-image: url(https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/665935372ad594dd39825363/img/div-image-2@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 60px;
  left: 10px;
  position: absolute;
  top: 150px;
  width: 60px;
}

.collect-detail .frame-7 {
  background-image: url(https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/665935372ad594dd39825363/img/div-image-2@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 60px;
  left: 10px;
  position: absolute;
  top: 220px;
  width: 60px;
}

.collect-detail .frame-8 {
  background-image: url(https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/665935372ad594dd39825363/img/div-image-2@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 60px;
  left: 10px;
  position: absolute;
  top: 290px;
  width: 60px;
}

.collect-detail .frame-9 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #aaaaaa;
  border-right-style: solid;
  border-right-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex-direction: column;
  height: 610px;
  padding: 10px;
  position: relative;
  width: 690px;
}

.collect-detail .frame-10 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.collect-detail .text-wrapper-19 {
  color: #000000;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.collect-detail .frame-11 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 80px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.collect-detail .p {
  align-self: stretch;
  color: #000000;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
}

.collect-detail .section-frame-2 {
  align-items: flex-start;
  border: 1px solid;
  border-color: #aaaaaa;
  display: flex;
  flex-direction: column;
  height: 155px;
  padding: 10px;
  position: relative;
  width: 1380px;
}

.collect-detail .frame-12 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.collect-detail .text-wrapper-20 {
  color: #0075ff;
  flex: 1;
  font-family: "Roboto-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.collect-detail .frame-13 {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 45px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.collect-detail .frame-14 {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #aaaaaa;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
}

.collect-detail .text-wrapper-21 {
  color: #000000;
  flex: 1;
  font-family: "Roboto-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.collect-detail .frame-15 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #aaaaaa;
  border-right-style: solid;
  border-right-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
}

.collect-detail .frame-16 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #aaaaaa;
  border-left-style: solid;
  border-left-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
}

.collect-detail .frame-17 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #aaaaaa;
  border-right-style: solid;
  border-right-width: 1px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
}

.collect-detail .frame-18 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 45px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.collect-detail .section-frame-3 {
  align-items: flex-start;
  border: 1px solid;
  border-color: #aaaaaa;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 10px;
  position: relative;
  width: 1380px;
}

.collect-detail .section-frame-4 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 0px 0px;
  position: relative;
  width: 1380px;
}

.collect-detail .rec-item-frame {
  align-items: center;
  display: flex;
  position: relative;
  width: 1380px;
}

.collect-detail .div-7 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 700px;
  position: relative;
  width: 690px;
}

.collect-detail .overlap-group-wrapper {
  align-self: stretch;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #aaaaaa;
  height: 580px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.collect-detail .overlap-group-2 {
  height: 580px;
  position: relative;
  width: 690px;
}

.collect-detail .back-image-frame {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 0;
  padding: 10px;
  position: absolute;
  top: 0;
  width: 690px;
}

.collect-detail .back-image {
  flex: 1;
  flex-grow: 1;
  height: 560px;
  position: relative;
}

.collect-detail .button-3 {
  all: unset;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 28px;
  left: 640px;
  opacity: 0.8;
  position: absolute;
  top: 20px;
  width: 28px;
}

.collect-detail .button-box-2 {
  color: #888888;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.collect-detail .info-frame {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 120px;
  position: relative;
  width: 100%;
  z-index: 0;
}

.collect-detail .frame-19 {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 40px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.collect-detail .frame-20 {
  align-self: stretch;
  height: 40px;
  position: relative;
  width: 100%;
}

.collect-detail .thumbnail-2 {
  align-self: stretch;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #aaaaaa;
  border-right-style: solid;
  border-right-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  height: 580px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.collect-detail .section-frame-5 {
  align-items: flex-start;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 530px;
  position: relative;
  width: 1380px;
}

.collect-detail .form-title-frame {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
  width: 1160px;
}

.collect-detail .form-title {
  color: #0075ff;
  flex: 1;
  font-family: "Roboto-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22px;
  position: relative;
}

.collect-detail .form-caption-frame {
  align-items: center;
  align-self: stretch;
  border-radius: 2px;
  display: flex;
  gap: 10px;
  height: 40px;
  overflow: hidden;
  padding: 0px 10px;
  position: relative;
  width: 100%;
}

.collect-detail .form-caption-wrapper {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #aaaaaa;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
  width: 1040px;
}

.collect-detail .form-caption {
  -webkit-text-stroke: 1px #aaaaaaaa;
  color: #333333;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -1.00px;
  position: relative;
}

.collect-detail .form-determ-tag-wrapper {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #aaaaaa;
  display: flex;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100px;
}

.collect-detail .form-determ-tag {
  color: #333333;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.collect-detail .form-determ-drop-wrapper {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #aaaaaa;
  display: flex;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 200px;
}

.collect-detail .form-determ-drop {
  color: #333333;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22px;
  position: relative;
  text-align: center;
}

.collect-detail .form-text-frame {
  align-items: center;
  align-self: stretch;
  border-radius: 2px;
  display: flex;
  gap: 10px;
  height: 360px;
  justify-content: center;
  overflow: hidden;
  padding: 10px;
  position: relative;
  width: 100%;
}

.collect-detail .form-text-wrapper {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  height: 340px;
  justify-content: center;
  padding: 10px;
  position: relative;
}

.collect-detail .form-text {
  color: #333333;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 320px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
}

.collect-detail .form-submit-frame {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 1120px;
  height: 60px;
  justify-content: center;
  padding: 10px;
  position: relative;
  width: 100%;
}

.collect-detail .form-submit-layout {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #888888;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 5px 0px;
  position: relative;
  width: 120px;
}

.collect-detail .form-submit {
  color: #888888;
  flex: 1;
  font-family: "Roboto-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 30px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.collect-detail .form-submit-wrapper {
  align-items: center;
  background-color: #27ae60;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 5px 0px;
  position: relative;
  width: 120px;
}

.collect-detail .form-submit-2 {
  color: #ffffff;
  flex: 1;
  font-family: "Roboto-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 30px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.collect-detail .main-logo-2 {
  left: 0 !important;
  position: absolute !important;
  top: 40px !important;
}

.collect-detail .right-menu-3 {
  left: 1404px !important;
  position: absolute !important;
}

.collect-detail .left-menu-3 {
  left: 0 !important;
  position: absolute !important;
  width: 240px !important;
}
