.determin-detail {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.determin-detail .div-8 {
  background-color: #ffffff;
  height: 3425px;
  position: relative;
  width: 1640px;
}

.determin-detail .main-frame-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  left: 240px;
  padding: 10px;
  position: absolute;
  top: 100px;
  width: 1400px;
}

.determin-detail .bulletin-body-title-2 {
  align-self: stretch;
  height: 25px;
  margin-right: -2.00px;
  position: relative;
  width: 100%;
}

.determin-detail .title-4 {
  color: #000000;
  font-family: "NanumGothic-Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 25px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 1380px;
}

.determin-detail .body-sub-title-3 {
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #bbbbbb;
  height: 10px;
  position: relative;
  width: 100%;
}

.determin-detail .section-frame-6 {
  align-items: flex-start;
  display: flex;
  height: 610px;
  justify-content: space-between;
  position: relative;
  width: 1380px;
}

.determin-detail .frame-21 {
  height: 610px;
  position: relative;
  width: 690px;
}

.determin-detail .img-wrapper {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #aaaaaa;
  border-right-style: solid;
  border-right-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 610px;
  left: 80px;
  padding: 10px;
  position: absolute;
  top: 0;
  width: 610px;
}

.determin-detail .img {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.determin-detail .frame-22 {
  border: 1px solid;
  border-color: #aaaaaa;
  height: 610px;
  left: 0;
  position: absolute;
  top: 0;
  width: 80px;
}

.determin-detail .frame-23 {
  background-image: url(https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/665935372ad594dd39825363/img/div-image-2@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 60px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 60px;
}

.determin-detail .frame-24 {
  background-image: url(https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/665935372ad594dd39825363/img/div-image-2@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 60px;
  left: 10px;
  position: absolute;
  top: 80px;
  width: 60px;
}

.determin-detail .frame-25 {
  background-image: url(https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/665935372ad594dd39825363/img/div-image-2@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 60px;
  left: 10px;
  position: absolute;
  top: 150px;
  width: 60px;
}

.determin-detail .frame-26 {
  background-image: url(https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/665935372ad594dd39825363/img/div-image-2@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 60px;
  left: 10px;
  position: absolute;
  top: 220px;
  width: 60px;
}

.determin-detail .frame-27 {
  background-image: url(https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/665935372ad594dd39825363/img/div-image-2@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 60px;
  left: 10px;
  position: absolute;
  top: 290px;
  width: 60px;
}

.determin-detail .frame-28 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #aaaaaa;
  border-right-style: solid;
  border-right-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex-direction: column;
  height: 610px;
  padding: 10px;
  position: relative;
  width: 690px;
}

.determin-detail .frame-29 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.determin-detail .text-wrapper-22 {
  color: #000000;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.determin-detail .frame-30 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 80px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.determin-detail .text-wrapper-23 {
  align-self: stretch;
  color: #000000;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
}

.determin-detail .section-frame-7 {
  align-items: flex-start;
  border: 1px solid;
  border-color: #aaaaaa;
  display: flex;
  flex-direction: column;
  height: 155px;
  padding: 10px;
  position: relative;
  width: 1380px;
}

.determin-detail .frame-31 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.determin-detail .text-wrapper-24 {
  color: #0075ff;
  flex: 1;
  font-family: "Roboto-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.determin-detail .frame-32 {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 45px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.determin-detail .frame-33 {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #aaaaaa;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
}

.determin-detail .text-wrapper-25 {
  color: #000000;
  flex: 1;
  font-family: "Roboto-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.determin-detail .frame-34 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #aaaaaa;
  border-right-style: solid;
  border-right-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
}

.determin-detail .frame-35 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #aaaaaa;
  border-left-style: solid;
  border-left-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
}

.determin-detail .frame-36 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #aaaaaa;
  border-right-style: solid;
  border-right-width: 1px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
}

.determin-detail .frame-37 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 45px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.determin-detail .section-frame-8 {
  align-items: flex-start;
  border: 1px solid;
  border-color: #aaaaaa;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 10px;
  position: relative;
  width: 1380px;
}

.determin-detail .section-frame-9 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 0px 0px;
  position: relative;
  width: 1380px;
}

.determin-detail .rec-item-frame-2 {
  align-items: center;
  display: flex;
  position: relative;
  width: 1380px;
}

.determin-detail .div-9 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 700px;
  position: relative;
  width: 690px;
}

.determin-detail .thumbnail-3 {
  align-self: stretch;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #aaaaaa;
  height: 580px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.determin-detail .overlap-group-3 {
  height: 580px;
  position: relative;
  width: 690px;
}

.determin-detail .back-image-wrapper {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 0;
  padding: 10px;
  position: absolute;
  top: 0;
  width: 690px;
}

.determin-detail .back-image-2 {
  flex: 1;
  flex-grow: 1;
  height: 560px;
  position: relative;
}

.determin-detail .button-4 {
  all: unset;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 28px;
  left: 640px;
  opacity: 0.8;
  position: absolute;
  top: 20px;
  width: 28px;
}

.determin-detail .button-box-3 {
  color: #888888;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.determin-detail .info-frame-2 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 120px;
  position: relative;
  width: 100%;
  z-index: 0;
}

.determin-detail .frame-38 {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 40px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.determin-detail .frame-39 {
  align-self: stretch;
  height: 40px;
  position: relative;
  width: 100%;
}

.determin-detail .thumbnail-4 {
  align-self: stretch;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #aaaaaa;
  border-right-style: solid;
  border-right-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  height: 580px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.determin-detail .section-frame-10 {
  align-items: flex-start;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 530px;
  position: relative;
  width: 1380px;
}

.determin-detail .form-title-wrapper {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
  width: 1160px;
}

.determin-detail .form-title-2 {
  color: #0075ff;
  flex: 1;
  font-family: "Roboto-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22px;
  position: relative;
}

.determin-detail .form-caption-frame-2 {
  align-items: center;
  align-self: stretch;
  border-radius: 2px;
  display: flex;
  gap: 10px;
  height: 40px;
  overflow: hidden;
  padding: 0px 10px;
  position: relative;
  width: 100%;
}

.determin-detail .frame-40 {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #aaaaaa;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
  width: 1040px;
}

.determin-detail .form-caption-2 {
  -webkit-text-stroke: 1px #aaaaaaaa;
  color: #333333;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -1.00px;
  position: relative;
}

.determin-detail .frame-41 {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #aaaaaa;
  display: flex;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100px;
}

.determin-detail .form-determ-tag-2 {
  color: #333333;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.determin-detail .frame-42 {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #aaaaaa;
  display: flex;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 200px;
}

.determin-detail .form-determ-drop-2 {
  color: #333333;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22px;
  position: relative;
  text-align: center;
}

.determin-detail .form-text-frame-2 {
  align-items: center;
  align-self: stretch;
  border-radius: 2px;
  display: flex;
  gap: 10px;
  height: 360px;
  justify-content: center;
  overflow: hidden;
  padding: 10px;
  position: relative;
  width: 100%;
}

.determin-detail .frame-43 {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  height: 340px;
  justify-content: center;
  padding: 10px;
  position: relative;
}

.determin-detail .form-text-2 {
  color: #333333;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 320px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
}

.determin-detail .form-submit-frame-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 1120px;
  height: 60px;
  justify-content: center;
  padding: 10px;
  position: relative;
  width: 100%;
}

.determin-detail .form-submit-layout-2 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #888888;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 5px 0px;
  position: relative;
  width: 120px;
}

.determin-detail .form-submit-3 {
  color: #888888;
  flex: 1;
  font-family: "Roboto-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 30px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.determin-detail .form-submit-layout-3 {
  align-items: center;
  background-color: #27ae60;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 5px 0px;
  position: relative;
  width: 120px;
}

.determin-detail .form-submit-4 {
  color: #ffffff;
  flex: 1;
  font-family: "Roboto-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 30px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.determin-detail .main-logo-3 {
  left: 0 !important;
  position: absolute !important;
  top: 40px !important;
}

.determin-detail .right-menu-4 {
  left: 1404px !important;
  position: absolute !important;
}

.determin-detail .left-menu-4 {
  left: 0 !important;
  position: absolute !important;
  width: 240px !important;
}
