.main-logo {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 50px;
  position: relative;
  top: 50px;
  width: 240px;
}

.main-logo .link {
  height: 50px;
  position: relative;
  width: 240px;
}
