.right-menu {
  align-items: center;
  display: inline-flex;
  gap: 1px;
  left: 1406px;
  position: relative;
  top: 40px;
}

.right-menu .item-link {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 0px 15px;
  position: relative;
  width: 71px;
}

.right-menu .text-wrapper-3 {
  color: #333333;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 50px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.right-menu .after {
  border-color: #dfdfdf;
  border-right-style: solid;
  border-right-width: 1px;
  height: 28.00%;
  position: absolute;
  right: 0;
  top: 36.00%;
  width: 1px;
}

.right-menu .item-link-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 0px 15px;
  position: relative;
  width: 81px;
}

.right-menu .text-wrapper-4 {
  color: #333333;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 50px;
  margin-right: -1.00px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.right-menu .div-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 50px;
  padding: 0px 10px;
  position: relative;
  width: 80px;
}

.right-menu .text-wrapper-5 {
  align-self: stretch;
  color: #333333;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 50px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}
