.my-page-menu {
  height: 376px;
  position: relative;
  width: 240px;
}

.my-page-menu .my-page-sub-menu {
  align-items: flex-start;
  background-color: var(--storegpakoreacomnero);
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 10px 0px;
  position: absolute;
  top: 44px;
  width: 240px;
}

.my-page-menu .span-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 9.47px 15px 10px;
  position: relative;
  width: 100%;
}

.my-page-menu .span {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  padding: 0px 0px 0.54px;
  position: relative;
}

.my-page-menu .text-wrapper-11 {
  color: #232323;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
}

.my-page-menu .after-7 {
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: #232323;
  height: 1px;
  left: 0;
  position: absolute;
  top: 20px;
  width: 48px;
}

.my-page-menu .item-link-7 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 10px 15px;
  position: relative;
  width: 100%;
}

.my-page-menu .text-wrapper-12 {
  align-self: stretch;
  color: #333333;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  cursor: pointer;
}

.my-page-menu .div-wrapper-2 {
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: #232323;
  height: 44px;
  left: 0;
  position: absolute;
  top: 0;
  width: 240px;
}

.my-page-menu .text-wrapper-13 {
  color: #232323;
  font-family: "Roboto", Helvetica;
  font-size: 20.8px;
  font-weight: 700;
  height: 23px;
  left: 0;
  letter-spacing: -1.04px;
  line-height: 22.9px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 95px;
}
