.faq-detail {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.faq-detail .div-2 {
  height: 1354px;
  overflow-x: hidden;
  position: relative;
  width: 1440px;
}

.faq-detail .right-menu-instance {
  left: 1037px !important;
  position: absolute !important;
  top: 0 !important;
}

.faq-detail .main-logo-instance {
  left: 130px !important;
  position: absolute !important;
  top: 50px !important;
}

.faq-detail .search-area-instance {
  left: 540px !important;
  position: absolute !important;
  top: 50px !important;
}

.faq-detail .nav-menu-instance {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.faq-detail .link-tree-instance {
  left: 130px !important;
  position: absolute !important;
  top: 150px !important;
}

.faq-detail .faq-detail-body {
  height: 960px;
  left: 360px;
  overflow: hidden;
  position: absolute;
  top: 200px;
  width: 940px;
}

.faq-detail .faq-detail-body-2 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  left: 0;
  position: absolute;
  top: 0;
}

.faq-detail .span-bar {
  background-color: #fc871e;
  height: 4px;
  position: relative;
  width: 88.6px;
}

.faq-detail .heading {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 28px;
  font-weight: 700;
  height: 21px;
  letter-spacing: 0;
  line-height: 21px;
  position: relative;
  white-space: nowrap;
  width: 330px;
}

.faq-detail .faq-detail-body-3 {
  height: 79px;
  left: 0;
  position: absolute;
  top: 56px;
  width: 940px;
}

.faq-detail .div-3 {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 0;
  padding: 9px 20px;
  position: absolute;
  top: 0;
  width: 940px;
}

.faq-detail .p {
  color: #505050;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1px;
  position: relative;
}

.faq-detail .div-4 {
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: #656978;
  height: 39px;
  left: 0;
  position: absolute;
  top: 40px;
  width: 940px;
}

.faq-detail .table-body-row-data {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  height: 21px;
  left: 20px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: 6px;
  white-space: nowrap;
  width: 260px;
}

.faq-detail .table-body-row-data-2 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  height: 21px;
  left: 300px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
  width: 300px;
}

.faq-detail .table-body-row-data-3 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  height: 21px;
  left: 790px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: right;
  top: 6px;
  white-space: nowrap;
  width: 130px;
}

.faq-detail .faq-detail-body-4 {
  height: 788px;
  left: 0;
  position: absolute;
  top: 135px;
  width: 940px;
}

.faq-detail .table-body-row-data-wrapper {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #656978;
  display: flex;
  gap: 10px;
  height: 788px;
  justify-content: center;
  padding: 30px;
  position: relative;
  width: 940px;
}

.faq-detail .table-body-row-data-4 {
  align-self: stretch;
  color: #000000;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1px;
  position: relative;
}

.faq-detail .faq-detail-body-back {
  height: 37px;
  left: 865px;
  position: absolute;
  top: 923px;
  width: 75px;
}

.faq-detail .link-4 {
  background-color: #41444f;
  height: 37px;
  position: relative;
}

.faq-detail .text-wrapper-22 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: 19px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: right;
  top: 6px;
  white-space: nowrap;
  width: 27px;
}

.faq-detail .customer-menu-instance {
  left: 130px !important;
  position: absolute !important;
  top: 200px !important;
}

.faq-detail .kakao-service-instance {
  left: 0 !important;
  position: absolute !important;
  top: 200px !important;
}

.faq-detail .viewed-products-instance {
  left: 1310px !important;
  position: absolute !important;
  top: 200px !important;
}

.faq-detail .jimmed-products-instance {
  left: 1310px !important;
  position: absolute !important;
  top: 560px !important;
}

.faq-detail .footer-panel {
  align-items: center;
  background-color: #606060;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 1194px;
  width: 1440px;
}

.faq-detail .footer-frame {
  height: 160px;
  position: relative;
  width: 1160px;
}

.faq-detail .company-infos {
  height: 72px;
  left: 213px;
  position: absolute;
  top: 43px;
  width: 683px;
}

.faq-detail .flexcontainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 72px;
  position: relative;
  width: 681px;
}

.faq-detail .text-i {
  align-self: stretch;
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
  position: relative;
}

.faq-detail .text-wrapper-23 {
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
}

.faq-detail .sns-link {
  height: 40px;
  left: 949px;
  position: absolute;
  top: 60px;
  width: 200px;
}

.faq-detail .list-item-link {
  background-image: url(https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/6631ce2b390a47a19a365d2d/img/list---item---link@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 46px;
}

.faq-detail .text-wrapper-24 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 40px;
}

.faq-detail .list-item-link-2 {
  background-image: url(https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/6631ce2b390a47a19a365d2d/img/list---item---link-1@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 51px;
  position: absolute;
  top: 0;
  width: 46px;
}

.faq-detail .list-item-link-3 {
  background-image: url(https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/6631ce2b390a47a19a365d2d/img/list---item---link-2@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 103px;
  position: absolute;
  top: 0;
  width: 46px;
}

.faq-detail .text-wrapper-25 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 53px;
}

.faq-detail .list-item-link-4 {
  background-image: url(https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/6631ce2b390a47a19a365d2d/img/list---item---link-3@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 154px;
  position: absolute;
  top: 0;
  width: 46px;
}

.faq-detail .text-wrapper-26 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 66px;
}

.faq-detail .logo-gothic {
  height: 111px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 25px;
  width: 145px;
}
