.PD-body-review {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 23px;
  padding: 300px 0px;
  position: relative;
}

.PD-body-review .review-title {
  color: #333333;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 125px;
}

.PD-body-review .review-head {
  background-color: #f1f1f1;
  height: 126.59px;
  position: relative;
  width: 694px;
}

.PD-body-review .text-wrapper-18 {
  color: #800080;
  font-family: "Roboto", Helvetica;
  font-size: 31.4px;
  font-weight: 700;
  height: 21px;
  left: 266px;
  letter-spacing: 0;
  line-height: 20.8px;
  position: absolute;
  text-align: center;
  top: 55px;
  white-space: nowrap;
  width: 61px;
}

.PD-body-review .div-star {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 5px;
  left: 341px;
  position: absolute;
  top: 40px;
}

.PD-body-review .div-stars {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 3.8px;
  justify-content: center;
  padding: 4px 0px 3.8px;
  position: relative;
  width: 100%;
}

.PD-body-review .symbol-5 {
  color: #ffa500;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 13px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.PD-body-review .p-2 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 4.3px 0px 4.28px;
  position: relative;
  width: 87.3px;
}

.PD-body-review .text-wrapper-19 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20.8px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.PD-body-review .review-list {
  height: 281px;
  position: relative;
  width: 704px;
}

.PD-body-review .item-2 {
  align-items: flex-start;
  border-bottom-style: solid;
  border-bottom-width: 1.11px;
  border-color: #e1e1e1;
  display: flex;
  flex-direction: column;
  height: 280px;
  padding: 20px 10px 20px 100px;
  position: relative;
  top: 1px;
  width: 704px;
}

.PD-body-review .face-frame {
  background-image: url(../../../static/img/faceimage.png);
  background-size: 100% 100%;
  height: 80px;
  left: 8px;
  position: absolute;
  top: 20px;
  width: 80px;
}

.PD-body-review .container {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 0px 378.02px 10px 0px;
  position: relative;
  width: 100%;
}

.PD-body-review .container-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 97.69px;
}

.PD-body-review .text-wrapper-20 {
  color: #888888;
  font-family: "Inter", Helvetica;
  font-size: 11.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20.8px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.PD-body-review .container-3 {
  align-items: flex-start;
  display: flex;
  gap: 3.8px;
  padding: 4.05px 0px 3.75px 10px;
  position: relative;
  width: 98.32px;
}

.PD-body-review .symbol-6 {
  color: #ffa500;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 13px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.PD-body-review .symbol-7 {
  color: #ffa500;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 13px;
  margin-right: -1.88px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.PD-body-review .vertical-divider {
  border-color: #e1e1e1;
  border-left-style: solid;
  border-left-width: 1.11px;
  height: 12px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 1px;
}

.PD-body-review .container-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.PD-body-review .text-wrapper-21 {
  align-self: stretch;
  color: #666666;
  font-family: "Inter", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20.8px;
  margin-top: -1px;
  position: relative;
}

.PD-body-review .container-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  padding: 15.99px 0px 0px;
  position: relative;
  width: 100%;
}

.PD-body-review .small-3 {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.PD-body-review .text-wrapper-22 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 0;
  color: #666666;
  display: -webkit-box;
  font-family: "Inter", Helvetica;
  font-size: 11.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20.8px;
  margin-top: -1px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
}

.PD-body-review .margin {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: -2.84e-14px;
  padding: 0px 0px 0px 20px;
  position: relative;
  width: 220px;
}

.PD-body-review .container-6 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  height: 20.8px;
  position: relative;
}

.PD-body-review .text-wrapper-23 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 0;
  color: #808080;
  display: -webkit-box;
  font-family: "Inter", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20.8px;
  margin-top: -1px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
}

.PD-body-review .container-7 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 100px;
  padding: 20px 0px 0px 20px;
  position: absolute;
  top: 110px;
  width: 594px;
}

.PD-body-review .background-border {
  align-items: flex-start;
  align-self: stretch;
  background-color: #fafafa;
  border: 1px solid;
  border-color: #efefef;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 21.11px;
  position: relative;
  width: 100%;
}

.PD-body-review .text-wrapper-24 {
  align-self: stretch;
  color: #666666;
  font-family: "Inter", Helvetica;
  font-size: 12.7px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20.8px;
  margin-top: -1px;
  position: relative;
}

.PD-body-review .border {
  border-bottom-style: solid;
  border-bottom-width: 1.11px;
  border-color: #dddddd;
  border-left-style: solid;
  border-left-width: 1.11px;
  height: 12px;
  left: 0;
  position: absolute;
  top: 0;
  width: 12px;
}

.PD-body-review .review-more {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #cccccc;
  border-radius: 4px;
  display: flex;
  flex: 0 0 auto;
  padding: 7px 302.61px 7px 302.59px;
  position: relative;
  width: 704px;
}

.PD-body-review .text-wrapper-25 {
  color: #333333;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}
