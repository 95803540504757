.report-list {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.report-list .div-2 {
  background-color: #ffffff;
  height: 1690px;
  position: relative;
  width: 1640px;
}

.report-list .bulletin-body {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 1300px;
  left: 240px;
  padding: 10px;
  position: absolute;
  top: 100px;
  width: 1400px;
}

.report-list .body {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  padding: 5px 0px 95px;
  position: relative;
  width: 100%;
}

.report-list .bulletin-body-title {
  align-self: stretch;
  height: 25px;
  position: relative;
  width: 100%;
}

.report-list .title {
  color: #000000;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 25px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -1px;
  white-space: nowrap;
  width: 1380px;
}

.report-list .body-head {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 10px 5px;
  position: relative;
  width: 100%;
}

.report-list .search {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 5px 5px;
  padding: 10px 5px;
  position: relative;
  width: 320px;
}

.report-list .dropdown {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  height: 28px;
  padding: 4.5px 5px;
  position: relative;
  width: 124px;
}

.report-list .text-wrapper-8 {
  color: #000000;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.report-list .dropdown-2 {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  flex: 1;
  flex-grow: 1;
  height: 28px;
  padding: 4.5px 5px;
  position: relative;
}

.report-list .element {
  color: #000000;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
}

.report-list .div-3 {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 5px 5px;
  padding: 10px 5px;
  position: relative;
  width: 240px;
}

.report-list .dropdown-3 {
  align-items: center;
  display: flex;
  height: 28px;
  padding: 4.5px 5px;
  position: relative;
  width: 60px;
}

.report-list .text-wrapper-9 {
  color: #000000;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
}

.report-list .nation {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 5px 5px;
  padding: 10px 5px;
  position: relative;
  width: 230px;
}

.report-list .dropdown-4 {
  align-items: center;
  display: flex;
  height: 28px;
  padding: 4.5px 5px;
  position: relative;
  width: 50px;
}

.report-list .body-table {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.report-list .form-table {
  align-items: flex-start;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #adadad;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 1380px;
}

.report-list .head-frame {
  align-items: flex-start;
  display: flex;
  height: 50px;
  position: relative;
  width: 1380px;
}

.report-list .body-row-cell {
  align-items: center;
  background-color: #656978;
  display: flex;
  gap: 10px;
  height: 50px;
  justify-content: center;
  padding: 16px 5px;
  position: relative;
  width: 70px;
}

.report-list .text-wrapper-10 {
  color: #ffffff;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.report-list .body-row-cell-2 {
  align-items: center;
  background-color: #656978;
  display: flex;
  gap: 10px;
  height: 50px;
  justify-content: center;
  padding: 16px 5px;
  position: relative;
  width: 120px;
}

.report-list .body-row-cell-3 {
  align-items: center;
  background-color: #656978;
  display: flex;
  gap: 10px;
  height: 50px;
  justify-content: center;
  padding: 16px 5px;
  position: relative;
  width: 100px;
}

.report-list .body-row-cell-4 {
  align-items: center;
  background-color: #656978;
  display: flex;
  gap: 10px;
  height: 50px;
  justify-content: center;
  padding: 16px 5px;
  position: relative;
  width: 200px;
}

.report-list .text-wrapper-11 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  width: 130px;
}

.report-list .body-row-cell-5 {
  align-items: center;
  background-color: #656978;
  display: flex;
  gap: 10px;
  height: 50px;
  justify-content: center;
  padding: 16px 5px;
  position: relative;
  width: 130px;
}

.report-list .body-row-cell-6 {
  align-items: center;
  background-color: #656978;
  display: flex;
  gap: 10px;
  height: 50px;
  justify-content: center;
  padding: 16px 5px;
  position: relative;
  width: 140px;
}

.report-list .record-frame {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 0px 0px;
  height: 45px;
  position: relative;
  width: 1380px;
}

.report-list .body-row-data {
  align-items: center;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  display: flex;
  gap: 10px;
  height: 45px;
  justify-content: center;
  padding: 13.5px 5px;
  position: relative;
  width: 70px;
}

.report-list .text-wrapper-12 {
  color: #666666;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.report-list .body-row-data-2 {
  align-items: center;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  display: flex;
  gap: 10px;
  height: 45px;
  padding: 13.5px 5px;
  position: relative;
  width: 120px;
}

.report-list .body-row-data-3 {
  align-items: center;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  display: flex;
  gap: 10px;
  height: 45px;
  padding: 13.5px 5px;
  position: relative;
  width: 100px;
}

.report-list .body-row-data-4 {
  align-items: center;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  display: flex;
  gap: 10px;
  height: 45px;
  justify-content: center;
  padding: 13.5px 5px;
  position: relative;
  width: 200px;
}

.report-list .body-row-data-5 {
  align-items: center;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  display: flex;
  gap: 10px;
  height: 45px;
  justify-content: center;
  padding: 13.5px 5px;
  position: relative;
  width: 130px;
}

.report-list .body-row-data-6 {
  align-items: center;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  display: flex;
  gap: 10px;
  height: 45px;
  justify-content: center;
  padding: 13.5px 5px;
  position: relative;
  width: 100px;
}

.report-list .body-row-data-7 {
  align-items: center;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d7dbdd;
  display: flex;
  gap: 10px;
  height: 45px;
  justify-content: center;
  padding: 13.5px 5px;
  position: relative;
  width: 140px;
}

.report-list .body-page-instance {
  left: unset !important;
  top: unset !important;
}

.report-list .main-logo-instance {
  left: 0 !important;
  position: absolute !important;
}

.report-list .right-menu-instance {
  position: absolute !important;
}

.report-list .left-menu-instance {
  left: 0 !important;
  position: absolute !important;
  width: 240px !important;
}
