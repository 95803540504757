.monitoring-detail {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.monitoring-detail .div-3 {
  background-color: #ffffff;
  height: 1690px;
  overflow: hidden;
  position: relative;
  width: 1640px;
}

.monitoring-detail .body-wrapper {
  align-items: flex-start;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 1300px;
  left: 240px;
  padding: 10px;
  position: absolute;
  top: 100px;
  width: 1400px;
}

.monitoring-detail .body-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  padding: 5px 0px 95px;
  position: relative;
  width: 100%;
}

.monitoring-detail .title-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.monitoring-detail .title-2 {
  color: #000000;
  flex: 1;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 25px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
}

.monitoring-detail .body-sub-title-2 {
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #bbbbbb;
  height: 10px;
  position: relative;
  width: 100%;
}

.monitoring-detail .body-head-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 370px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.monitoring-detail .drop-downs-2 {
  align-items: center;
  display: flex;
  gap: 10px;
  height: 28px;
  position: relative;
  width: 610px;
}

.monitoring-detail .dropdown-4 {
  align-items: center;
  display: flex;
  height: 28px;
  padding: 5px;
  position: relative;
  width: 60px;
}

.monitoring-detail .text-wrapper-14 {
  color: #000000;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
}

.monitoring-detail .dropdown-5 {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  height: 28px;
  padding: 5px;
  position: relative;
  width: 120px;
}

.monitoring-detail .text-wrapper-15 {
  color: #000000;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
}

.monitoring-detail .dropdown-6 {
  align-items: center;
  display: flex;
  height: 28px;
  padding: 5px;
  position: relative;
  width: 70px;
}

.monitoring-detail .design-component-instance-node {
  left: unset !important;
  top: unset !important;
}

.monitoring-detail .footer-panel-2 {
  left: -100px !important;
  position: absolute !important;
  top: 1530px !important;
  width: 1920px !important;
}

.monitoring-detail .main-logo-2 {
  left: 0 !important;
  position: absolute !important;
  top: 40px !important;
  width: 240px !important;
}

.monitoring-detail .right-menu-2 {
  position: absolute !important;
}

.monitoring-detail .left-menu-2 {
  left: 0 !important;
  position: absolute !important;
  width: 240px !important;
}
