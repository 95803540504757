.signup-registry {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.signup-registry .div-2 {
  background-color: #ffffff;
  height: 1360px;
  overflow: hidden;
  position: relative;
  width: 1640px;
}

.signup-registry .middle-section {
  height: 1300px;
  left: 0;
  position: absolute;
  top: 100px;
  width: 1640px;
}

.signup-registry .signup-registry-body {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 800px;
  left: 240px;
  padding: 10px;
  position: absolute;
  top: 0;
  width: 1400px;
}

.signup-registry .registry-title {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 42px;
  padding: 7px 0px;
  position: relative;
  width: 100%;
}

.signup-registry .registry-title-text {
  align-self: stretch;
  color: #333333;
  font-family: "Roboto", Helvetica;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -1.00px;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
}

.signup-registry .form {
  align-items: center;
  align-self: stretch;
  border-color: #bcbcbc;
  border-top-style: solid;
  border-top-width: 3px;
  display: flex;
  flex-direction: column;
  height: 440px;
  justify-content: center;
  padding: 10px 0px;
  position: relative;
  width: 100%;
}

.signup-registry .registry-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 420px;
  justify-content: center;
  position: relative;
  width: 1000px;
}

.signup-registry .div-3 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1.33px;
  border-color: #dddddd;
  display: flex;
  gap: 10px;
  height: 70px;
  padding: 15px 10px;
  position: relative;
  width: 100%;
}

.signup-registry .div-wrapper-2 {
  align-items: center;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  padding: 10.5px 10px;
  position: relative;
  width: 160px;
}

.signup-registry .text-wrapper-6 {
  color: #000000;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.signup-registry .email-edit {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  padding: 10.5px 10px;
  position: relative;
  width: 160px;
}

.signup-registry .email-at {
  align-items: center;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  padding: 10.5px 10px;
  position: relative;
  width: 20px;
}

.signup-registry .text-wrapper-7 {
  color: #000000;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-right: -1.00px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.signup-registry .email-domain {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  padding: 10.5px 10px;
  position: relative;
  width: 140px;
}

.signup-registry .email-btn {
  align-items: center;
  background-color: #163e64;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  padding: 10.5px 10px;
  position: relative;
  width: 120px;
}

.signup-registry .text-wrapper-8 {
  color: #ffffff;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.signup-registry .div-wrapper-3 {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  height: 40px;
  justify-content: center;
  padding: 10.5px 10px;
  position: relative;
}

.signup-registry .text-wrapper-9 {
  color: #000000;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
}

.signup-registry .button-container {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  gap: 10px;
  height: 60px;
  justify-content: center;
  padding: 10px;
  position: relative;
  width: 100%;
}

.signup-registry .link-2 {
  align-items: center;
  background-color: #163e64;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 10.5px 0px;
  position: relative;
  width: 100px;
}

.signup-registry .text-wrapper-10 {
  color: #ffffff;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -3.00px;
  position: relative;
  text-align: center;
}

.signup-registry .link-3 {
  align-items: center;
  background-color: #ffffff;
  border: 2px solid;
  border-color: #cccccc;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 10.5px 0px;
  position: relative;
  width: 100px;
}

.signup-registry .text-wrapper-11 {
  color: #000000;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -2.00px;
  position: relative;
  text-align: center;
}

.signup-registry .left-menu-instance {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 240px !important;
}

.signup-registry .menu-section {
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 40px;
  width: 1640px;
}

.signup-registry .main-logo-instance {
  top: unset !important;
}

.signup-registry .right-menu-instance {
  flex: 0 0 auto !important;
  left: unset !important;
  top: unset !important;
}
