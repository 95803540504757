.link-tree {
  align-items: center;
  display: inline-flex;
  gap: 20px;
  height: 30px;
  left: 130px;
  padding: 0px 10px;
  position: relative;
  top: 150px;
}

.link-tree .item-link {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  height: 30px;
  padding: 7.5px 0px;
  position: relative;
}

.link-tree .symbol-6 {
  color: #333333;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}
