.cache-deposit-mutong {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.cache-deposit-mutong .div-30 {
  background-color: #ffffff;
  height: 1460px;
  position: relative;
  width: 1440px;
}

.cache-deposit-mutong .right-menu-9 {
  left: 1034px !important;
  position: absolute !important;
  top: 0 !important;
}

.cache-deposit-mutong .main-logo-10 {
  left: 131px !important;
  position: absolute !important;
  top: 50px !important;
}

.cache-deposit-mutong .search-area-10 {
  left: 541px !important;
  position: absolute !important;
  top: 50px !important;
}

.cache-deposit-mutong .nav-menu-10 {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.cache-deposit-mutong .link-tree-10 {
  left: 131px !important;
  position: absolute !important;
  top: 150px !important;
}

.cache-deposit-mutong .my-page-menu-instance {
  left: 130px !important;
  position: absolute !important;
  top: 200px !important;
}

.cache-deposit-mutong .my-page-avata-instance {
  left: 370px !important;
  position: absolute !important;
  top: 200px !important;
  width: 930px !important;
}

.cache-deposit-mutong .div-31 {
  height: 810px;
  left: 370px;
  position: absolute;
  top: 400px;
  width: 930px;
}

.cache-deposit-mutong .form-4 {
  height: 756px;
  left: 0;
  position: absolute;
  top: 0;
  width: 930px;
}

.cache-deposit-mutong .div-inner-point-list {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  display: flex;
  flex-direction: column;
  left: 15px;
  padding: 1.25px;
  position: absolute;
  top: 0;
  width: 512px;
}

.cache-deposit-mutong .list-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.cache-deposit-mutong .label-wrapper {
  align-items: flex-start;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: var(--storegpakoreacomalto);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 0px 1.25px;
  position: relative;
  width: 100%;
}

.cache-deposit-mutong .div-32 {
  align-self: stretch;
  height: 50.41px;
  max-width: 510px;
  position: relative;
  width: 100%;
}

.cache-deposit-mutong .symbol-wrapper-4 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 15px;
  padding: 0px 2.16px 1.09px 2.12px;
  position: absolute;
  top: 14px;
  width: 21px;
}

.cache-deposit-mutong .symbol-16 {
  color: #232323;
  font-family: var(--store-gpakorea-com-font-awesome-5-free-solid-17-2-font-family);
  font-size: var(--store-gpakorea-com-font-awesome-5-free-solid-17-2-font-size);
  font-style: var(--store-gpakorea-com-font-awesome-5-free-solid-17-2-font-style);
  font-weight: var(--store-gpakorea-com-font-awesome-5-free-solid-17-2-font-weight);
  letter-spacing: var(--store-gpakorea-com-font-awesome-5-free-solid-17-2-letter-spacing);
  line-height: var(--store-gpakorea-com-font-awesome-5-free-solid-17-2-line-height);
  margin-left: -0.41px;
  margin-right: -0.41px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit-mutong .overlap-group-7 {
  height: 23px;
  left: 45px;
  position: absolute;
  top: 14px;
  width: 185px;
}

.cache-deposit-mutong .text-wrapper-184 {
  color: #505050;
  font-family: var(--store-gpakorea-com-roboto-medium-14-3-font-family);
  font-size: var(--store-gpakorea-com-roboto-medium-14-3-font-size);
  font-style: var(--store-gpakorea-com-roboto-medium-14-3-font-style);
  font-weight: var(--store-gpakorea-com-roboto-medium-14-3-font-weight);
  height: 20px;
  left: 0;
  letter-spacing: var(--store-gpakorea-com-roboto-medium-14-3-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-medium-14-3-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 89px;
}

.cache-deposit-mutong .span-label {
  align-items: flex-start;
  background-color: var(--storegpakoreacomfern);
  border-radius: 2.68px;
  display: inline-flex;
  left: 89px;
  padding: 3.64px 6.43px 4.71px;
  position: absolute;
  top: 4px;
}

.cache-deposit-mutong .text-wrapper-185 {
  color: #ffffff;
  font-family: var(--store-gpakorea-com-roboto-bold-10-7-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-10-7-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-10-7-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-10-7-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-bold-10-7-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-10-7-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit-mutong .symbol-17 {
  color: #d9d9d9;
  font-family: var(--store-gpakorea-com-font-awesome-5-free-regular-17-2-font-family);
  font-size: var(--store-gpakorea-com-font-awesome-5-free-regular-17-2-font-size);
  font-style: var(--store-gpakorea-com-font-awesome-5-free-regular-17-2-font-style);
  font-weight: var(--store-gpakorea-com-font-awesome-5-free-regular-17-2-font-weight);
  letter-spacing: var(--store-gpakorea-com-font-awesome-5-free-regular-17-2-letter-spacing);
  line-height: var(--store-gpakorea-com-font-awesome-5-free-regular-17-2-line-height);
  margin-left: -0.41px;
  margin-right: -0.41px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit-mutong .overlap-group-8 {
  height: 23px;
  left: 45px;
  position: absolute;
  top: 14px;
  width: 191px;
}

.cache-deposit-mutong .overlap-group-9 {
  height: 23px;
  left: 45px;
  position: absolute;
  top: 14px;
  width: 204px;
}

.cache-deposit-mutong .text-wrapper-186 {
  color: #505050;
  font-family: var(--store-gpakorea-com-roboto-medium-14-3-font-family);
  font-size: var(--store-gpakorea-com-roboto-medium-14-3-font-size);
  font-style: var(--store-gpakorea-com-roboto-medium-14-3-font-style);
  font-weight: var(--store-gpakorea-com-roboto-medium-14-3-font-weight);
  height: 20px;
  left: 0;
  letter-spacing: var(--store-gpakorea-com-roboto-medium-14-3-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-medium-14-3-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 102px;
}

.cache-deposit-mutong .span-label-2 {
  align-items: flex-start;
  background-color: var(--storegpakoreacomfern);
  border-radius: 2.68px;
  display: inline-flex;
  left: 102px;
  padding: 3.64px 6.43px 4.71px;
  position: absolute;
  top: 4px;
}

.cache-deposit-mutong .overlap-group-10 {
  height: 23px;
  left: 45px;
  position: absolute;
  top: 14px;
  width: 210px;
}

.cache-deposit-mutong .overlap-53 {
  height: 23px;
  left: 45px;
  position: absolute;
  top: 14px;
  width: 219px;
}

.cache-deposit-mutong .text-wrapper-187 {
  color: #505050;
  font-family: var(--store-gpakorea-com-roboto-medium-14-3-font-family);
  font-size: var(--store-gpakorea-com-roboto-medium-14-3-font-size);
  font-style: var(--store-gpakorea-com-roboto-medium-14-3-font-style);
  font-weight: var(--store-gpakorea-com-roboto-medium-14-3-font-weight);
  height: 20px;
  left: 0;
  letter-spacing: var(--store-gpakorea-com-roboto-medium-14-3-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-medium-14-3-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 111px;
}

.cache-deposit-mutong .span-label-3 {
  align-items: flex-start;
  background-color: var(--storegpakoreacomfern);
  border-radius: 2.68px;
  display: inline-flex;
  left: 111px;
  padding: 3.64px 6.43px 4.71px;
  position: absolute;
  top: 4px;
}

.cache-deposit-mutong .div-inner-pay-table {
  align-items: flex-start;
  background-color: var(--storegpakoreacomcyan-aqua);
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  display: flex;
  flex-direction: column;
  left: 558px;
  padding: 16.25px;
  position: absolute;
  top: 0;
  width: 358px;
}

.cache-deposit-mutong .table-10 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  max-width: 357.5px;
  position: relative;
  width: 100%;
}

.cache-deposit-mutong .body-6 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.cache-deposit-mutong .row-5 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 52.94px;
  padding: 0px 0px 0px 7.98px;
  position: relative;
}

.cache-deposit-mutong .cell-32 {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-medium-14-3-font-family);
  font-size: var(--store-gpakorea-com-roboto-medium-14-3-font-size);
  font-style: var(--store-gpakorea-com-roboto-medium-14-3-font-style);
  font-weight: var(--store-gpakorea-com-roboto-medium-14-3-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-medium-14-3-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-medium-14-3-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit-mutong .data-24 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 8px 7.99px 8px 112.46px;
  position: relative;
}

.cache-deposit-mutong .text-wrapper-188 {
  color: var(--storegpakoreacomgray);
  font-family: var(--store-gpakorea-com-roboto-regular-14-3-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-14-3-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-14-3-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-14-3-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-regular-14-3-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-14-3-line-height);
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit-mutong .row-6 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 35.05px;
  padding: 0px 0px 0px 7.98px;
  position: relative;
}

.cache-deposit-mutong .cell-33 {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-regular-14-3-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-14-3-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-14-3-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-14-3-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-regular-14-3-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-14-3-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit-mutong .data-25 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 8px 7.99px 8px 130.03px;
  position: relative;
}

.cache-deposit-mutong .data-26 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 8px 7.99px 8px 111.54px;
  position: relative;
}

.cache-deposit-mutong .text-wrapper-189 {
  color: var(--storegpakoreacomblack);
  font-family: var(--store-gpakorea-com-roboto-bold-14-3-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-14-3-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-14-3-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-14-3-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-bold-14-3-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-14-3-line-height);
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit-mutong .footer-row-2 {
  height: 120.64px;
  margin-top: -2.84e-14px;
  position: relative;
  width: 325px;
}

.cache-deposit-mutong .cell-34 {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-medium-14-3-font-family);
  font-size: var(--store-gpakorea-com-roboto-medium-14-3-font-size);
  font-style: var(--store-gpakorea-com-roboto-medium-14-3-font-style);
  font-weight: var(--store-gpakorea-com-roboto-medium-14-3-font-weight);
  height: 20px;
  left: 8px;
  letter-spacing: var(--store-gpakorea-com-roboto-medium-14-3-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-medium-14-3-line-height);
  position: absolute;
  top: 39px;
  white-space: nowrap;
  width: 73px;
}

.cache-deposit-mutong .data-27 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 120px;
  padding: 80px 7.99px 8px 83.43px;
  position: absolute;
  top: 0;
}

.cache-deposit-mutong .text-wrapper-190 {
  color: #ff0000;
  font-family: var(--store-gpakorea-com-roboto-medium-22-9-font-family);
  font-size: var(--store-gpakorea-com-roboto-medium-22-9-font-size);
  font-style: var(--store-gpakorea-com-roboto-medium-22-9-font-style);
  font-weight: var(--store-gpakorea-com-roboto-medium-22-9-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-medium-22-9-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-medium-22-9-line-height);
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit-mutong .div-inner-pay-method {
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  height: 91px;
  left: 15px;
  position: absolute;
  top: 455px;
  width: 900px;
}

.cache-deposit-mutong .heading-18 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 16px;
  position: absolute;
  top: 16px;
  width: 868px;
}

.cache-deposit-mutong .text-wrapper-191 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: var(--storegpakoreacomscorpion);
  display: -webkit-box;
  font-family: "Roboto", Helvetica;
  font-size: 18.2px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit-mutong .label-4 {
  align-items: flex-start;
  display: inline-flex;
  left: 16px;
  max-width: 900px;
  padding: 0px 0px 0.54px 20px;
  position: absolute;
  top: 56px;
}

.cache-deposit-mutong .span-fas-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 17px;
  left: 0;
  padding: 0px 1.95px 0.94px 1.93px;
  position: absolute;
  top: 1px;
  width: 19px;
}

.cache-deposit-mutong .symbol-18 {
  color: #232323;
  font-family: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-font-family);
  font-size: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-font-size);
  font-style: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-font-style);
  font-weight: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-font-weight);
  letter-spacing: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-line-height);
  margin-left: -0.2px;
  margin-right: -0.2px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit-mutong .text-wrapper-192 {
  color: #505050;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit-mutong .label-5 {
  align-items: flex-start;
  display: inline-flex;
  left: 306px;
  max-width: 900px;
  padding: 0px 0px 0.54px 20px;
  position: absolute;
  top: 56px;
}

.cache-deposit-mutong .span-far-4 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 17px;
  left: 0;
  padding: 0px 1.94px 0.94px;
  position: absolute;
  top: 1px;
  width: 19px;
}

.cache-deposit-mutong .symbol-19 {
  color: #d9d9d9;
  font-family: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-font-family);
  font-size: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-font-size);
  font-style: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-font-style);
  font-weight: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-font-weight);
  letter-spacing: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-line-height);
  margin-left: -0.2px;
  margin-right: -0.2px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit-mutong .div-panel-dbank-2 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  border-radius: 4px;
  box-shadow: 0px 1px 1px #0000000d;
  display: flex;
  flex-direction: column;
  gap: 1.63e-13px;
  left: 15px;
  padding: 1.25px;
  position: absolute;
  top: 566px;
  width: 900px;
}

.cache-deposit-mutong .div-panel-heading-4 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--storegpakoreacomwild-sand);
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: var(--storegpakoreacomalto);
  border-radius: 3px 3px 0px 0px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 10px 15px 11.25px;
  position: relative;
  width: 100%;
}

.cache-deposit-mutong .text-wrapper-193 {
  align-self: stretch;
  color: #333333;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1.25px;
  position: relative;
}

.cache-deposit-mutong .div-panel-body-11 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 14.47px 15px 15.01px;
  position: relative;
  width: 100%;
}

.cache-deposit-mutong .div-33 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 0px 0.54px;
  position: relative;
  width: 100%;
}

.cache-deposit-mutong .text-wrapper-194 {
  align-self: stretch;
  color: #ff0000;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
}

.cache-deposit-mutong .table-11 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  max-width: 897.5px;
  padding: 0.63px 0px 0.62px;
  position: relative;
  width: 100%;
}

.cache-deposit-mutong .body-row-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.14e-13px;
  position: relative;
}

.cache-deposit-mutong .cell-35 {
  align-items: flex-start;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24.03px 8px 25.12px;
  position: relative;
  width: 200px;
}

.cache-deposit-mutong .text-wrapper-195 {
  color: #212121;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1.25px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit-mutong .data-28 {
  align-items: flex-start;
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 15.62px 473.04px 15.63px 7.98px;
  position: relative;
}

.cache-deposit-mutong .div-input-group-10 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 1.25px;
  position: relative;
}

.cache-deposit-mutong .input-10 {
  align-self: stretch;
  background-color: #ffffff;
  height: 33.98px;
  position: relative;
  width: 100%;
}

.cache-deposit-mutong .div-text-right-3 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 15px;
  position: absolute;
  top: 776px;
  width: 900px;
}

.cache-deposit-mutong .link-48 {
  align-items: center;
  background-color: var(--storegpakoreacomconifer);
  border: 1px solid;
  border-color: var(--storegpakoreacomconifer);
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  padding: 7.25px 13.25px;
  position: relative;
}

.cache-deposit-mutong .text-wrapper-196 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.cache-deposit-mutong .footer-panel-9 {
  align-items: center;
  background-color: #606060;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 1300px;
  width: 1440px;
}

.cache-deposit-mutong .footer-frame-10 {
  height: 160px;
  position: relative;
  width: 1160px;
}

.cache-deposit-mutong .company-infos-8 {
  height: 72px;
  left: 213px;
  position: absolute;
  top: 43px;
  width: 683px;
}

.cache-deposit-mutong .flexcontainer-9 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 72px;
  position: relative;
  width: 681px;
}

.cache-deposit-mutong .text-i-10 {
  align-self: stretch;
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
  position: relative;
}

.cache-deposit-mutong .text-wrapper-197 {
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
}

.cache-deposit-mutong .sns-link-10 {
  height: 40px;
  left: 949px;
  position: absolute;
  top: 60px;
  width: 200px;
}

.cache-deposit-mutong .list-item-link-43 {
  background-image: url(../../../static/img/list-item-link.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 46px;
}

.cache-deposit-mutong .text-wrapper-198 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 40px;
}

.cache-deposit-mutong .list-item-link-44 {
  background-image: url(../../../static/img/list-item-link-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 51px;
  position: absolute;
  top: 0;
  width: 46px;
}

.cache-deposit-mutong .list-item-link-45 {
  background-image: url(../../../static/img/list-item-link-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 103px;
  position: absolute;
  top: 0;
  width: 46px;
}

.cache-deposit-mutong .text-wrapper-199 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 53px;
}

.cache-deposit-mutong .list-item-link-46 {
  background-image: url(../../../static/img/list-item-link-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 154px;
  position: absolute;
  top: 0;
  width: 46px;
}

.cache-deposit-mutong .text-wrapper-200 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 66px;
}

.cache-deposit-mutong .logo-gothic-10 {
  height: 111px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 25px;
  width: 145px;
}

.cache-deposit-mutong .kakao-service-10 {
  left: 0 !important;
  position: absolute !important;
  top: 200px !important;
}

.cache-deposit-mutong .jimmed-products-10 {
  left: 1310px !important;
  position: absolute !important;
  top: 560px !important;
}

.cache-deposit-mutong .viewed-products-10 {
  left: 1310px !important;
  position: absolute !important;
  top: 200px !important;
}
