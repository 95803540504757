.body-page {
  background-color: #ffffff;
  height: 29px;
  /* left: 510px; */
  position: relative;
  /* top: 1746px; */
  width: 340px;
}

.body-page .nav-link {
  border: 1px solid;
  border-color: #cccccc;
  height: 29px;
  left: 0;
  position: absolute;
  top: 0;
  width: 40px;
}

.body-page .page-prev-gif {
  background-image: url(../../../static/img/page-prev-gif.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 7px;
  left: 17px;
  position: relative;
  top: 11px;
  width: 5px;
}

.body-page .div-wrapper {
  align-items: center;
  border: 1px solid;
  border-color: #222222;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 50px;
  padding: 4px 0px;
  position: absolute;
  top: 0;
  width: 40px;
}

.body-page .text-wrapper-3 {
  color: #666666;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.body-page .nav-link-2 {
  align-items: center;
  border: 1px solid;
  border-color: #cccccc;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 100px;
  padding: 4px 0px;
  position: absolute;
  top: 0;
  width: 40px;
}

.body-page .nav-link-3 {
  align-items: center;
  border: 1px solid;
  border-color: #cccccc;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 150px;
  padding: 4px 0px;
  position: absolute;
  top: 0;
  width: 40px;
}

.body-page .nav-link-4 {
  align-items: center;
  border: 1px solid;
  border-color: #cccccc;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 200px;
  padding: 4px 0px;
  position: absolute;
  top: 0;
  width: 40px;
}

.body-page .nav-link-5 {
  align-items: center;
  border: 1px solid;
  border-color: #cccccc;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 250px;
  padding: 4px 0px;
  position: absolute;
  top: 0;
  width: 40px;
}

.body-page .page-next-gif-wrapper {
  border: 1px solid;
  border-color: #cccccc;
  height: 29px;
  left: 300px;
  position: absolute;
  top: 0;
  width: 40px;
}

.body-page .page-next-gif {
  background-image: url(../../../static/img/page-next-gif.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 7px;
  left: 18px;
  position: relative;
  top: 11px;
  width: 5px;
}
