.purchase-card {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.purchase-card .div-10 {
  background-color: #ffffff;
  height: 1880px;
  position: relative;
  width: 1440px;
}

.purchase-card .right-menu-2 {
  left: 1034px !important;
  position: absolute !important;
  top: 0 !important;
}

.purchase-card .main-logo-3 {
  left: 131px !important;
  position: absolute !important;
  top: 50px !important;
}

.purchase-card .search-area-3 {
  left: 541px !important;
  position: absolute !important;
  top: 50px !important;
}

.purchase-card .nav-menu-3 {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.purchase-card .link-tree-3 {
  left: 131px !important;
  position: absolute !important;
  top: 150px !important;
}

.purchase-card .div-11 {
  height: 1274px;
  left: 130px;
  position: absolute;
  top: 200px;
  width: 1170px;
}

.purchase-card .heading-6 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 15px;
  padding: 0px 520.09px 1.16px 520.07px;
  position: absolute;
  top: -1px;
  width: 1140px;
}

.purchase-card .text-wrapper-78 {
  color: #212121;
  font-family: "Roboto", Helvetica;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28.6px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.purchase-card .form-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  left: 15px;
  position: absolute;
  top: 109px;
  width: 1140px;
}

.purchase-card .div-panel {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  box-shadow: 0px 1px 1px #0000000d;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 1.25px;
  position: relative;
  width: 1140px;
}

.purchase-card .div-panel-body-4 {
  align-self: stretch;
  height: 572.81px;
  position: relative;
  width: 100%;
}

.purchase-card .div-item-data-2 {
  height: 150px;
  left: 15px;
  position: absolute;
  top: 15px;
  width: 1108px;
}

.purchase-card .div-image-4 {
  align-items: flex-start;
  background-image: url(../../../static/img/div-image-3.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 200px;
}

.purchase-card .div-img-2 {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  object-fit: cover;
  position: relative;
}

.purchase-card .div-info-2 {
  align-items: flex-start;
  display: inline-flex;
  left: 200px;
  padding: 0px 0px 0px 20px;
  position: absolute;
  top: -1px;
}

.purchase-card .heading-7 {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 0px 126.27px;
  position: relative;
}

.purchase-card .text-wrapper-79 {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-medium-15-6-font-family);
  font-size: var(--store-gpakorea-com-roboto-medium-15-6-font-size);
  font-style: var(--store-gpakorea-com-roboto-medium-15-6-font-style);
  font-weight: var(--store-gpakorea-com-roboto-medium-15-6-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-medium-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-medium-15-6-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-card .table-4 {
  align-items: flex-start;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 15px;
  max-width: 1137.5px;
  padding: 0.63px 0px 0px;
  position: absolute;
  top: 195px;
}

.purchase-card .div-12 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.purchase-card .cell-12 {
  align-items: flex-start;
  background-color: var(--storegpakoreacomalto);
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  padding: 8.07px 779.6px 8.53px 7.98px;
  position: relative;
}

.purchase-card .text-wrapper-80 {
  color: #646464;
  font-family: var(--store-gpakorea-com-roboto-bold-13-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-13-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-13-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-13-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-bold-13-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-13-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-card .cell-13 {
  align-items: flex-start;
  background-color: var(--storegpakoreacomalto);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8.07px 42.53px 8.53px 42.51px;
  position: relative;
  width: 110px;
}

.purchase-card .text-wrapper-81 {
  color: #646464;
  font-family: var(--store-gpakorea-com-roboto-bold-13-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-13-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-13-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-13-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-bold-13-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-13-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.purchase-card .cell-14 {
  align-items: flex-start;
  background-color: var(--storegpakoreacomalto);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8.07px 7.99px 8.53px 127.05px;
  position: relative;
  width: 160px;
}

.purchase-card .text-wrapper-82 {
  color: #646464;
  font-family: var(--store-gpakorea-com-roboto-bold-13-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-13-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-13-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-13-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-bold-13-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-13-line-height);
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.purchase-card .div-13 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  margin-top: -0.01px;
  position: relative;
}

.purchase-card .data-7 {
  align-items: flex-start;
  background-color: #ffffff;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 0.01px;
  padding: 30.01px 8px 30.56px;
  position: relative;
}

.purchase-card .p-pkg-summary-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 0px 0.54px;
  position: relative;
  width: 100%;
}

.purchase-card .text-wrapper-83 {
  color: #646464;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-card .data-8 {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 8px;
  position: relative;
  width: 110px;
}

.purchase-card .div-box-qty-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 30px 42.14px 30px 42.13px;
  position: relative;
  width: 100%;
}

.purchase-card .text-wrapper-84 {
  color: #646464;
  font-family: var(--store-gpakorea-com-roboto-extrabold-15-6-font-family);
  font-size: var(--store-gpakorea-com-roboto-extrabold-15-6-font-size);
  font-style: var(--store-gpakorea-com-roboto-extrabold-15-6-font-style);
  font-weight: var(--store-gpakorea-com-roboto-extrabold-15-6-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-extrabold-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-extrabold-15-6-line-height);
  margin-left: -0.13px;
  margin-right: -0.14px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.purchase-card .link-21 {
  align-items: center;
  background-color: #555555;
  border-radius: 26px;
  display: flex;
  flex-direction: column;
  height: 26px;
  left: 0;
  padding: 0px 10.08px 0px 10.06px;
  position: absolute;
  top: 28px;
  width: 26px;
}

.purchase-card .text-wrapper-85 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 26px;
  margin-left: -0.07px;
  margin-right: -0.07px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.purchase-card .link-22 {
  align-items: center;
  background-color: #555555;
  border-radius: 26px;
  display: flex;
  flex-direction: column;
  height: 26px;
  left: 68px;
  padding: 0px 9.13px 0px 9.12px;
  position: absolute;
  top: 28px;
  width: 26px;
}

.purchase-card .text-wrapper-86 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 26px;
  margin-left: -0.12px;
  margin-right: -0.12px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.purchase-card .data-9 {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 39.31px 7.99px 40.38px 95.62px;
  position: relative;
  width: 160px;
}

.purchase-card .text-wrapper-87 {
  color: #646464;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.purchase-card .table-5 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 15px;
  max-width: 1137.5px;
  padding: 0.62px 0px 0.63px;
  position: absolute;
  top: 369px;
}

.purchase-card .body-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.purchase-card .cell-15 {
  align-items: flex-start;
  background-color: #f7f7f7;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 37.04px 8px 38.13px;
  position: relative;
  width: 350px;
}

.purchase-card .text-wrapper-88 {
  color: #212121;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1.25px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-card .data-10 {
  align-items: flex-start;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 16.25px 8px 15px;
  position: relative;
}

.purchase-card .textarea-wrapper {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 1.25px;
  position: relative;
  width: 100%;
}

.purchase-card .textarea-2 {
  align-self: stretch;
  background-color: #ffffff;
  height: 60px;
  position: relative;
  width: 100%;
}

.purchase-card .cell-16 {
  align-items: flex-start;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 27.77px 8px 28.84px;
  position: relative;
  width: 350px;
}

.purchase-card .element-2 {
  color: #212121;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1.25px;
  position: relative;
  width: fit-content;
}

.purchase-card .data-11 {
  align-items: flex-start;
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 15.63px 8px 15.62px;
  position: relative;
}

.purchase-card .textarea-3 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 60px;
  padding: 7.36px 8px 32.02px;
  position: relative;
  width: 100%;
}

.purchase-card .div-placeholder-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 0px 0.62px;
  position: relative;
  width: 100%;
}

.purchase-card .text-wrapper-89 {
  align-self: stretch;
  color: var(--storegpakoreacomdusty-gray);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
}

.purchase-card .div-panel-pay {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  box-shadow: 0px 1px 1px #0000000d;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.53e-14px;
  padding: 1.25px;
  position: relative;
  width: 1140px;
}

.purchase-card .div-panel-heading-2 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--storegpakoreacomwild-sand);
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: var(--storegpakoreacomalto);
  border-radius: 3px 3px 0px 0px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 10px 15px 11.25px;
  position: relative;
  width: 100%;
}

.purchase-card .text-wrapper-90 {
  align-self: stretch;
  color: #333333;
  font-family: var(--store-gpakorea-com-roboto-regular-14-3-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-14-3-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-14-3-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-14-3-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-regular-14-3-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-14-3-line-height);
  margin-top: -1.25px;
  position: relative;
}

.purchase-card .div-panel-body-5 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--storegpakoreacomcyan-aqua);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 25px 0px;
  position: relative;
  width: 100%;
}

.purchase-card .div-row-2 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 1.14e-13px;
  justify-content: center;
  position: relative;
  width: 1137.5px;
}

.purchase-card .div-col-sm-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  min-height: 1px;
  padding: 35px 15px 1.14e-13px;
  position: relative;
}

.purchase-card .div-box-coupon-2 {
  height: 34.49px;
  position: relative;
  width: 538.75px;
}

.purchase-card .div-14 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 8px;
}

.purchase-card .heading-8 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 5.99px;
  padding: 0px 0px 1.07px;
  position: relative;
  width: 100%;
}

.purchase-card .text-wrapper-91 {
  color: #555555;
  font-family: "Roboto", Helvetica;
  font-size: 15.6px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 17.2px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-card .text-wrapper-92 {
  color: #ff0000;
  font-family: "Roboto", Helvetica;
  font-size: 15.6px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 17.2px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-card .link-23 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #cccccc;
  border-radius: 4px;
  display: inline-flex;
  left: 458px;
  padding: 7.25px 13.25px;
  position: absolute;
  top: 0;
}

.purchase-card .text-wrapper-93 {
  color: #333333;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.purchase-card .div-box-point-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.purchase-card .heading-9 {
  height: 17.15px;
  position: relative;
  width: 538.75px;
}

.purchase-card .text-wrapper-94 {
  color: #555555;
  font-family: "Roboto", Helvetica;
  font-size: 15.6px;
  font-weight: 700;
  height: 17px;
  left: 0;
  letter-spacing: 0;
  line-height: 17.2px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 69px;
}

.purchase-card .small-4 {
  color: #555555;
  font-family: var(--store-gpakorea-com-roboto-regular-13-3-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-13-3-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-13-3-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-13-3-font-weight);
  height: 13px;
  left: 75px;
  letter-spacing: var(--store-gpakorea-com-roboto-regular-13-3-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-13-3-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 89px;
}

.purchase-card .div-input-group-5 {
  align-items: flex-start;
  background-color: #ffffff;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}

.purchase-card .input-6 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  border-radius: 4px 0px 0px 4px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 33.98px;
  padding: 7.25px 13.25px;
  position: relative;
  z-index: 1;
}

.purchase-card .div-15 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow: scroll;
  position: relative;
  width: 100%;
}

.purchase-card .text-wrapper-95 {
  color: #555555;
  font-family: var(--store-gpakorea-com-roboto-regular-14-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-14-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-14-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-14-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-regular-14-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-14-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-card .span-input-group-btn-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  margin-left: -1.14e-13px;
  padding: 0px 0.01px 0px 9px;
  position: relative;
  z-index: 0;
}

.purchase-card .link-24 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #cccccc;
  border-radius: 0px 4px 4px 0px;
  display: inline-flex;
  flex: 0 0 auto;
  padding: 7.25px 13.25px;
  position: relative;
}

.purchase-card .div-col-sm-3 {
  align-items: flex-start;
  border-color: var(--storegpakoreacomalto);
  border-left-style: solid;
  border-left-width: 1.25px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  min-height: 1px;
  padding: 0px 15px 0px 16.25px;
  position: relative;
}

.purchase-card .table-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  max-width: 568.75px;
  position: relative;
  width: 100%;
}

.purchase-card .row-2 {
  height: 38.26px;
  position: relative;
  width: 537.5px;
}

.purchase-card .cell-17 {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-bold-15-6-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-15-6-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-15-6-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-15-6-font-weight);
  height: 22px;
  left: 8px;
  letter-spacing: var(--store-gpakorea-com-roboto-bold-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-15-6-line-height);
  position: absolute;
  top: 6px;
  white-space: nowrap;
  width: 99px;
}

.purchase-card .data-12 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 252px;
  padding: 9.31px 8px 9.85px;
  position: absolute;
  top: 0;
}

.purchase-card .div-wrapper-5 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 0px 0.54px;
  position: relative;
  width: 100%;
}

.purchase-card .text-wrapper-96 {
  color: #212121;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.purchase-card .cell-18 {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-regular-15-6-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-15-6-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-15-6-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-15-6-font-weight);
  height: 22px;
  left: 8px;
  letter-spacing: var(--store-gpakorea-com-roboto-regular-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-15-6-line-height);
  position: absolute;
  top: 6px;
  white-space: nowrap;
  width: 50px;
}

.purchase-card .cell-19 {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-bold-15-6-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-15-6-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-15-6-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-15-6-font-weight);
  height: 22px;
  left: 8px;
  letter-spacing: var(--store-gpakorea-com-roboto-bold-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-15-6-line-height);
  position: absolute;
  top: 6px;
  white-space: nowrap;
  width: 45px;
}

.purchase-card .data-13 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 252px;
  padding: 9.31px 8px 9.84px;
  position: absolute;
  top: 0;
}

.purchase-card .cell-20 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 1.14px;
  padding: 28.85px 8px 7.98px;
  position: relative;
}

.purchase-card .text-wrapper-97 {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-bold-15-6-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-15-6-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-15-6-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-15-6-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-bold-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-15-6-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-card .small-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.purchase-card .text-wrapper-98 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 0;
  color: #212121;
  display: -webkit-box;
  font-family: var(--store-gpakorea-com-roboto-bold-13-3-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-13-3-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-13-3-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-13-3-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-bold-13-3-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-13-3-line-height);
  margin-top: -1px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
}

.purchase-card .data-14 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-left: -0.01px;
  padding: 32.05px 8px 10.04px;
  position: relative;
}

.purchase-card .p-total-amount-2 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.purchase-card .text-wrapper-99 {
  color: #ff0000;
  font-family: var(--store-gpakorea-com-roboto-bold-26-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-26-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-26-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-26-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-bold-26-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-26-line-height);
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.purchase-card .div-panel-pay-method-2 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  box-shadow: 0px 1px 1px #0000000d;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 0.01px;
  padding: 1.25px;
  position: relative;
  width: 1140px;
}

.purchase-card .text-wrapper-100 {
  align-self: stretch;
  color: #333333;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1.25px;
  position: relative;
}

.purchase-card .div-panel-body-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 18.8px;
  padding: 14.47px 15px 15px;
  position: relative;
  width: 100%;
}

.purchase-card .label-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  max-width: 1137.5px;
  padding: 0px 0px 0.54px 20px;
  position: relative;
}

.purchase-card .symbol-wrapper-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 17px;
  left: 0;
  padding: 0px 1.95px 0.94px 1.93px;
  position: absolute;
  top: 1px;
  width: 19px;
}

.purchase-card .symbol-10 {
  color: #d9d9d9;
  font-family: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-font-family);
  font-size: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-font-size);
  font-style: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-font-style);
  font-weight: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-font-weight);
  letter-spacing: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-line-height);
  margin-left: -0.2px;
  margin-right: -0.2px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.purchase-card .text-wrapper-101 {
  color: #505050;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-card .symbol-11 {
  color: #232323;
  font-family: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-font-family);
  font-size: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-font-size);
  font-style: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-font-style);
  font-weight: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-font-weight);
  letter-spacing: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-line-height);
  margin-left: -0.2px;
  margin-right: -0.2px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.purchase-card .span-far-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 17px;
  left: 0;
  padding: 0px 1.94px 0.94px;
  position: absolute;
  top: 1px;
  width: 19px;
}

.purchase-card .link-25 {
  background-color: var(--storegpakoreacomconifer);
  border: 1px solid;
  border-color: var(--storegpakoreacomconifer);
  border-radius: 4px;
  height: 52px;
  left: 15px;
  position: absolute;
  top: 1221px;
  width: 1140px;
}

.purchase-card .overlap-group-4 {
  height: 26px;
  left: 522px;
  position: relative;
  top: 12px;
  width: 96px;
}

.purchase-card .symbol-12 {
  color: #ffffff;
  font-family: var(--store-gpakorea-com-font-awesome-5-free-solid-18-2-font-family);
  font-size: var(--store-gpakorea-com-font-awesome-5-free-solid-18-2-font-size);
  font-style: var(--store-gpakorea-com-font-awesome-5-free-solid-18-2-font-style);
  font-weight: var(--store-gpakorea-com-font-awesome-5-free-solid-18-2-font-weight);
  height: 18px;
  left: 0;
  letter-spacing: var(--store-gpakorea-com-font-awesome-5-free-solid-18-2-letter-spacing);
  line-height: var(--store-gpakorea-com-font-awesome-5-free-solid-18-2-line-height);
  position: absolute;
  text-align: center;
  top: 5px;
  white-space: nowrap;
  width: 21px;
}

.purchase-card .text-wrapper-102 {
  color: #ffffff;
  font-family: var(--store-gpakorea-com-roboto-regular-18-2-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-18-2-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-18-2-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-18-2-font-weight);
  height: 26px;
  left: 20px;
  letter-spacing: var(--store-gpakorea-com-roboto-regular-18-2-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-18-2-line-height);
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 75px;
}

.purchase-card .footer-panel-instance {
  align-items: center;
  background-color: #606060;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 1720px;
  width: 1440px;
}

.purchase-card .footer-frame-3 {
  height: 160px;
  position: relative;
  width: 1160px;
}

.purchase-card .flexcontainer-i-wrapper {
  height: 72px;
  left: 213px;
  position: absolute;
  top: 43px;
  width: 683px;
}

.purchase-card .flexcontainer-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 72px;
  position: relative;
  width: 681px;
}

.purchase-card .text-i-3 {
  align-self: stretch;
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
  position: relative;
}

.purchase-card .text-wrapper-103 {
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
}

.purchase-card .sns-link-3 {
  height: 40px;
  left: 949px;
  position: absolute;
  top: 60px;
  width: 200px;
}

.purchase-card .list-item-link-9 {
  background-image: url(../../../static/img/list-item-link.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 46px;
}

.purchase-card .text-wrapper-104 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 40px;
}

.purchase-card .list-item-link-10 {
  background-image: url(../../../static/img/list-item-link-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 51px;
  position: absolute;
  top: 0;
  width: 46px;
}

.purchase-card .list-item-link-11 {
  background-image: url(../../../static/img/list-item-link-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 103px;
  position: absolute;
  top: 0;
  width: 46px;
}

.purchase-card .text-wrapper-105 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 53px;
}

.purchase-card .list-item-link-12 {
  background-image: url(../../../static/img/list-item-link-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 154px;
  position: absolute;
  top: 0;
  width: 46px;
}

.purchase-card .text-wrapper-106 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 66px;
}

.purchase-card .logo-gothic-3 {
  height: 111px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 25px;
  width: 145px;
}

.purchase-card .kakao-service-3 {
  left: 0 !important;
  position: absolute !important;
  top: 200px !important;
}

.purchase-card .jimmed-products-3 {
  left: 1310px !important;
  position: absolute !important;
  top: 560px !important;
}

.purchase-card .viewed-products-3 {
  left: 1310px !important;
  position: absolute !important;
  top: 200px !important;
}
