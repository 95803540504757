.my-page-avata {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #0000001a;
  display: flex;
  gap: 20px;
  height: 200px;
  padding: 40px;
  position: relative;
  width: 900px;
}

.my-page-avata .div-profile-image {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 13.09px 0px 13.1px;
  position: relative;
  width: 70px;
}

.my-page-avata .div-image {
  align-self: stretch;
  height: 70px;
  position: relative;
  width: 100%;
}

.my-page-avata .text-wrapper-14 {
  color: #212121;
  font-family: "Roboto", Helvetica;
  font-size: 18.2px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.my-page-avata .div-my-point {
  border-color: var(--storegpakoreacomalto);
  border-left-style: solid;
  border-left-width: 1.25px;
  height: 96.19px;
  position: relative;
  width: 260.14px;
}

.my-page-avata .small {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 41px;
  padding: 0px 82.6px 0.54px 82.58px;
  position: absolute;
  top: -1px;
  width: 219px;
}

.my-page-avata .text-wrapper-15 {
  color: #888888;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.my-page-avata .overlap-group {
  height: 68px;
  left: 41px;
  position: absolute;
  top: 29px;
  width: 219px;
}

.my-page-avata .strong-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 101.33px;
  position: absolute;
  top: 0;
  width: 219px;
}

.my-page-avata .text-wrapper-16 {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-bold-26-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-26-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-26-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-26-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-bold-26-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-26-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.my-page-avata .link-3 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomsilver);
  border-radius: 3px;
  display: inline-flex;
  left: 73px;
  padding: 6.25px 11.25px;
  position: absolute;
  top: 37px;
}

.my-page-avata .text-wrapper-17 {
  color: #333333;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}
