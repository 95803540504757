.signup-select {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.signup-select .div-6 {
  background-color: #ffffff;
  height: 1460px;
  position: relative;
  width: 1440px;
}

.signup-select .right-menu-4 {
  left: 1034px !important;
  position: absolute !important;
  top: 0 !important;
}

.signup-select .main-logo-4 {
  left: 131px !important;
  position: absolute !important;
  width: 185px !important;
}

.signup-select .search-area-4 {
  left: 541px !important;
  position: absolute !important;
}

.signup-select .design-component-instance-node-7 {
  left: 0 !important;
  position: absolute !important;
}

.signup-select .link-tree-4 {
  left: 131px !important;
  position: absolute !important;
  width: 906px !important;
}

.signup-select .login-text-2 {
  height: 145px;
  left: 330px;
  position: absolute;
  top: 200px;
  width: 780px;
}

.signup-select .login-title-2 {
  border: 1px solid;
  border-color: #ffffff;
  height: 29px;
  left: 300px;
  position: absolute;
  top: 0;
  width: 180px;
}

.signup-select .text-wrapper-33 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 26px;
  font-weight: 500;
  height: 29px;
  left: 0;
  letter-spacing: 0;
  line-height: 28.6px;
  position: absolute;
  text-align: center;
  top: -1px;
  width: 180px;
}

.signup-select .flexcontainer-wrapper {
  height: 65px;
  left: 0;
  position: absolute;
  top: 80px;
  width: 780px;
}

.signup-select .flexcontainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 65px;
  position: relative;
  top: -1px;
  width: 780px;
}

.signup-select .text {
  align-self: stretch;
  color: #000000;
  font-family: "Noto Sans KR-Medium", Helvetica;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 21px;
  position: relative;
  text-align: center;
}

.signup-select .text-wrapper-34 {
  letter-spacing: -0.21px;
}

.signup-select .signup-frame {
  height: 282px;
  left: 530px;
  position: absolute;
  top: 460px;
  width: 380px;
}

.signup-select .chagaun-signup {
  align-items: center;
  background-color: #c97f7f;
  border: 1px solid;
  border-color: #00000080;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 0;
  overflow: hidden;
  padding: 16px;
  position: absolute;
  top: 0;
  width: 380px;
}

.signup-select .img-2 {
  height: 25px;
  object-fit: cover;
  position: relative;
  width: 25px;
}

.signup-select .text-wrapper-35 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  position: relative;
  text-align: center;
  width: 310px;
}

.signup-select .naver-signup {
  align-items: center;
  background-color: #c5ec2f;
  border: 1px solid;
  border-color: #00000080;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 0;
  overflow: hidden;
  padding: 16px;
  position: absolute;
  top: 73px;
  width: 380px;
}

.signup-select .kakao-signup {
  align-items: center;
  background-color: #ffdc00;
  border: 1px solid;
  border-color: #00000080;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 0;
  overflow: hidden;
  padding: 16px;
  position: absolute;
  top: 148px;
  width: 380px;
}

.signup-select .google-signup {
  align-items: center;
  background-color: #cad9ed;
  border: 1px solid;
  border-color: #00000080;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 0;
  overflow: hidden;
  padding: 16px;
  position: absolute;
  top: 225px;
  width: 380px;
}

.signup-select .footer-panel-4 {
  left: 0 !important;
  position: absolute !important;
  top: 1300px !important;
  width: 1440px !important;
}

.signup-select .design-component-instance-node-8 {
  position: absolute !important;
  width: 130px !important;
}
