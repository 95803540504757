.forgery-detail {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.forgery-detail .div-10 {
  background-color: #ffffff;
  height: 3400px;
  position: relative;
  width: 1640px;
}

.forgery-detail .main-logo-4 {
  left: 0 !important;
  position: absolute !important;
  top: 40px !important;
}

.forgery-detail .right-menu-5 {
  position: absolute !important;
}

.forgery-detail .main-frame-3 {
  align-items: center;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  left: 240px;
  padding: 10px;
  position: absolute;
  top: 100px;
  width: 1400px;
}

.forgery-detail .body-title-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.forgery-detail .title-5 {
  color: #000000;
  flex: 1;
  font-family: "NanumGothic-Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 25px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
}

.forgery-detail .section-frame-11 {
  align-items: center;
  display: flex;
  height: 610px;
  justify-content: space-between;
  position: relative;
  width: 1380px;
}

.forgery-detail .frame-44 {
  height: 610px;
  position: relative;
  width: 690px;
}

.forgery-detail .frame-45 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #aaaaaa;
  border-right-style: solid;
  border-right-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 610px;
  left: 80px;
  padding: 10px;
  position: absolute;
  top: 0;
  width: 610px;
}

.forgery-detail .div-image-2 {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.forgery-detail .frame-46 {
  border: 1px solid;
  border-color: #aaaaaa;
  height: 610px;
  left: 0;
  position: absolute;
  top: 0;
  width: 80px;
}

.forgery-detail .frame-47 {
  background-image: url(https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/665935372ad594dd39825363/img/div-image-2@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 60px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 60px;
}

.forgery-detail .frame-48 {
  background-image: url(https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/665935372ad594dd39825363/img/div-image-2@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 60px;
  left: 10px;
  position: absolute;
  top: 80px;
  width: 60px;
}

.forgery-detail .frame-49 {
  background-image: url(https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/665935372ad594dd39825363/img/div-image-2@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 60px;
  left: 10px;
  position: absolute;
  top: 150px;
  width: 60px;
}

.forgery-detail .frame-50 {
  background-image: url(https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/665935372ad594dd39825363/img/div-image-2@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 60px;
  left: 10px;
  position: absolute;
  top: 220px;
  width: 60px;
}

.forgery-detail .frame-51 {
  background-image: url(https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/665935372ad594dd39825363/img/div-image-2@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 60px;
  left: 10px;
  position: absolute;
  top: 290px;
  width: 60px;
}

.forgery-detail .frame-52 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #aaaaaa;
  border-right-style: solid;
  border-right-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex-direction: column;
  height: 610px;
  padding: 10px 10px 10px 20px;
  position: relative;
  width: 690px;
}

.forgery-detail .frame-53 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.forgery-detail .text-wrapper-26 {
  color: #000000;
  flex: 1;
  font-family: "Roboto-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.forgery-detail .text-wrapper-27 {
  color: #000000;
  flex: 1;
  font-family: "Roboto-Regular", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.forgery-detail .frame-54 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 80px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.forgery-detail .text-wrapper-28 {
  align-self: stretch;
  color: #000000;
  flex: 1;
  font-family: "Roboto-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
}

.forgery-detail .section-frame-12 {
  align-items: flex-start;
  border: 1px solid;
  border-color: #aaaaaa;
  display: flex;
  flex-direction: column;
  height: 155px;
  padding: 10px;
  position: relative;
  width: 1380px;
}

.forgery-detail .frame-55 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.forgery-detail .text-wrapper-29 {
  color: #0075ff;
  flex: 1;
  font-family: "Roboto-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.forgery-detail .frame-56 {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 45px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.forgery-detail .frame-57 {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #aaaaaa;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
}

.forgery-detail .text-wrapper-30 {
  color: #000000;
  flex: 1;
  font-family: "Roboto-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.forgery-detail .frame-58 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #aaaaaa;
  border-right-style: solid;
  border-right-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
}

.forgery-detail .frame-59 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #aaaaaa;
  border-left-style: solid;
  border-left-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
}

.forgery-detail .frame-60 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #aaaaaa;
  border-right-style: solid;
  border-right-width: 1px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
}

.forgery-detail .frame-61 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 45px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.forgery-detail .section-frame-13 {
  align-items: flex-start;
  border: 1px solid;
  border-color: #aaaaaa;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 10px;
  position: relative;
  width: 1380px;
}

.forgery-detail .section-frame-14 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 0px 0px;
  position: relative;
  width: 1380px;
}

.forgery-detail .rec-item-frame-3 {
  align-items: center;
  display: flex;
  position: relative;
  width: 1380px;
}

.forgery-detail .div-11 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 700px;
  position: relative;
  width: 690px;
}

.forgery-detail .thumbnail-5 {
  align-self: stretch;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #aaaaaa;
  height: 580px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.forgery-detail .overlap-group-4 {
  height: 580px;
  position: relative;
  width: 690px;
}

.forgery-detail .back-image-frame-2 {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 0;
  padding: 10px;
  position: absolute;
  top: 0;
  width: 690px;
}

.forgery-detail .back-image-3 {
  height: 560px;
  position: relative;
  width: 670px;
}

.forgery-detail .button-5 {
  all: unset;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 28px;
  left: 640px;
  opacity: 0.8;
  position: absolute;
  top: 20px;
  width: 28px;
}

.forgery-detail .button-box-4 {
  color: #888888;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.forgery-detail .info-frame-3 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 120px;
  position: relative;
  width: 100%;
  z-index: 0;
}

.forgery-detail .frame-62 {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 40px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.forgery-detail .frame-63 {
  align-self: stretch;
  height: 40px;
  position: relative;
  width: 100%;
}

.forgery-detail .thumbnail-6 {
  align-self: stretch;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #aaaaaa;
  border-right-style: solid;
  border-right-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  height: 580px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.forgery-detail .section-frame-15 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 500px;
  position: relative;
  width: 1380px;
}

.forgery-detail .form-title-frame-2 {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
  width: 1380px;
}

.forgery-detail .form-title-3 {
  color: #0075ff;
  flex: 1;
  font-family: "Roboto-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22px;
  position: relative;
}

.forgery-detail .form-caption-frame-3 {
  align-items: center;
  align-self: stretch;
  border-radius: 2px;
  display: flex;
  gap: 10px;
  height: 40px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.forgery-detail .frame-64 {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #aaaaaa;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
  width: 1080px;
}

.forgery-detail .form-caption-3 {
  -webkit-text-stroke: 1px #aaaaaaaa;
  color: #333333;
  flex: 1;
  font-family: "Roboto-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -1.00px;
  position: relative;
}

.forgery-detail .frame-65 {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #aaaaaa;
  display: flex;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 80px;
}

.forgery-detail .text-wrapper-31 {
  color: #333333;
  flex: 1;
  font-family: "Roboto-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22px;
  position: relative;
  text-align: center;
}

.forgery-detail .frame-66 {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #aaaaaa;
  display: flex;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 200px;
}

.forgery-detail .form-text-frame-3 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #aaaaaa;
  border-left-style: solid;
  border-left-width: 1px;
  border-radius: 2px;
  border-right-style: solid;
  border-right-width: 1px;
  display: flex;
  gap: 10px;
  height: 360px;
  justify-content: center;
  overflow: hidden;
  padding: 10px;
  position: relative;
  width: 100%;
}

.forgery-detail .form-text-3 {
  color: #333333;
  font-family: "Roboto-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 320px;
  letter-spacing: 0;
  line-height: 22px;
  position: relative;
  width: 1360px;
}

.forgery-detail .form-submit-frame-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 20px;
  height: 60px;
  justify-content: center;
  padding: 10px 450px;
  position: relative;
  width: 100%;
}

.forgery-detail .form-submit-layout-4 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #888888;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 5px 0px;
  position: relative;
  width: 120px;
}

.forgery-detail .form-submit-5 {
  color: #888888;
  flex: 1;
  font-family: "Roboto-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 30px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.forgery-detail .form-submit-layout-5 {
  align-items: center;
  background-color: #27ae60;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 5px 0px;
  position: relative;
  width: 120px;
}

.forgery-detail .form-submit-6 {
  color: #ffffff;
  flex: 1;
  font-family: "Roboto-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 30px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.forgery-detail .left-menu-5 {
  left: 0 !important;
  position: absolute !important;
  width: 240px !important;
}
