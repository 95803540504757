.viewed-products {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #dddddd;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 340px;
  left: 1310px;
  padding: 1.25px 1.25px 11.25px;
  position: relative;
  top: 200px;
  width: calc(100% - 1310px);
}

.viewed-products .p-title-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 10px 42.23px 0px 42.22px;
  position: relative;
  width: 100%;
}

.viewed-products .text-wrapper-6 {
  color: #545454;
  font-family: var(--chagaun-net-roboto-bold-11-1-font-family);
  font-size: var(--chagaun-net-roboto-bold-11-1-font-size);
  font-style: var(--chagaun-net-roboto-bold-11-1-font-style);
  font-weight: var(--chagaun-net-roboto-bold-11-1-font-weight);
  letter-spacing: var(--chagaun-net-roboto-bold-11-1-letter-spacing);
  line-height: var(--chagaun-net-roboto-bold-11-1-line-height);
  margin-left: -6.97px;
  margin-right: -6.98px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 57px;
}

.viewed-products .div-box-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.viewed-products .div-image-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 14.77px 0px 14.74px;
  position: relative;
  width: 100%;
}

.viewed-products .img {
  height: 81px;
  margin-left: -5px;
  margin-right: -5px;
  position: relative;
  width: 107.99px;
}

.viewed-products .div-pager-2 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 80px;
}

.viewed-products .link-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 7.44px 0px 0px;
  position: relative;
}

.viewed-products .link-2 {
  align-items: flex-start;
  border: 1px solid;
  border-color: #e1e1e1;
  display: inline-flex;
  flex: 0 0 auto;
  padding: 3.88px 5.25px 2.79px;
  position: relative;
}

.viewed-products .symbol-3 {
  color: #333333;
  font-family: var(--chagaun-net-font-awesome-5-free-solid-9-8-font-family);
  font-size: var(--chagaun-net-font-awesome-5-free-solid-9-8-font-size);
  font-style: var(--chagaun-net-font-awesome-5-free-solid-9-8-font-style);
  font-weight: var(--chagaun-net-font-awesome-5-free-solid-9-8-font-weight);
  letter-spacing: var(--chagaun-net-font-awesome-5-free-solid-9-8-letter-spacing);
  line-height: var(--chagaun-net-font-awesome-5-free-solid-9-8-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.viewed-products .div-num-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 1.16px 4.96px 1.32px;
  position: relative;
}

.viewed-products .text-wrapper-7 {
  color: #212121;
  font-family: var(--chagaun-net-roboto-regular-9-8-font-family);
  font-size: var(--chagaun-net-roboto-regular-9-8-font-size);
  font-style: var(--chagaun-net-roboto-regular-9-8-font-style);
  font-weight: var(--chagaun-net-roboto-regular-9-8-font-weight);
  letter-spacing: var(--chagaun-net-roboto-regular-9-8-letter-spacing);
  line-height: var(--chagaun-net-roboto-regular-9-8-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.viewed-products .div-text-right-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 0px 0px 7.44px;
  position: relative;
}

.viewed-products .symbol-4 {
  color: #333333;
  font-family: var(--chagaun-net-font-awesome-5-free-solid-9-8-font-family);
  font-size: var(--chagaun-net-font-awesome-5-free-solid-9-8-font-size);
  font-style: var(--chagaun-net-font-awesome-5-free-solid-9-8-font-style);
  font-weight: var(--chagaun-net-font-awesome-5-free-solid-9-8-font-weight);
  letter-spacing: var(--chagaun-net-font-awesome-5-free-solid-9-8-letter-spacing);
  line-height: var(--chagaun-net-font-awesome-5-free-solid-9-8-line-height);
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}
