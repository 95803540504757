.dialog-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  z-index: 100;

  .overlay {
    width: 100%;
    height: 100%;
  }

  .dialog {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);

    width: 400px;
    padding: 20px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    .title {
      margin: 0 0 20px 0;
    }

    .input {
      width: 100%;
      padding: 10px;
      border-radius: 8px;
      border: 2px solid #ccc;
      font-size: 1em;
      font-family: inherit;
      box-sizing: border-box;
      margin-top: 10px;
    }

    .buttons {
      margin-top: 20px;
      float: right;
    }

    .buttons button {
      background-color: transparent;
      /* color: #84d8ff; */
      /* border: 2px solid #84d8ff; */
      color: #000000;
      border: 2px solid #000000;
      text-transform: uppercase;
      transition: all 0.2s;
      font-weight: bold;
      margin: 0 5px;
    }

    .buttons button:hover {
      background-color: #84d8ff;
      color: white;
    }

    .buttons button:focus {
      border: 2px solid #000000;
    }
  }
}
