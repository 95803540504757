.signup-agree {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.signup-agree .div-4 {
  background-color: #ffffff;
  height: 1700px;
  position: relative;
  width: 1640px;
}

.signup-agree .middle-section-2 {
  align-items: flex-start;
  display: flex;
  height: 1600px;
  left: 0;
  position: absolute;
  top: 100px;
  width: 1640px;
}

.signup-agree .design-component-instance-node {
  top: unset !important;
  width: 240px !important;
}

.signup-agree .body-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 10px;
  position: relative;
  width: 1400px;
}

.signup-agree .registry-title-text-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 42px;
  padding: 7px 0px;
  position: relative;
  width: 100%;
}

.signup-agree .p {
  align-self: stretch;
  color: #333333;
  font-family: "Roboto", Helvetica;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -1.00px;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
}

.signup-agree .title-container {
  align-items: center;
  align-self: stretch;
  border: 3px solid;
  border-color: #eeeeee;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  position: relative;
  width: 100%;
}

.signup-agree .div-agree-all-txt {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 19.97px;
  position: relative;
  width: 100%;
}

.signup-agree .logo-png {
  background-image: url(https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/676808ce879694704eec9bcd/img/logo-png.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 42.98px;
  max-width: 1210.03px;
  position: relative;
  width: 100%;
}

.signup-agree .p-point {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 0px 0px;
  justify-content: center;
  padding: 10px 10px 0px;
  position: relative;
  width: 100%;
}

.signup-agree .div-5 {
  color: transparent;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.signup-agree .span {
  color: #343434;
}

.signup-agree .text-wrapper-12 {
  color: #777777;
}

.signup-agree .p-join-check {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 5px;
  height: 24px;
  justify-content: center;
  padding: 3.5px 5px;
  position: relative;
  width: 100%;
}

.signup-agree .check-box {
  color: #343434;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 17px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  width: 17px;
}

.signup-agree .text-wrapper-13 {
  color: #343434;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  width: 76px;
}

.signup-agree .agree-container {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 23px;
  height: 607px;
  padding: 10px;
  position: relative;
  width: 100%;
}

.signup-agree .heading {
  color: #000000;
  font-family: "Noto Sans KR", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -1.00px;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  width: fit-content;
}

.signup-agree .div-con {
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(221, 221, 221), rgb(0, 0, 0) 0%)
    1;
  border-top-style: solid;
  border-top-width: 3px;
  height: 496px;
  position: relative;
  width: 100%;
}

.signup-agree .list-item {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32.5px;
  height: 441px;
  padding: 0px 0px 1021.8px;
  position: relative;
  top: 30px;
  width: 1360px;
}

.signup-agree .element {
  align-self: stretch;
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: -767.80px;
  margin-top: -1.00px;
  position: relative;
}

.signup-agree .text-wrapper-14 {
  letter-spacing: -0.08px;
}

.signup-agree .text-wrapper-15 {
  font-family: "Play", Helvetica;
  font-size: 15.9px;
  letter-spacing: -0.08px;
  line-height: 20px;
}

.signup-agree .text-wrapper-16 {
  font-size: 14px;
  letter-spacing: 0;
}

.signup-agree .p-join-check-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 5px;
  height: 24px;
  margin-bottom: -8.00px;
  padding: 3.5px 5px;
  position: relative;
  width: 100%;
}

.signup-agree .text-wrapper-17 {
  color: #343434;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
}

.signup-agree .personal-container {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 23px;
  padding: 10px;
  position: relative;
  width: 100%;
}

.signup-agree .heading-2 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -1.00px;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.signup-agree .list-item-wrapper {
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(221, 221, 221), rgb(0, 0, 0) 0%)
    1;
  border-top-style: solid;
  border-top-width: 3px;
  height: 252.95px;
  position: relative;
  width: 100%;
}

.signup-agree .term-wrapper {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 30px 30px;
  height: 205px;
  left: 30px;
  padding: 0px 0px 1479.04px;
  position: relative;
  top: 32px;
  width: 1330px;
}

.signup-agree .term {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 8.98px;
  height: 205px;
  margin-bottom: -1479.04px;
  padding: 0px 0px 0.97px;
  position: relative;
}

.signup-agree .p-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 0px 0px;
  padding: 0px 0px 0.94px;
  position: relative;
  width: 100%;
}

.signup-agree .text-wrapper-18 {
  color: #000000;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
}

.signup-agree .text-wrapper-19 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.50px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.signup-agree .p-join-check-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 5px;
  height: 24px;
  padding: 3.5px 5px;
  position: relative;
  width: 100%;
}

.signup-agree .text-wrapper-20 {
  color: #343434;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 17px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
}

.signup-agree .button-container-2 {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  gap: 10px;
  height: 60px;
  justify-content: center;
  padding: 10px;
  position: relative;
  width: 100%;
}

.signup-agree .link-4 {
  align-items: flex-start;
  background-color: #163e64;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 11.5px 0px;
  position: relative;
  width: 100px;
}

.signup-agree .text-wrapper-21 {
  color: #ffffff;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -3.00px;
  position: relative;
  text-align: center;
}

.signup-agree .link-5 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 2px solid;
  border-color: #cccccc;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 11.5px 0px;
  position: relative;
  width: 100px;
}

.signup-agree .text-wrapper-22 {
  color: #000000;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -2.00px;
  position: relative;
  text-align: center;
}

.signup-agree .menu-section-2 {
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 40px;
  width: 1640px;
}

.signup-agree .main-logo-2 {
  top: unset !important;
}

.signup-agree .right-menu-2 {
  flex: 0 0 auto !important;
  left: unset !important;
  top: unset !important;
}
