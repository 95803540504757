.lawsuit-detail {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.lawsuit-detail .div-2 {
  background-color: #ffffff;
  height: 1652px;
  position: relative;
  width: 1640px;
}

.lawsuit-detail .body-frame {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 1300px;
  left: 240px;
  padding: 20px 10px;
  position: absolute;
  top: 100px;
  width: 1400px;
}

.lawsuit-detail .bulletin-body-title {
  align-self: stretch;
  height: 25px;
  margin-right: -2.00px;
  position: relative;
  width: 100%;
}

.lawsuit-detail .title {
  color: #000000;
  font-family: "Nanum Gothic", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 25px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 1380px;
}

.lawsuit-detail .body-sub-title {
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #bbbbbb;
  height: 10px;
  position: relative;
  width: 100%;
}

.lawsuit-detail .container {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 21px;
  height: 1139px;
  padding: 0px 0px 20px;
  position: relative;
  width: 100%;
}

.lawsuit-detail .form {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  margin-bottom: -76.00px;
  position: relative;
  width: 100%;
}

.lawsuit-detail .fieldset {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.lawsuit-detail .horizontal-border {
  align-items: flex-start;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #e8e8e8;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 10px 10px 11px;
  position: relative;
  width: 100%;
}

.lawsuit-detail .container-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.lawsuit-detail .label-frame {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 8.25px 10px 8.75px;
  position: relative;
  width: 170px;
}

.lawsuit-detail .text-wrapper-6 {
  align-self: stretch;
  color: #333333;
  font-family: "Inter", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
}

.lawsuit-detail .data-frame {
  align-items: flex-start;
  border: 1px solid;
  border-color: #bbbbbb;
  display: flex;
  gap: 10px;
  height: 30px;
  padding: 7.5px 10px;
  position: relative;
  width: 300px;
}

.lawsuit-detail .text-wrapper-7 {
  color: #000000;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -2.00px;
  margin-top: -1.00px;
  position: relative;
}

.lawsuit-detail .container-wrapper {
  align-items: flex-start;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #e8e8e8;
  display: flex;
  flex-direction: column;
  height: 320px;
  padding: 10px 10px 11px;
  position: relative;
  width: 100%;
}

.lawsuit-detail .container-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  margin-bottom: -1.00px;
  position: relative;
  width: 100%;
}

.lawsuit-detail .data-frame-2 {
  align-items: flex-start;
  border: 1px solid;
  border-color: #bbbbbb;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 300px;
  overflow: scroll;
  padding: 10px;
  position: relative;
  width: 880px;
}

.lawsuit-detail .data-frame-2::-webkit-scrollbar {
  display: none;
  width: 0;
}

.lawsuit-detail .text-wrapper-8 {
  align-self: stretch;
  color: #333333;
  font-family: "Inter", Helvetica;
  font-size: 15px;
  font-weight: 400;
  height: 15px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
}

.lawsuit-detail .field-summary {
  align-items: center;
  align-self: stretch;
  background-color: #f8f8f8;
  border: 1px solid;
  border-color: #e8e8e8;
  border-radius: 4px;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  overflow: hidden;
  padding: 13px 10px 13px 1310px;
  position: relative;
  width: 100%;
}

.lawsuit-detail .list-frame {
  align-items: center;
  background-color: #205067;
  display: flex;
  gap: 10px;
  height: 35px;
  justify-content: center;
  padding: 6px 10px;
  position: relative;
  width: 60px;
}

.lawsuit-detail .text-wrapper-9 {
  color: #ffffff;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.lawsuit-detail .left-menu-instance {
  left: 0 !important;
  position: absolute !important;
  width: 240px !important;
}

.lawsuit-detail .right-menu-instance {
  left: 1404px !important;
  position: absolute !important;
}

.lawsuit-detail .main-logo-instance {
  left: 0 !important;
  position: absolute !important;
  top: 40px !important;
}
