.purchase-mutong {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.purchase-mutong .div-24 {
  background-color: #ffffff;
  height: 1880px;
  position: relative;
  width: 1440px;
}

.purchase-mutong .right-menu-8 {
  left: 1034px !important;
  position: absolute !important;
  top: 0 !important;
}

.purchase-mutong .main-logo-9 {
  left: 131px !important;
  position: absolute !important;
  top: 50px !important;
}

.purchase-mutong .search-area-9 {
  left: 541px !important;
  position: absolute !important;
  top: 50px !important;
}

.purchase-mutong .nav-menu-9 {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.purchase-mutong .link-tree-9 {
  left: 131px !important;
  position: absolute !important;
  top: 150px !important;
}

.purchase-mutong .div-25 {
  height: 1461px;
  left: 130px;
  position: absolute;
  top: 200px;
  width: 1170px;
}

.purchase-mutong .heading-14 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 15px;
  padding: 0px 520.09px 1.16px 520.07px;
  position: absolute;
  top: -1px;
  width: 1140px;
}

.purchase-mutong .text-wrapper-156 {
  color: #212121;
  font-family: "Roboto", Helvetica;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28.6px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.purchase-mutong .form-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  left: 15px;
  position: absolute;
  top: 109px;
  width: 1140px;
}

.purchase-mutong .div-26 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  box-shadow: 0px 1px 1px #0000000d;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 1.25px;
  position: relative;
  width: 1140px;
}

.purchase-mutong .div-panel-body-7 {
  align-self: stretch;
  height: 572.81px;
  position: relative;
  width: 100%;
}

.purchase-mutong .div-item-data-3 {
  height: 150px;
  left: 15px;
  position: absolute;
  top: 15px;
  width: 1108px;
}

.purchase-mutong .div-image-5 {
  align-items: flex-start;
  background-image: url(../../../static/img/div-image-3.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 200px;
}

.purchase-mutong .div-img-3 {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  object-fit: cover;
  position: relative;
}

.purchase-mutong .div-info-3 {
  align-items: flex-start;
  display: inline-flex;
  left: 200px;
  padding: 0px 0px 0px 20px;
  position: absolute;
  top: -1px;
}

.purchase-mutong .heading-15 {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 0px 126.27px;
  position: relative;
}

.purchase-mutong .text-wrapper-157 {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-medium-15-6-font-family);
  font-size: var(--store-gpakorea-com-roboto-medium-15-6-font-size);
  font-style: var(--store-gpakorea-com-roboto-medium-15-6-font-style);
  font-weight: var(--store-gpakorea-com-roboto-medium-15-6-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-medium-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-medium-15-6-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-mutong .table-7 {
  align-items: flex-start;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 15px;
  max-width: 1137.5px;
  padding: 0.63px 0px 0px;
  position: absolute;
  top: 195px;
}

.purchase-mutong .div-27 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.purchase-mutong .cell-23 {
  align-items: flex-start;
  background-color: var(--storegpakoreacomalto);
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  padding: 8.07px 779.6px 8.53px 7.98px;
  position: relative;
}

.purchase-mutong .text-wrapper-158 {
  color: #646464;
  font-family: var(--store-gpakorea-com-roboto-bold-13-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-13-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-13-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-13-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-bold-13-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-13-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-mutong .cell-24 {
  align-items: flex-start;
  background-color: var(--storegpakoreacomalto);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8.07px 42.53px 8.53px 42.51px;
  position: relative;
  width: 110px;
}

.purchase-mutong .text-wrapper-159 {
  color: #646464;
  font-family: var(--store-gpakorea-com-roboto-bold-13-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-13-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-13-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-13-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-bold-13-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-13-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.purchase-mutong .cell-25 {
  align-items: flex-start;
  background-color: var(--storegpakoreacomalto);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8.07px 7.99px 8.53px 127.05px;
  position: relative;
  width: 160px;
}

.purchase-mutong .text-wrapper-160 {
  color: #646464;
  font-family: var(--store-gpakorea-com-roboto-bold-13-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-13-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-13-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-13-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-bold-13-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-13-line-height);
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.purchase-mutong .body-row {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  margin-top: -0.01px;
  position: relative;
}

.purchase-mutong .data-16 {
  align-items: flex-start;
  background-color: #ffffff;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 0.01px;
  padding: 30.01px 8px 30.56px;
  position: relative;
}

.purchase-mutong .p-pkg-summary-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 0px 0.54px;
  position: relative;
  width: 100%;
}

.purchase-mutong .text-wrapper-161 {
  color: #646464;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-mutong .data-17 {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 8px;
  position: relative;
  width: 110px;
}

.purchase-mutong .div-box-qty-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 30px 42.14px 30px 42.13px;
  position: relative;
  width: 100%;
}

.purchase-mutong .text-wrapper-162 {
  color: #646464;
  font-family: var(--store-gpakorea-com-roboto-extrabold-15-6-font-family);
  font-size: var(--store-gpakorea-com-roboto-extrabold-15-6-font-size);
  font-style: var(--store-gpakorea-com-roboto-extrabold-15-6-font-style);
  font-weight: var(--store-gpakorea-com-roboto-extrabold-15-6-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-extrabold-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-extrabold-15-6-line-height);
  margin-left: -0.13px;
  margin-right: -0.14px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.purchase-mutong .link-43 {
  align-items: center;
  background-color: #555555;
  border-radius: 26px;
  display: flex;
  flex-direction: column;
  height: 26px;
  left: 0;
  padding: 0px 10.08px 0px 10.06px;
  position: absolute;
  top: 28px;
  width: 26px;
}

.purchase-mutong .text-wrapper-163 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 26px;
  margin-left: -0.07px;
  margin-right: -0.07px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.purchase-mutong .link-44 {
  align-items: center;
  background-color: #555555;
  border-radius: 26px;
  display: flex;
  flex-direction: column;
  height: 26px;
  left: 68px;
  padding: 0px 9.13px 0px 9.12px;
  position: absolute;
  top: 28px;
  width: 26px;
}

.purchase-mutong .text-wrapper-164 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 26px;
  margin-left: -0.12px;
  margin-right: -0.12px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.purchase-mutong .data-18 {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 39.31px 7.99px 40.38px 95.62px;
  position: relative;
  width: 160px;
}

.purchase-mutong .text-wrapper-165 {
  color: #646464;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.purchase-mutong .table-8 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  left: 15px;
  max-width: 1137.5px;
  padding: 0.62px 0px 0.63px;
  position: absolute;
  top: 369px;
}

.purchase-mutong .body-5 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.purchase-mutong .cell-26 {
  align-items: flex-start;
  background-color: #f7f7f7;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 37.04px 8px 38.13px;
  position: relative;
  width: 350px;
}

.purchase-mutong .text-wrapper-166 {
  color: #212121;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1.25px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-mutong .data-19 {
  align-items: flex-start;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 16.25px 8px 15px;
  position: relative;
}

.purchase-mutong .div-input-group-7 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 1.25px;
  position: relative;
  width: 100%;
}

.purchase-mutong .textarea-4 {
  align-self: stretch;
  background-color: #ffffff;
  height: 60px;
  position: relative;
  width: 100%;
}

.purchase-mutong .cell-27 {
  align-items: flex-start;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 27.77px 8px 28.84px;
  position: relative;
  width: 350px;
}

.purchase-mutong .element-5 {
  color: #212121;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1.25px;
  position: relative;
  width: fit-content;
}

.purchase-mutong .data-20 {
  align-items: flex-start;
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 15.63px 8px 15.62px;
  position: relative;
}

.purchase-mutong .textarea-5 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 60px;
  padding: 7.36px 8px 32.02px;
  position: relative;
  width: 100%;
}

.purchase-mutong .div-placeholder-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 0px 0.62px;
  position: relative;
  width: 100%;
}

.purchase-mutong .text-wrapper-167 {
  align-self: stretch;
  color: var(--storegpakoreacomdusty-gray);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
}

.purchase-mutong .div-panel-pay-2 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  box-shadow: 0px 1px 1px #0000000d;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.53e-14px;
  padding: 1.25px;
  position: relative;
  width: 1140px;
}

.purchase-mutong .div-panel-heading-3 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--storegpakoreacomwild-sand);
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: var(--storegpakoreacomalto);
  border-radius: 3px 3px 0px 0px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 10px 15px 11.25px;
  position: relative;
  width: 100%;
}

.purchase-mutong .text-wrapper-168 {
  align-self: stretch;
  color: #333333;
  font-family: var(--store-gpakorea-com-roboto-regular-14-3-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-14-3-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-14-3-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-14-3-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-regular-14-3-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-14-3-line-height);
  margin-top: -1.25px;
  position: relative;
}

.purchase-mutong .div-panel-body-8 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--storegpakoreacomcyan-aqua);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 25px 0px;
  position: relative;
  width: 100%;
}

.purchase-mutong .div-row-3 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 1.14e-13px;
  justify-content: center;
  position: relative;
  width: 1137.5px;
}

.purchase-mutong .div-col-sm-4 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  min-height: 1px;
  padding: 35px 15px 1.14e-13px;
  position: relative;
}

.purchase-mutong .div-box-coupon-3 {
  height: 34.49px;
  position: relative;
  width: 538.75px;
}

.purchase-mutong .div-28 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 8px;
}

.purchase-mutong .heading-16 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 5.99px;
  padding: 0px 0px 1.07px;
  position: relative;
  width: 100%;
}

.purchase-mutong .text-wrapper-169 {
  color: #555555;
  font-family: "Roboto", Helvetica;
  font-size: 15.6px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 17.2px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-mutong .text-wrapper-170 {
  color: #ff0000;
  font-family: "Roboto", Helvetica;
  font-size: 15.6px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 17.2px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-mutong .link-45 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #cccccc;
  border-radius: 4px;
  display: inline-flex;
  left: 458px;
  padding: 7.25px 13.25px;
  position: absolute;
  top: 0;
}

.purchase-mutong .text-wrapper-171 {
  color: #333333;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.purchase-mutong .div-box-point-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.purchase-mutong .heading-17 {
  height: 17.15px;
  position: relative;
  width: 538.75px;
}

.purchase-mutong .text-wrapper-172 {
  color: #555555;
  font-family: "Roboto", Helvetica;
  font-size: 15.6px;
  font-weight: 700;
  height: 17px;
  left: 0;
  letter-spacing: 0;
  line-height: 17.2px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 69px;
}

.purchase-mutong .small-7 {
  color: #555555;
  font-family: var(--store-gpakorea-com-roboto-regular-13-3-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-13-3-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-13-3-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-13-3-font-weight);
  height: 13px;
  left: 75px;
  letter-spacing: var(--store-gpakorea-com-roboto-regular-13-3-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-13-3-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 89px;
}

.purchase-mutong .div-input-group-8 {
  align-items: flex-start;
  background-color: #ffffff;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}

.purchase-mutong .input-8 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  border-radius: 4px 0px 0px 4px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 33.98px;
  padding: 7.25px 13.25px;
  position: relative;
  z-index: 1;
}

.purchase-mutong .div-29 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow: scroll;
  position: relative;
  width: 100%;
}

.purchase-mutong .text-wrapper-173 {
  color: #555555;
  font-family: var(--store-gpakorea-com-roboto-regular-14-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-14-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-14-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-14-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-regular-14-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-14-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-mutong .span-input-group-btn-4 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  margin-left: -1.14e-13px;
  padding: 0px 0.01px 0px 9px;
  position: relative;
  z-index: 0;
}

.purchase-mutong .link-46 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #cccccc;
  border-radius: 0px 4px 4px 0px;
  display: inline-flex;
  flex: 0 0 auto;
  padding: 7.25px 13.25px;
  position: relative;
}

.purchase-mutong .div-col-sm-5 {
  align-items: flex-start;
  border-color: var(--storegpakoreacomalto);
  border-left-style: solid;
  border-left-width: 1.25px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  min-height: 1px;
  padding: 0px 15px 0px 16.25px;
  position: relative;
}

.purchase-mutong .table-9 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  max-width: 568.75px;
  position: relative;
  width: 100%;
}

.purchase-mutong .row-4 {
  height: 38.26px;
  position: relative;
  width: 537.5px;
}

.purchase-mutong .cell-28 {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-bold-15-6-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-15-6-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-15-6-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-15-6-font-weight);
  height: 22px;
  left: 8px;
  letter-spacing: var(--store-gpakorea-com-roboto-bold-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-15-6-line-height);
  position: absolute;
  top: 6px;
  white-space: nowrap;
  width: 99px;
}

.purchase-mutong .data-21 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 252px;
  padding: 9.31px 8px 9.85px;
  position: absolute;
  top: 0;
}

.purchase-mutong .div-wrapper-6 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 0px 0.54px;
  position: relative;
  width: 100%;
}

.purchase-mutong .text-wrapper-174 {
  color: #212121;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.purchase-mutong .cell-29 {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-regular-15-6-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-15-6-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-15-6-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-15-6-font-weight);
  height: 22px;
  left: 8px;
  letter-spacing: var(--store-gpakorea-com-roboto-regular-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-15-6-line-height);
  position: absolute;
  top: 6px;
  white-space: nowrap;
  width: 50px;
}

.purchase-mutong .footer-row {
  height: 75.12px;
  margin-top: -0.01px;
  position: relative;
  width: 537.5px;
}

.purchase-mutong .cell-30 {
  color: #212121;
  font-family: var(--store-gpakorea-com-roboto-bold-15-6-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-15-6-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-15-6-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-15-6-font-weight);
  height: 22px;
  left: 8px;
  letter-spacing: var(--store-gpakorea-com-roboto-bold-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-15-6-line-height);
  position: absolute;
  top: 35px;
  white-space: nowrap;
  width: 80px;
}

.purchase-mutong .data-22 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 252px;
  padding: 30px 8px 8px;
  position: absolute;
  top: 0;
}

.purchase-mutong .p-total-amount-3 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.purchase-mutong .text-wrapper-175 {
  color: #ff0000;
  font-family: var(--store-gpakorea-com-roboto-bold-26-font-family);
  font-size: var(--store-gpakorea-com-roboto-bold-26-font-size);
  font-style: var(--store-gpakorea-com-roboto-bold-26-font-style);
  font-weight: var(--store-gpakorea-com-roboto-bold-26-font-weight);
  letter-spacing: var(--store-gpakorea-com-roboto-bold-26-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-bold-26-line-height);
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.purchase-mutong .text-wrapper-176 {
  align-self: stretch;
  color: #333333;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1.25px;
  position: relative;
}

.purchase-mutong .div-panel-body-9 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 18.8px;
  margin-top: -6.39e-14px;
  padding: 14.47px 15px 15px;
  position: relative;
  width: 100%;
}

.purchase-mutong .label-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  max-width: 1137.5px;
  padding: 0px 0px 0.54px 20px;
  position: relative;
}

.purchase-mutong .symbol-wrapper-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 17px;
  left: 0;
  padding: 0px 1.95px 0.94px 1.93px;
  position: absolute;
  top: 1px;
  width: 19px;
}

.purchase-mutong .symbol-13 {
  color: #232323;
  font-family: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-font-family);
  font-size: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-font-size);
  font-style: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-font-style);
  font-weight: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-font-weight);
  letter-spacing: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-font-awesome-5-free-solid-15-6-line-height);
  margin-left: -0.2px;
  margin-right: -0.2px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.purchase-mutong .text-wrapper-177 {
  color: #505050;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-mutong .symbol-14 {
  color: #d9d9d9;
  font-family: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-font-family);
  font-size: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-font-size);
  font-style: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-font-style);
  font-weight: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-font-weight);
  letter-spacing: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-letter-spacing);
  line-height: var(--store-gpakorea-com-font-awesome-5-free-regular-15-6-line-height);
  margin-left: -0.2px;
  margin-right: -0.2px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.purchase-mutong .span-far-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 17px;
  left: 0;
  padding: 0px 1.94px 0.94px;
  position: absolute;
  top: 1px;
  width: 19px;
}

.purchase-mutong .div-panel-dbank {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  box-shadow: 0px 1px 1px #0000000d;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 1.63e-13px;
  padding: 1.25px;
  position: relative;
  width: 100%;
}

.purchase-mutong .div-panel-body-10 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10.01px;
  padding: 15px 15px 35px;
  position: relative;
  width: 100%;
}

.purchase-mutong .text-wrapper-178 {
  color: #ff0000;
  font-family: "Roboto", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.purchase-mutong .body-row-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  max-width: 1137.5px;
  padding: 0.62px 0px 0.63px;
  position: relative;
}

.purchase-mutong .cell-31 {
  align-items: flex-start;
  background-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24.03px 8px 25.12px;
  position: relative;
  width: 200px;
}

.purchase-mutong .data-23 {
  align-items: flex-start;
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: var(--storegpakoreacomalto);
  border-top-style: solid;
  border-top-width: 1.25px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 15.63px 713.04px 15.62px 7.98px;
  position: relative;
}

.purchase-mutong .div-input-group-9 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--storegpakoreacomalto);
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 1.25px;
  position: relative;
}

.purchase-mutong .input-9 {
  align-self: stretch;
  background-color: #ffffff;
  height: 33.98px;
  position: relative;
  width: 100%;
}

.purchase-mutong .link-47 {
  background-color: var(--storegpakoreacomconifer);
  border: 1px solid;
  border-color: var(--storegpakoreacomconifer);
  border-radius: 4px;
  height: 52px;
  left: 15px;
  position: absolute;
  top: 1409px;
  width: 1140px;
}

.purchase-mutong .overlap-group-6 {
  height: 26px;
  left: 522px;
  position: relative;
  top: 12px;
  width: 96px;
}

.purchase-mutong .symbol-15 {
  color: #ffffff;
  font-family: var(--store-gpakorea-com-font-awesome-5-free-solid-18-2-font-family);
  font-size: var(--store-gpakorea-com-font-awesome-5-free-solid-18-2-font-size);
  font-style: var(--store-gpakorea-com-font-awesome-5-free-solid-18-2-font-style);
  font-weight: var(--store-gpakorea-com-font-awesome-5-free-solid-18-2-font-weight);
  height: 18px;
  left: 0;
  letter-spacing: var(--store-gpakorea-com-font-awesome-5-free-solid-18-2-letter-spacing);
  line-height: var(--store-gpakorea-com-font-awesome-5-free-solid-18-2-line-height);
  position: absolute;
  text-align: center;
  top: 5px;
  white-space: nowrap;
  width: 21px;
}

.purchase-mutong .text-wrapper-179 {
  color: #ffffff;
  font-family: var(--store-gpakorea-com-roboto-regular-18-2-font-family);
  font-size: var(--store-gpakorea-com-roboto-regular-18-2-font-size);
  font-style: var(--store-gpakorea-com-roboto-regular-18-2-font-style);
  font-weight: var(--store-gpakorea-com-roboto-regular-18-2-font-weight);
  height: 26px;
  left: 20px;
  letter-spacing: var(--store-gpakorea-com-roboto-regular-18-2-letter-spacing);
  line-height: var(--store-gpakorea-com-roboto-regular-18-2-line-height);
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 75px;
}

.purchase-mutong .footer-panel-8 {
  align-items: center;
  background-color: #606060;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 1720px;
  width: 1440px;
}

.purchase-mutong .footer-frame-9 {
  height: 160px;
  position: relative;
  width: 1160px;
}

.purchase-mutong .company-infos-7 {
  height: 72px;
  left: 213px;
  position: absolute;
  top: 43px;
  width: 683px;
}

.purchase-mutong .flexcontainer-8 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 72px;
  position: relative;
  width: 681px;
}

.purchase-mutong .text-i-9 {
  align-self: stretch;
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
  position: relative;
}

.purchase-mutong .text-wrapper-180 {
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
}

.purchase-mutong .sns-link-9 {
  height: 40px;
  left: 949px;
  position: absolute;
  top: 60px;
  width: 200px;
}

.purchase-mutong .list-item-link-39 {
  background-image: url(../../../static/img/list-item-link.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 46px;
}

.purchase-mutong .text-wrapper-181 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 40px;
}

.purchase-mutong .list-item-link-40 {
  background-image: url(../../../static/img/list-item-link-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 51px;
  position: absolute;
  top: 0;
  width: 46px;
}

.purchase-mutong .list-item-link-41 {
  background-image: url(../../../static/img/list-item-link-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 103px;
  position: absolute;
  top: 0;
  width: 46px;
}

.purchase-mutong .text-wrapper-182 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 53px;
}

.purchase-mutong .list-item-link-42 {
  background-image: url(../../../static/img/list-item-link-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 154px;
  position: absolute;
  top: 0;
  width: 46px;
}

.purchase-mutong .text-wrapper-183 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 66px;
}

.purchase-mutong .logo-gothic-9 {
  height: 111px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 25px;
  width: 145px;
}

.purchase-mutong .kakao-service-9 {
  left: 0 !important;
  position: absolute !important;
  top: 200px !important;
}

.purchase-mutong .jimmed-products-9 {
  left: 1310px !important;
  position: absolute !important;
  top: 560px !important;
}

.purchase-mutong .viewed-products-9 {
  left: 1310px !important;
  position: absolute !important;
  top: 200px !important;
}
