.left-menu {
  align-items: flex-start;
  background-color: #163e64;
  display: flex;
  flex-direction: column;
  gap: 25px;
  height: 1300px;
  padding: 20px 15px;
  position: relative;
  top: 100px;
  width: 100%;
}

.left-menu .left-menu-head {
  align-items: flex-start;
  border-bottom-style: solid;
  border-bottom-width: 1.25px;
  border-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  gap: 9px;
  position: relative;
  width: 210px;
}

.left-menu .text-wrapper {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -1.04px;
  line-height: Truepx;
  margin-top: -1.25px;
  position: relative;
  width: fit-content;
}

.left-menu .div {
  color: #ffffff;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: Truepx;
  margin-top: -1.25px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.left-menu .left-menu-list {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 5px 0px;
  position: relative;
  width: 186px;
}

.left-menu .left-menu-item {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 40px;
  margin-right: -24.00px;
  padding: 8px 0px;
  position: relative;
  width: 210px;
}

.left-menu .text-wrapper-2 {
  align-self: stretch;
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 24px;
  letter-spacing: 0;
  line-height: Truepx;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
}

.left-menu .button {
  all: unset;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 21px;
  justify-content: center;
  left: 190px;
  padding: 0px 4px;
  position: absolute;
  top: 8px;
  width: 21px;
}

.left-menu .symbol {
  color: #aaaaaa;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 21px;
  letter-spacing: 0;
  line-height: Truepx;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 13px;
}
