.my-purchase-stat {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.my-purchase-stat .div-23 {
  background-color: #ffffff;
  height: 1460px;
  position: relative;
  width: 1440px;
}

.my-purchase-stat .right-menu-9 {
  left: 1034px !important;
  position: absolute !important;
  top: 0 !important;
}

.my-purchase-stat .main-logo-10 {
  left: 131px !important;
  position: absolute !important;
  top: 50px !important;
}

.my-purchase-stat .search-area-10 {
  left: 541px !important;
  position: absolute !important;
  top: 50px !important;
}

.my-purchase-stat .nav-menu-10 {
  left: 0 !important;
  position: absolute !important;
  top: 100px !important;
}

.my-purchase-stat .link-tree-10 {
  left: 131px !important;
  position: absolute !important;
  top: 150px !important;
}

.my-purchase-stat .my-page-menu-5 {
  left: 130px !important;
  position: absolute !important;
  top: 200px !important;
}

.my-purchase-stat .my-page-avata-5 {
  left: 370px !important;
  position: absolute !important;
  top: 200px !important;
}

.my-purchase-stat .footer-panel-9 {
  align-items: center;
  background-color: #606060;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 1300px;
  width: 1440px;
}

.my-purchase-stat .footer-frame-10 {
  height: 160px;
  position: relative;
  width: 1160px;
}

.my-purchase-stat .company-infos-8 {
  height: 72px;
  left: 213px;
  position: absolute;
  top: 43px;
  width: 683px;
}

.my-purchase-stat .flexcontainer-9 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 72px;
  position: relative;
  width: 681px;
}

.my-purchase-stat .text-i-10 {
  align-self: stretch;
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
  position: relative;
}

.my-purchase-stat .text-wrapper-119 {
  color: #b2b2b2;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.2px;
}

.my-purchase-stat .sns-link-10 {
  height: 40px;
  left: 949px;
  position: absolute;
  top: 60px;
  width: 200px;
}

.my-purchase-stat .list-item-link-37 {
  background-image: url(../../../static/img/list-item-link.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-purchase-stat .text-wrapper-120 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 40px;
}

.my-purchase-stat .list-item-link-38 {
  background-image: url(../../../static/img/list-item-link-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 51px;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-purchase-stat .list-item-link-39 {
  background-image: url(../../../static/img/list-item-link-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 103px;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-purchase-stat .text-wrapper-121 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 53px;
}

.my-purchase-stat .list-item-link-40 {
  background-image: url(../../../static/img/list-item-link-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 40px;
  left: 154px;
  position: absolute;
  top: 0;
  width: 46px;
}

.my-purchase-stat .text-wrapper-122 {
  color: #666666;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 21px;
  left: -139986px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 66px;
}

.my-purchase-stat .logo-gothic-10 {
  height: 111px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 25px;
  width: 145px;
}

.my-purchase-stat .kakao-service-9 {
  left: 0 !important;
  position: absolute !important;
  top: 200px !important;
}

.my-purchase-stat .jimmed-products-10 {
  left: 1310px !important;
  position: absolute !important;
  top: 560px !important;
}

.my-purchase-stat .viewed-products-10 {
  left: 1310px !important;
  position: absolute !important;
  top: 200px !important;
}
