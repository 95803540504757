.body-search {
  align-items: center;
  display: flex;
  left: 480px;
  position: relative;
  top: 1822px;
  width: 400px;
}

.body-search .options {
  align-items: center;
  background-color: #efefef;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  gap: 10px;
  height: 28px;
  justify-content: center;
  padding: 5px 10px;
  position: relative;
  width: 100px;
}

.body-search .text-wrapper-10 {
  color: #000000cc;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.body-search .input {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  gap: 10px;
  height: 28px;
  justify-content: center;
  padding: 5px 10px;
  position: relative;
  width: 240px;
}

.body-search .text-wrapper-11 {
  color: #000000;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
}

.body-search .button-2 {
  all: unset;
  align-items: center;
  background-color: #5f6271;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  height: 28px;
  justify-content: center;
  padding: 5px;
  position: relative;
  width: 60px;
}

.body-search .text-wrapper-12 {
  color: #ffffff;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}
