.content-detail {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.content-detail .div-2 {
  background-color: #ffffff;
  height: 2765px;
  position: relative;
  width: 1440px;
}

.content-detail .footer-panel-instance {
  left: 0;
  position: absolute;
  top: 2613px;
  width: 1440px;
}

.content-detail .design-component-instance-node {
  position: absolute !important;
  width: 130px !important;
}

.content-detail .design-component-instance-node-2 {
  left: 0 !important;
  position: absolute !important;
}

.content-detail .design-component-instance-node-3 {
  position: absolute !important;
}

.content-detail .main-logo-instance {
  position: absolute !important;
  width: 185px !important;
}

.content-detail .right-menu-instance {
  left: 1034px !important;
  position: absolute !important;
  top: 0 !important;
}

.content-detail .main-frame {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  left: 140px;
  position: absolute;
  top: 200px;
}

.content-detail .section-frame {
  height: 500px;
  position: relative;
  width: 1160px;
}

.content-detail .frame {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #aaaaaa;
  border-right-style: solid;
  border-right-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: center;
  left: 580px;
  padding: 10px 10px 10px 40px;
  position: absolute;
  top: 0;
  width: 580px;
}

.content-detail .frame-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.content-detail .text-wrapper-15 {
  color: #000000;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.content-detail .text-wrapper-16 {
  color: #000000;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.content-detail .text-wrapper-17 {
  color: #000000;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.content-detail .frame-3 {
  height: 500px;
  left: 0;
  position: absolute;
  top: 0;
  width: 580px;
}

.content-detail .img-wrapper {
  align-items: flex-start;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #aaaaaa;
  border-right-style: solid;
  border-right-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 500px;
  left: 80px;
  padding: 10px;
  position: absolute;
  top: 0;
  width: 500px;
}

.content-detail .div-image-2 {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.content-detail .frame-4 {
  border: 1px solid;
  border-color: #aaaaaa;
  height: 500px;
  left: 0;
  position: absolute;
  top: 0;
  width: 80px;
}

.content-detail .frame-5 {
  background-image: url(../../../static/img/div-image-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 60px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 60px;
}

.content-detail .frame-6 {
  background-image: url(../../../static/img/div-image-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 60px;
  left: 10px;
  position: absolute;
  top: 80px;
  width: 60px;
}

.content-detail .frame-7 {
  background-image: url(../../../static/img/div-image-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 60px;
  left: 10px;
  position: absolute;
  top: 150px;
  width: 60px;
}

.content-detail .frame-8 {
  background-image: url(../../../static/img/div-image-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 60px;
  left: 10px;
  position: absolute;
  top: 220px;
  width: 60px;
}

.content-detail .frame-9 {
  background-image: url(../../../static/img/div-image-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 60px;
  left: 10px;
  position: absolute;
  top: 290px;
  width: 60px;
}

.content-detail .rec-item-frame-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 0px 0px;
  opacity: 0.7;
  position: relative;
  width: 1160px;
}

.content-detail .rec-item-frame {
  align-items: center;
  display: flex;
  position: relative;
  width: 1160px;
}

.content-detail .rec-item-left {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 800px;
  position: relative;
  width: 800px;
}

.content-detail .thumbnail {
  align-self: stretch;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #aaaaaa;
  height: 800px;
  position: relative;
  width: 100%;
}

.content-detail .back-image-frame {
  align-items: center;
  display: flex;
  gap: 10px;
  height: 800px;
  padding: 10px;
  position: relative;
  width: 800px;
}

.content-detail .back-image {
  height: 780px;
  position: relative;
  width: 780px;
}

.content-detail .frame-wrapper {
  align-items: flex-start;
  border: 1px solid;
  border-color: #aaaaaa;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 10px;
  position: relative;
  width: 1160px;
}

.content-detail .text-wrapper-18 {
  color: #0075ff;
  flex: 1;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.content-detail .rec-item-left-wrapper {
  align-items: center;
  display: flex;
  padding: 10px;
  position: relative;
  width: 1160px;
}

.content-detail .rec-item-left-2 {
  flex: 1;
  flex-grow: 1;
  height: 800px;
  position: relative;
}
